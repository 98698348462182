import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostApplied } from "./components/basic/BasicPostApplied";
import { BasicDetail } from "./components/basic/BasicDetail";
import { BasicLanguageStar } from "./components/basic/BasicLanguage";
import { BasicExperience } from "./components/basic/BasicExperience";
import { BasicSkillsOneLineHorzStar } from "./components/basic/BasicSkills";
import { BasicPassport } from "./components/basic/BasicPassport";
import { BasicFullName } from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicReferenceOnly } from "./components/basic/BasicReference";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { BasicTitleSingleLang } from "./components/basic/BasicTitle";
import { BasicFooter } from "./components/basic/BasicFooter";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function SkillStarTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  //a08745;f2f1f0 fffcf2
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),url(/android-chrome-512x512.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="center"
          >
            {/* Short Photo */}
            <Grid item xs={3}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>

            <Grid container item xs={9}>
              {cvTemplate && currentEmployee && (
                <>
                  <Grid item xs={12}>
                    <BasicTitleSingleLang template={cvTemplate} />

                    <BasicReferenceOnly
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />

                    <BasicFullName
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />

                    <BasicPostApplied
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                      showHeader={false}
                      showProfession={true}
                      showSalary={true}
                      showContractPeriod={true}
                      showEmployeeTelephone={false}
                    />

                    <BasicDetail
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                      showHeader={false}
                      showReference={false}
                      showNationality={false}
                      showPassportNumber={false}
                      showReligion={false}
                      showDateOfBirth={false}
                      showAge={true}
                      showPlaceOfBirth={false}
                      showMaritalStatus={false}
                      showNumberOfChildren={false}
                      showWeightHeight={false}
                      showComplexion={false}
                      showEducation={false}
                      showLivingTown={false}
                      showEmployeeTelephone={false}
                      showContactName={false}
                      showContactTelephone={false}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item md={7} xs={12}>
            {cvTemplate && currentEmployee && (
              <>
                <BasicDetail
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  showHeader={true}
                  showReference={false}
                  showNationality={true}
                  showPassportNumber={false}
                  showReligion={true}
                  showDateOfBirth={true}
                  showAge={false}
                  showPlaceOfBirth={true}
                  showMaritalStatus={true}
                  showNumberOfChildren={true}
                  showWeightHeight={false}
                  showComplexion={false}
                  showEducation={true}
                  showLivingTown={false}
                  showEmployeeTelephone={true}
                  showContactName={false}
                  showContactTelephone={false}
                />
                <BasicPassport
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  showHeader={true}
                  showPassportNumber={true}
                  showIssueDate={true}
                  showPlaceOfIssue={true}
                  showExpiryDate={true}
                />
                <BasicExperience
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  type="onlyOneExperienceFull"
                />
              </>
            )}
          </Grid>
          <Grid item md={5} xs={12}>
            {cvTemplate && currentEmployee && (
              <Stack
                spacing={cvTemplate.spacing}
                sx={{
                  alignItems: "center",
                }}
              >
                <BasicStand
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              </Stack>
            )}
          </Grid>

          <Grid item xs={7}>
            {cvTemplate && currentEmployee && (
              <BasicSkillsOneLineHorzStar
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item xs={5}>
            {cvTemplate && currentEmployee && (
              <BasicLanguageStar
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={false}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicFooter
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0.80)),url(/android-chrome-512x512.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default SkillStarTemplate;
