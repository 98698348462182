import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, useTheme } from "@mui/material";
import { ColorButton, StyledLink } from "../../styles/componentStyled";
import { SignIn } from "../auth";

export default function Header() {
  //const classes = useStyles();
  const theme = useTheme();

  const { t: tt } = useTranslation();
  return (
    <Grid
      container
      spacing={3}
      id="home"
      sx={{ color: "white", width: "100%", px: 0 }}
    >
      <Grid
        item
        xs={12}
        // sm={12}
        // sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Stack data-aos="fade-down" alignItems="center" sx={{ width: "100%" }}>
          <Typography
            variant="h2"
            component="h2"
            color="white"
            sx={{ textAlign: "center", textTransform: "uppercase" }}
          >
            {tt("welcomeToCvPlus")}
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            color="white"
            sx={{ textAlign: "center", textTransform: "uppercase" }}
          >
            {tt("allInOne")}
          </Typography>
        </Stack>
      </Grid>

      {/* <Grid item xs={false} md={7} sx={{ display: { xs: "none", md: "flex" } }}>
        <Stack
          spacing={2}
          data-aos="fade-up"
          sx={{
            alignItems: "center",
          }}
        >
          <img alt="Logo" height="500px" src="../../../personal-data-712.svg" />
        </Stack>
      </Grid> */}
      <Grid item xs={12}>
        <SignIn />
      </Grid>
      <Grid item xs={12}>
        <StyledLink
          aria-label="Share Link"
          href={`https://wa.me/+966556594373`}
          target={"_blank"}
          sx={{
            padding: "3px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <img
            alt="Chat on WhatsApp"
            src="/WhatsAppButtonWhiteLarge.svg"
            width="160px"
            height="40px"
          />
        </StyledLink>
      </Grid>
    </Grid>
  );
}
