import Grid from "@mui/material/Grid";
import { Box, Stack, Typography } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostAppliedEnglish } from "./components/basic/BasicPostApplied";
import { BasicDetailEnglish } from "./components/basic/BasicDetail";
import {
  BasicLanguageEnglish,
  BasicLanguageEnglishNoHeader,
} from "./components/basic/BasicLanguage";
import { BasicExperienceEnglish } from "./components/basic/BasicExperience";
import { BasicPassportEnglish } from "./components/basic/BasicPassport";
import { BasicFullNameEnglish } from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicReferenceEnglish } from "./components/basic/BasicReference";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicFooter, BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function JewelTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  //a08745;f2f1f0 fffcf2
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            sx={{
              borderColor: cvTemplate.borderColor,
              borderWidth: cvTemplate.borderWidth as number,
              borderStyle: cvTemplate.borderStyle,
            }}
            justifyContent="center"
            alignItems="center"
          >
            {/* Short Photo */}
            {/* <Grid item xs={1}></Grid> */}
            <Grid item xs={3}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>

            <Grid container item xs={9}>
              <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                <BasicReferenceEnglish
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
                <img
                  style={{ margin: "auto", marginTop: "5px" }}
                  width="75%"
                  height="250px"
                  src="/jewelLogo.png"
                  alt="office logo"
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicPostAppliedEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={false}
                showProfession={true}
                showSalary={false}
                showContractPeriod={false}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicFullNameEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetailEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={false}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={true}
                showPlaceOfBirth={true}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={true}
                showComplexion={true}
                showEducation={false}
                showLivingTown={false}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicExperienceEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
                type="entrance"
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicLanguageEnglishNoHeader
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={true}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicLanguageEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={false}
              />
            )}
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                p: 1,
                fontWeight: 900,
                borderColor: cvTemplate.borderColor,
                boxShadow: cvTemplate.headerShadow,
                borderWidth: cvTemplate.borderWidth as number,
                borderStyle: cvTemplate.borderStyle,
              }}
            >
              HOUSEHOLD SKILLS
              <br /> HOUSEKEEPING
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              sx={{
                alignItems: "center",
              }}
            >
              {cvTemplate && currentEmployee && (
                <BasicPassportEnglish
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  showHeader={true}
                  showPassportNumber={true}
                  showIssueDate={true}
                  showPlaceOfIssue={true}
                  showExpiryDate={true}
                />
              )}
              <Stack direction="row" spacing={1}>
                {cvTemplate && currentEmployee && (
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <BasicFooter
              selectedCompany={selectedCompany}
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default JewelTemplate;
