import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { apolloClient } from "../../apollo/graphql";
import {
  AgeCategory,
  Complexion,
  CountriesList,
  CurrencyTypes,
  Education,
  EducationLevel,
  Employee,
  EmployeeArgs,
  EmployeeStatus,
  EmployeesWithCount,
  ExperienceYears,
  LanguageExperience,
  MaritalStatus,
  Nationalities,
  NotificationArgs,
  NotificationsWithCount,
  PhotoTypes,
  ProcessState,
  ProfessionalTypes,
  ReligionTypes,
  RequiredDocuments,
  Sex,
  ShareByEmail,
  ShortLink,
  Skills,
  Visa,
  VisaArgs,
  VisaStatus,
  Notification,
  WorkExperience,
} from "./types/processTypes";
import {
  CHECK_STATUS_CAPTCHA,
  GET_ALL_EMPLOYEES,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_SHORT_LINKS,
  GET_ALL_VISAS,
  GET_LINKED_EMPLOYEES,
  GET_SELECTED_EMPLOYEE,
  GET_SELECTED_VISA,
  GET_SHARED_EMPLOYEES,
} from "../../apollo/queries";
import {
  RecaptchaCheckStatus,
  RejectWithValueType,
  UploadGraphQL,
  UploadGraphQLResult,
} from "../auth/types/authType";
import {
  ADD_UPDATE_EMPLOYEE,
  ADD_UPDATE_NOTIFICATION,
  ADD_UPDATE_VISA,
  GENERATE_LINK,
  LINK_EMPLOYEE_VISA,
  MARK_AS_READ_NOTIFICATION,
  REMOVE_EMPLOYEE,
  REMOVE_NOTIFICATION,
  REMOVE_VISA,
  SHARE_BY_EMAIL,
  SINGLE_UPLOAD_MUTATION,
} from "../../apollo/mutations";
import { Address, Company } from "../setups/types/setupTypes";
import { addYears } from "date-fns";

export const shareByEmail = createAsyncThunk<
  any,
  ShareByEmail,
  { rejectValue: RejectWithValueType }
>("processes/shareByEmail", async (employeeArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const { email, link } = employeeArgs;
    const response = await apolloClient.mutate({
      mutation: SHARE_BY_EMAIL,
      variables: { email, link },
    });

    if (response && response.data && response.data.shareLinkByEmail) {
      return response.data.shareLinkByEmail as ShareByEmail;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const linkedEmployees = createAsyncThunk<
  any,
  EmployeeArgs,
  { rejectValue: RejectWithValueType }
>("processes/linkedEmployees", async (employeeArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const { refreshList, agencyId, agentId, status } = employeeArgs;
    const fetchPolicy =
      refreshList === "refresh" ? "network-only" : "cache-first";
    console.log(agencyId, agentId);
    const response = await apolloClient.query({
      query: GET_LINKED_EMPLOYEES,
      variables: { agencyId, agentId, status },
      fetchPolicy,
    });

    if (response && response.data && response.data.linkedEmployees) {
      //console.log(response.data.linkedEmployees);
      return response.data.linkedEmployees as EmployeesWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const sharedEmployees = createAsyncThunk<
  any,
  EmployeeArgs,
  { rejectValue: RejectWithValueType }
>("processes/sharedEmployees", async (employeeArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const { refreshList, link } = employeeArgs;
    const fetchPolicy =
      refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_SHARED_EMPLOYEES,
      variables: { link },
      fetchPolicy,
    });

    if (response && response.data && response.data.sharedEmployees) {
      return response.data.sharedEmployees as EmployeesWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const generateLink = createAsyncThunk<
  any,
  ShortLink,
  { rejectValue: RejectWithValueType }
>("processes/generateLink", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.mutate({
      mutation: GENERATE_LINK,
      variables: {
        ids: arg.employeeIds,
        headerId: arg.headerId,
      },
    });

    if (response && response.data && response.data.generateLink) {
      return response.data.generateLink as ShortLink;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const fetchShortLinks = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/fetchShortLinks", async (employeeArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await apolloClient.query({
      query: GET_ALL_SHORT_LINKS,
    });

    if (response && response.data && response.data.shortLinks) {
      return response.data.shortLinks as ShortLink[];
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const fetchEmployees = createAsyncThunk<
  any,
  EmployeeArgs,
  { rejectValue: RejectWithValueType }
>("processes/fetchEmployees", async (employeeArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    //const { refreshList } = employeeArgs;
    //console.log(employeeArgs);
    const fetchPolicy = "network-only";
    //refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_ALL_EMPLOYEES,
      variables: { ...employeeArgs },
      fetchPolicy,
    });

    if (response && response.data && response.data.employees) {
      return response.data.employees as EmployeesWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const getEmployee = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/getEmployee", async (employeeId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.query({
      query: GET_SELECTED_EMPLOYEE,
      variables: { id: employeeId },
    });
    if (response && response.data && response.data.getEmployee) {
      return response.data.getEmployee as Employee;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const createEmployee = createAsyncThunk<
  any,
  Employee,
  { rejectValue: RejectWithValueType }
>("processes/createEmployee", async (employee, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const emp = { ...employee } as Employee;

  //console.log(employee._id);
  const {
    address,
    contactPerson,
    education,
    visa,
    workExperience,
    additionalData,
    documents,
    owner,
    partner,
    parentCompany,
    prospects,
    embassyProcess,
    flight,
  } = emp;
  let enjazNumber, stampedDate;
  if (embassyProcess) {
    enjazNumber = embassyProcess.enjazNumber;
    stampedDate = embassyProcess.stampedDate;
  }
  let flightDate, arrivedDate;
  if (flight) {
    flightDate = flight.flightDateTime;
    arrivedDate = flight.arrivedDateTime;
  }
  let city, telephone, alternateTelephone, employAddress, country;
  if (address) {
    city = address.city;
    telephone = address.telephone;
    alternateTelephone = address.alternateTelephone;
    employAddress = address.streetAddress;
    country = address.country;
  }

  let displayName,
    kinship,
    cityC,
    telephoneC,
    addressC,
    alternateTelephoneC,
    countryC;

  if (contactPerson) {
    displayName = contactPerson.displayName;
    kinship = contactPerson.kinship;
    if (contactPerson.address) {
      cityC = contactPerson.address.city;
      addressC = contactPerson.address.streetAddress;
      telephoneC = contactPerson.address.telephone;
      alternateTelephoneC = contactPerson.address.alternateTelephone;
      countryC = contactPerson.address.country;
    }
  }
  let religion,
    nationality,
    pastNationality,
    maritalStatus,
    appliedCountry,
    appliedProfession,
    contractPeriod,
    complexion,
    documentReceivedDate,
    salary,
    currency,
    numberOfChildren,
    notes,
    height,
    weight,
    fullNameAmharic,
    lmisId,
    lmisPassword,
    labourId,
    discontinuedDate,
    afterFlightStatusDate,
    afterFlightStatusRemark;

  if (additionalData) {
    nationality = additionalData.nationality;
    pastNationality = additionalData.pastNationality;
    maritalStatus = additionalData.maritalStatus;
    religion = additionalData.religion;
    appliedCountry = additionalData.appliedCountry;
    appliedProfession = additionalData.appliedProfession;
    contractPeriod = additionalData.contractPeriod;
    complexion = additionalData.complexion;
    documentReceivedDate = additionalData.documentReceivedDate;
    salary = additionalData.salary;
    currency = additionalData.currency;
    numberOfChildren = additionalData.numberOfChildren;
    notes = additionalData.notes;
    height = additionalData.height;
    weight = additionalData.weight;
    fullNameAmharic = additionalData.fullNameAmharic;
    lmisId = additionalData.lmisId;
    lmisPassword = additionalData.lmisPassword;
    labourId = additionalData.labourId;
    afterFlightStatusDate = additionalData.afterFlightStatusDate;
    afterFlightStatusRemark = additionalData.afterFlightStatusRemark;
    discontinuedDate = additionalData.discontinuedDate;
  }

  let visaId,
    contractNumber,
    visaNumber,
    fullName,
    sponsorFullNameAmharic,
    cityAmharic,
    passportNumber,
    sponsorBirthDate,
    visaType;
  if (visa) {
    visaId = visa._id;
    contractNumber = visa.contractNumber;
    visaNumber = visa.visaNumber;
    fullName = visa.fullName;
    sponsorFullNameAmharic = visa.fullNameAmharic;
    cityAmharic = visa.cityAmharic;
    passportNumber = visa.passportNumber;
    sponsorBirthDate = visa.sponsorBirthDate;
    visaType = visa.visaType;
  }
  let sponsorTelephone, sponsorCity, sponsorCountry;
  if (visa && visa.address) {
    sponsorTelephone = visa.address.telephone;
    sponsorCity = visa.address.city;
    sponsorCountry = visa.address.country;
  }
  const {
    arabicLanguage,
    englishLanguage,
    educationLevel,
    skills,
    fieldOfStudy,
    yearCompleted,
    educationQG,
    remarks,
  } = education as Education;
  const workExp = workExperience?.map((w) => {
    return { country: w.country, profession: w.profession, years: w.years };
  }) as [WorkExperience];
  const empSkills: [Skills] = skills?.map((sk) => sk) as [Skills];

  const prospect = prospects?.map((p) => {
    return {
      _id: p?._id,
      type: p?.type,
      displayName: p?.displayName,
      email: p?.email,
    };
  }) as [Company];

  const requiredDocuments: [RequiredDocuments] = documents?.map((sk) => sk) as [
    RequiredDocuments
  ];

  try {
    const response = await apolloClient.mutate({
      mutation: ADD_UPDATE_EMPLOYEE,
      variables: {
        _id: emp._id,
        status: emp.status,
        currentStatusDate: emp.currentStatusDate,
        currentStatusRemark: emp.currentStatusRemark,
        passportNumber: emp.passportNumber?.toUpperCase(),
        placeOfBirth: emp.placeOfBirth?.toUpperCase(),
        placeOfIssue: emp.placeOfIssue?.toUpperCase(),
        givenName: emp.givenName?.toUpperCase(),
        surName: emp.surName?.toUpperCase(),
        notificationMessage: emp.notificationMessage,
        medicalCompleted: emp.medicalCompleted,
        cocCompleted: emp.cocCompleted,
        biometricsCompleted: emp.biometricsCompleted,
        visaIssued: emp.visaIssued,
        labourIssued: emp.labourIssued,
        ticketBooked: emp.ticketBooked,
        sex: emp.sex,
        dateOfBirth: new Date(emp.dateOfBirth as Date).setHours(14, 0, 0, 0),
        selectionDueDate:
          emp.selectionDueDate && new Date(emp.selectionDueDate as Date),
        passportIssueDate: new Date(emp.passportIssueDate as Date).setHours(
          14,
          0,
          0,
          0
        ),
        passportExpiryDate: new Date(emp.passportExpiryDate as Date).setHours(
          14,
          0,
          0,
          0
        ),
        documents: requiredDocuments,
        address: address && {
          city: city?.toUpperCase(),
          telephone: telephone?.trim(),
          country,
          streetAddress: employAddress?.toUpperCase(),
          alternateTelephone,
        },
        contactPerson: contactPerson && {
          displayName: displayName?.toUpperCase(),
          kinship: kinship?.toUpperCase(),
          address: contactPerson.address && {
            city: cityC?.toUpperCase(),
            streetAddress: addressC?.toUpperCase(),
            telephone: telephoneC?.trim(),
            alternateTelephone: alternateTelephoneC,
            country: countryC,
          },
        },
        additionalData: additionalData && {
          nationality,
          pastNationality,
          maritalStatus,
          religion,
          appliedCountry,
          appliedProfession,
          contractPeriod,
          complexion,
          documentReceivedDate,
          salary,
          currency,
          numberOfChildren,
          notes,
          height,
          weight,
          fullNameAmharic,
          lmisId,
          lmisPassword,
          labourId,
          discontinuedDate,
          afterFlightStatusDate,
          afterFlightStatusRemark,
        },
        education: {
          arabicLanguage,
          englishLanguage,
          educationLevel,
          skills: empSkills,
          fieldOfStudy,
          yearCompleted,
          educationQG,
          remarks,
        },
        workExperience: workExp,
        visa: visa &&
          visa.visaNumber &&
          visa.visaNumber.length >= 5 && {
            _id: visaId,
            contractNumber,
            visaNumber: visaNumber?.trim(),
            fullName: fullName?.toUpperCase(),
            fullNameAmharic: sponsorFullNameAmharic,
            cityAmharic,
            passportNumber: passportNumber?.trim(),
            sponsorBirthDate: sponsorBirthDate?.trim(),
            wekalaDate: "",
            visaType,
            address: visa.address && {
              telephone: sponsorTelephone?.trim(),
              city: sponsorCity?.toUpperCase(),
              country: sponsorCountry,
            },
            owner: {
              _id: owner?._id,
              type: owner?.type,
              displayName: owner?.displayName,
              email: owner?.email,
            },
          },
        embassyProcess: embassyProcess && {
          enjazNumber: enjazNumber?.trim(),
          stampedDate,
        },
        flight: flight && {
          flightDateTime: flightDate,
          arrivedDateTime: arrivedDate,
        },
        owner: {
          _id: owner?._id,
          type: owner?.type,
          displayName: owner?.displayName,
          email: owner?.email,
        },
        partner: partner && {
          _id: partner?._id,
          type: partner?.type,
          displayName: partner?.displayName,
          email: partner?.email,
        },
        parentCompany: parentCompany && {
          _id: parentCompany?._id,
          type: parentCompany?.type,
          displayName: parentCompany?.displayName,
          email: parentCompany?.email,
        },
        prospects: prospect,
      },
    });

    if (response && response.data && response.data.createEmployee) {
      await setSuccessAction(dispatch, {
        message: "saved successfully",
      });
      return response.data.createEmployee as Employee;
    }
    //return [];
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const assignEmployeeVisa = createAsyncThunk<
  any,
  string[],
  { rejectValue: RejectWithValueType }
>("processes/assignEmployeeVisa", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    console.log(arg);
    const response = await apolloClient.mutate({
      mutation: LINK_EMPLOYEE_VISA,
      variables: { ids: arg },
    });

    if (response && response.data && response.data.assignEmployeeVisa) {
      //return response.data.linkCompanies as Company;
      const linkedCompany = (await response.data
        .assignEmployeeVisa) as Employee;

      await setSuccessAction(dispatch, {
        message: "Visa Successfully Linked",
        setupType: "Visa",
      });

      return linkedCompany;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const checkStatusRecaptcha = createAsyncThunk<
  any,
  RecaptchaCheckStatus,
  { rejectValue: RejectWithValueType }
>("processes/checkStatusRecaptcha", async (captchaCheckStatus, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const { searchText, captcha } = captchaCheckStatus;

  try {
    const response = await apolloClient.mutate({
      mutation: CHECK_STATUS_CAPTCHA,
      variables: {
        searchText: searchText,
        captcha: captcha,
      },
    });

    if (response && response.data && response.data.recaptchaCheckStatus) {
      const recap = (await response.data.recaptchaCheckStatus) as Employee;
      await setSuccessAction(dispatch, {
        message: recap.givenName,
      });
      return recap;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const uploadPicture = createAsyncThunk<
  any,
  UploadGraphQL,
  { rejectValue: RejectWithValueType }
>("processes/uploadPicture", async (uploadInput, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const { _id, avatar, type } = uploadInput;
  try {
    if (avatar.size > 1000000) {
      const message = "File size can't exceeds 1Mb";
      await setErrorAction(dispatch, { message });
      return rejectWithValue({ message });
    } else {
      const response = await apolloClient.mutate({
        mutation: SINGLE_UPLOAD_MUTATION,
        variables: {
          _id: _id,
          type: type,
          avatar: avatar,
        },
      });

      if (response && response.data && response.data.uploadPicture) {
        await setSuccessAction(dispatch, {
          message: "uploaded successfully",
        });
        return response.data.uploadPicture as UploadGraphQLResult;
      }
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const removeEmployee = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/removeEmployee", async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.mutate({
      mutation: REMOVE_EMPLOYEE,
      variables: { id },
    });

    if (response && response.data && response.data.deleteEmployee) {
      await setSuccessAction(dispatch, {
        message: "Employee Successfully Deleted",
        setupType: "Employee",
      });
      return id as string;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});

export const fetchVisas = createAsyncThunk<
  any,
  VisaArgs,
  { rejectValue: RejectWithValueType }
>("processes/fetchVisas", async (visaArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const { refreshList } = visaArgs;
    const fetchPolicy =
      refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_ALL_VISAS,
      variables: { ...visaArgs },
      fetchPolicy,
    });

    if (response && response.data && response.data.visas) {
      return response.data.visas as Visa[];
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const getVisa = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/getVisa", async (visaId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.query({
      query: GET_SELECTED_VISA,
      variables: { id: visaId },
    });
    if (response && response.data && response.data.getVisa) {
      return response.data.getVisa as Visa;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const createVisa = createAsyncThunk<
  any,
  Visa,
  { rejectValue: RejectWithValueType }
>("processes/createVisa", async (vi, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  const visa = { ...vi } as Visa;
  const { address, owner } = visa;
  const { city, telephone, country } = address as Address;
  //console.log(visa);
  try {
    const response = await apolloClient.mutate({
      mutation: ADD_UPDATE_VISA,
      variables: {
        _id: visa._id,
        passportNumber: visa.passportNumber,
        fullName: visa.fullName,
        visaNumber: visa.visaNumber,
        visaQty: 1,
        firstTime: visa.firstTime,
        goodLooking: visa.goodLooking,
        writeRead: visa.writeRead,
        profession: visa.profession,
        religion: visa.religion,
        ageCategory: visa.ageCategory,
        complexion: visa.complexion,
        salary: visa.salary,
        currency: visa.currency,
        remarks: visa.remarks,
        status: VisaStatus.new,
        visaType: visa.visaType,
        address: { city, telephone, country },
        owner: {
          _id: owner?._id,
          type: owner?.type,
          displayName: owner?.displayName,
          email: owner?.email,
        },
      },
    });

    if (response && response.data && response.data.createVisa) {
      await setSuccessAction(dispatch, {
        message: "saved successfully",
      });
      return response.data.createVisa as Visa;
    }
    //return [];
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const removeVisa = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("setups/removeVisa", async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.mutate({
      mutation: REMOVE_VISA,
      variables: { id },
    });

    if (response && response.data && response.data.deleteVisa) {
      await setSuccessAction(dispatch, {
        message: "Visa Successfully Removed",
        setupType: "Visa",
      });
      return id as string;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const fetchNotifications = createAsyncThunk<
  any,
  NotificationArgs,
  { rejectValue: RejectWithValueType }
>("processes/fetchNotifications", async (notificationArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    //const { refreshList } = notificationArgs;
    const fetchPolicy = "network-only";
    //refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_ALL_NOTIFICATIONS,
      variables: { ...notificationArgs },
      fetchPolicy,
    });

    if (response && response.data && response.data.findNotifications) {
      return response.data.findNotifications as NotificationsWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const removeNotification = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/removeNotification", async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.mutate({
      mutation: REMOVE_NOTIFICATION,
      variables: { id },
    });

    if (response && response.data && response.data.deleteNotification) {
      await setSuccessAction(dispatch, {
        message: "Notification Successfully Deleted",
        setupType: "Notification",
      });
      return id as string;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const createNotification = createAsyncThunk<
  any,
  Notification,
  { rejectValue: RejectWithValueType }
>("processes/createNotification", async (notify, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  //const notify = { ...vi } as Notification;
  //const { employee } = notify;
  // console.log(notify);
  try {
    const response = await apolloClient.mutate({
      mutation: ADD_UPDATE_NOTIFICATION,
      variables: {
        _id: notify._id,
        message: notify.message,
        priority: notify.priority,
        status: notify.status,
      },
    });

    if (response && response.data && response.data.createNotification) {
      await setSuccessAction(dispatch, {
        message: "saved successfully",
      });
      return response.data.createNotification as Notification;
    }
    //return [];
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const markAsReadNotification = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("processes/markAsReadNotification", async (employeeId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.query({
      query: MARK_AS_READ_NOTIFICATION,
      variables: { id: employeeId },
    });
    if (response && response.data && response.data.markAsReadNotification) {
      return response.data.markAsReadNotification as Notification;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
async function setSuccessAction(
  dispatch: ThunkDispatch<any, any, any>,
  payload: any
) {
  dispatch(setSuccess(payload));
  setTimeout(() => {
    switch (payload.setupType) {
      case "Employee":
        dispatch(resetSelectedEmployee());
        break;
      case "Visa":
        dispatch(resetSelectedVisa());
        break;
    }
    dispatch(resetSuccess());
  }, 2000);
}
async function setErrorAction(
  dispatch: ThunkDispatch<any, any, any>,
  payload: any
) {
  dispatch(setError(payload));
  setTimeout(() => {
    dispatch(resetError());
  }, 6000);
}
const defaultSkills = [
  Skills.washing_dishes,
  Skills.washing_ironing_clothes,
  Skills.dusting_cleaning,
  Skills.baby_sitting,
  Skills.children_care,
];
const defaultEmployee: Employee = {
  givenName: "",
  surName: "",
  sex: Sex.female,
  status: EmployeeStatus.new,

  cocCompleted: false,
  medicalCompleted: false,
  visaIssued: false,
  labourIssued: false,
  ticketBooked: false,
  placeOfBirth: "",
  placeOfIssue: "ADDIS ABABA",
  passportNumber: "",
  dateOfBirth: addYears(new Date(), -25),
  passportIssueDate: new Date(),
  passportExpiryDate: addYears(new Date(), 5),
  documents: [RequiredDocuments.passport],
  address: { city: "", telephone: "", country: CountriesList.ethiopia },
  contactPerson: {
    displayName: "",
    kinship: "",
    address: { city: "", telephone: "", country: CountriesList.ethiopia },
  },
  additionalData: {
    nationality: Nationalities.ethiopian,
    pastNationality: Nationalities.ethiopian,
    maritalStatus: MaritalStatus.single,
    religion: ReligionTypes.muslim,
    appliedCountry: CountriesList.saudi_arabia,
    appliedProfession: ProfessionalTypes.housemaid,
    contractPeriod: ExperienceYears.two,
    complexion: Complexion.brown,
    salary: 1000,
    currency: CurrencyTypes.saudi_riyal,
    numberOfChildren: 0,
    height: "",
    weight: "",
    notes: "",
  },
  education: {
    arabicLanguage: LanguageExperience.poor,
    englishLanguage: LanguageExperience.poor,
    educationLevel: EducationLevel.grade_eight_complete,
    skills: defaultSkills,
    educationQG: "",
    fieldOfStudy: "",
    yearCompleted: "",
    remarks: "",
  },
};
const defaultVisa: Visa = {
  visaNumber: "",
  passportNumber: "",
  fullName: "",
  wekalaDate: "",
  address: { city: "", telephone: "", country: CountriesList.saudi_arabia },
  profession: ProfessionalTypes.housemaid,
  religion: ReligionTypes.muslim,
  ageCategory: AgeCategory.bet2125,
  complexion: Complexion.acceptable,
  salary: 1000,
  currency: CurrencyTypes.saudi_riyal,
  remarks: "",
  firstTime: false,
  goodLooking: false,
  writeRead: false,
};

const defaultShortLink: ShortLink = {
  link: "",
};
const defaultCompany: Company = {
  displayName: "",
  email: "",
  licenseNumber: "",
  address: {
    mobile: "",
    telephone: "",
    email: "",
    country: CountriesList.ethiopia,
  },
};
const initialProcessesState: ProcessState = {
  employeesWithCount: {
    totalCount: 0,
    employees: [],
    header: { ...defaultCompany },
  },
  visasWithCount: { totalCount: 0, visas: [] },
  notificationsWithCount: { totalCount: 0, notifications: [] },
  sharedLinks: [],
  selectedEmployee: { ...defaultEmployee },
  selectedVisa: { ...defaultVisa },
  selectedShortLink: { ...defaultShortLink },
  shortLinkHeader: { ...defaultCompany },
  loading: "idle",
  currentRequestId: undefined,
  success: null,
  error: null,
};

export const setupsSlice = createSlice({
  name: "processes",
  initialState: initialProcessesState,
  reducers: {
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    resetSuccess: (state) => {
      state.success = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      if (payload.message.includes("authorized")) {
        localStorage.removeItem("userInfo");
      }
    },
    resetError: (state) => {
      state.error = null;
    },
    resetSelectedEmployee: (state) => {
      state.selectedEmployee = {
        ...defaultEmployee,
      };
    },
    setSelectedEmployee: (state, { payload }) => {
      state.selectedEmployee = payload;
    },
    resetSelectedVisa: (state) => {
      state.selectedVisa = {
        ...defaultVisa,
      };
    },
    setSelectedVisa: (state, { payload }) => {
      state.selectedVisa = payload;
    },

    setEmployees: (state, { payload }) => {
      state.employeesWithCount = payload;
    },
    setVisas: (state, { payload }) => {
      state.visasWithCount = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedEmployees.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(sharedEmployees.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.employeesWithCount.employees = payload.employees;
      state.employeesWithCount.totalCount = payload.totalCount;
      state.employeesWithCount.header = payload.header;
    });
    builder.addCase(sharedEmployees.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(linkedEmployees.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(linkedEmployees.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.employeesWithCount = payload;
    });
    builder.addCase(linkedEmployees.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(shareByEmail.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(shareByEmail.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.success = { message: "Email Sent Successfully" };
    });
    builder.addCase(shareByEmail.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(generateLink.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(generateLink.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      //state.success = payload;
      state.selectedShortLink = payload;
      state.sharedLinks.unshift(payload);
    });
    builder.addCase(generateLink.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(fetchShortLinks.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchShortLinks.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.sharedLinks = payload;
    });
    builder.addCase(fetchShortLinks.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.notificationsWithCount = payload;
    });
    builder.addCase(fetchNotifications.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(fetchEmployees.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchEmployees.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.employeesWithCount = payload;
    });
    builder.addCase(fetchEmployees.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(getEmployee.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getEmployee.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedEmployee = payload;
    });
    builder.addCase(getEmployee.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(assignEmployeeVisa.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(assignEmployeeVisa.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedEmployee = payload;
    });
    builder.addCase(assignEmployeeVisa.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(checkStatusRecaptcha.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(checkStatusRecaptcha.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedEmployee = payload;
    });
    builder.addCase(checkStatusRecaptcha.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(createEmployee.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createEmployee.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedEmployee = payload;
      state.employeesWithCount.employees =
        state.employeesWithCount.employees?.filter(
          (c) => c._id !== payload._id
        );
      state.employeesWithCount.employees.unshift(payload);
      state.employeesWithCount.totalCount++;
    });
    builder.addCase(createEmployee.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(uploadPicture.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(uploadPicture.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      const photoUrl = payload.avatar as string;
      const type = payload.type as PhotoTypes;
      if (state.selectedEmployee) {
        if (type === PhotoTypes.Face)
          state.selectedEmployee.facePhoto = photoUrl;
        else if (type === PhotoTypes.Stand)
          state.selectedEmployee.standPhoto = photoUrl;
        else if (type === PhotoTypes.Passport)
          state.selectedEmployee.passportScan = photoUrl;
      }
    });
    builder.addCase(uploadPicture.rejected, (state, { payload }) => {
      state.loading = "idle";
    });
    builder.addCase(removeEmployee.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(removeEmployee.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.employeesWithCount.totalCount =
        (state.employeesWithCount.totalCount as number) - 1;
      state.employeesWithCount.employees =
        state.employeesWithCount.employees.filter((h) => h._id !== payload);
    });
    builder.addCase(removeEmployee.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(fetchVisas.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchVisas.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.visasWithCount = payload;
    });
    builder.addCase(fetchVisas.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(getVisa.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getVisa.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedVisa = payload;
    });
    builder.addCase(getVisa.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(createVisa.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createVisa.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedVisa = payload;
      state.visasWithCount.visas = state.visasWithCount.visas?.filter(
        (c) => c._id !== payload._id
      );
      state.visasWithCount.visas.unshift(payload);
      state.visasWithCount.totalCount++;
    });
    builder.addCase(createVisa.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(removeVisa.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(removeVisa.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.visasWithCount.totalCount =
        (state.visasWithCount.totalCount as number) - 1;
      state.visasWithCount.visas = state.visasWithCount.visas.filter(
        (h) => h._id !== payload
      );
    });
    builder.addCase(removeVisa.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(removeNotification.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(removeNotification.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.notificationsWithCount.totalCount =
        (state.notificationsWithCount.totalCount as number) - 1;
      state.notificationsWithCount.notifications =
        state.notificationsWithCount.notifications.filter(
          (h) => h._id !== payload
        );
    });
    builder.addCase(removeNotification.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(markAsReadNotification.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(markAsReadNotification.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.notificationsWithCount.totalCount =
        (state.notificationsWithCount.totalCount as number) - 1;
      state.notificationsWithCount.notifications =
        state.notificationsWithCount.notifications.filter(
          (h) => h._id !== payload
        );
    });
    builder.addCase(markAsReadNotification.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(createNotification.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createNotification.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.notificationsWithCount.totalCount =
        (state.notificationsWithCount.totalCount as number) - 1;
      state.notificationsWithCount.notifications =
        state.notificationsWithCount.notifications.filter(
          (h) => h._id !== payload._id
        );
    });
    builder.addCase(createNotification.rejected, (state) => {
      state.loading = "idle";
    });

    // builder.addCase(addEmployeeVisa.pending, (state) => {
    //   state.loading = "pending";
    // });
    // builder.addCase(addEmployeeVisa.fulfilled, (state, { payload }) => {
    //   state.loading = "idle";
    //   state.selectedEmployee = payload;
    // });
    // builder.addCase(addEmployeeVisa.rejected, (state) => {
    //   state.loading = "idle";
    // });
  },
});
const { actions, reducer } = setupsSlice;
export const {
  resetSuccess,
  setSuccess,
  resetError,
  setError,

  setSelectedEmployee,
  resetSelectedEmployee,
  setSelectedVisa,
  resetSelectedVisa,
  setEmployees,
  setVisas,
} = actions;

export default reducer;

// Selectors
export const selectProcesses = (state: RootState) =>
  state.processes as ProcessState;
