import { gql } from "@apollo/client";
import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_ONLY,
  GET_VISA,
} from "../fragments/processes";
import { GET_COMPANY } from "../fragments/setup";

export const GET_ALL_EMPLOYEES = gql`
  ${GET_EMPLOYEE}
  query GetEmployees(
    $searchText: String
    $status: EmployeeStatus
    $nationality: Nationalities
    $maritalStatus: MaritalStatus
    $religion: ReligionTypes
    $profession: ProfessionalTypes
    $arabic: LanguageExperience
    $english: LanguageExperience
    $ageStart: Int
    $ageEnd: Int
    $experienceYears: Int
    $skip: Int!
    $take: Int!
    $link: String
    $agencyId: String
    $agentId: String
    $cocCompleted: Boolean
    $medicalCompleted: Boolean
    $biometricsCompleted: Boolean
    $visaIssued: Boolean
    $labourIssued: Boolean
    $flightBooked: Boolean
  ) {
    employees(
      skip: $skip
      take: $take
      searchText: $searchText
      status: $status
      nationality: $nationality
      maritalStatus: $maritalStatus
      religion: $religion
      profession: $profession
      arabic: $arabic
      english: $english
      ageStart: $ageStart
      ageEnd: $ageEnd
      experienceYears: $experienceYears
      link: $link
      agencyId: $agencyId
      agentId: $agentId
      cocCompleted: $cocCompleted
      medicalCompleted: $medicalCompleted
      biometricsCompleted: $biometricsCompleted
      visaIssued: $visaIssued
      labourIssued: $labourIssued
      flightBooked: $flightBooked
    ) {
      totalCount
      employees {
        ...getEmployee
      }
    }
  }
`;
export const GET_SHARED_EMPLOYEES = gql`
  ${GET_EMPLOYEE_ONLY}
  query GetSharedEmployees($link: String) {
    sharedEmployees(link: $link) {
      totalCount
      employees {
        ...getEmployeeOnly
      }
      header {
        _id
        displayName
        header
        address {
          mobile
          country
        }
      }
    }
  }
`;
export const GET_LINKED_EMPLOYEES = gql`
  ${GET_EMPLOYEE_ONLY}
  query GetLinkedEmployees(
    $agencyId: String
    $agentId: String
    $status: EmployeeStatus
  ) {
    linkedEmployees(agencyId: $agencyId, agentId: $agentId, status: $status) {
      totalCount
      employees {
        ...getEmployeeOnly
      }
    }
  }
`;
export const GET_ALL_SHORT_LINKS = gql`
  query GetShortLinks {
    shortLinks {
      _id
      link

      employees {
        _id
        givenName
      }
    }
  }
`;
export const GET_ALL_NOTIFICATIONS = gql`
  query GetNotifications(
    $status: NotificationStatus
    $skip: Int!
    $take: Int!
  ) {
    findNotifications(skip: $skip, take: $take, status: $status) {
      totalCount
      notifications {
        _id
        message
        priority
        status
        fromUserEmail
        from {
          _id
          displayName
          header
          nameAmharic
        }
        employee {
          _id
          status
          passportNumber
          notificationMessage
          givenName
          surName
          facePhoto
          standPhoto
          passportScan
          sex
          dateOfBirth
          selectionDueDate
        }
        createdAt
      }
    }
  }
`;

export const GET_SELECTED_EMPLOYEE = gql`
  ${GET_EMPLOYEE}
  query GetSelectedEmployee($id: String!) {
    getEmployee(input: { _id: $id }) {
      ...getEmployee
    }
  }
`;

export const GET_ALL_VISAS = gql`
  ${GET_VISA}
  query GetVisas($searchText: String, $skip: Int!, $take: Int!) {
    visas(skip: $skip, take: $take, searchText: $searchText) {
      totalCount
      visas {
        ...getVisa
      }
    }
  }
`;

export const GET_SELECTED_VISA = gql`
  ${GET_VISA}
  query GetSelectedVisa($id: String!) {
    getVisa(input: { _id: $id }) {
      ...getVisa
    }
  }
`;

export const CHECK_STATUS_CAPTCHA = gql`
  ${GET_EMPLOYEE}
  query recaptchaCheckStatus($searchText: String, $captcha: String) {
    recaptchaCheckStatus(
      input: { searchText: $searchText, captcha: $captcha }
    ) {
      ...getEmployee
    }
  }
`;
