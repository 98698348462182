import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
  removeEmployee,
} from "../process/processSlices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  RemoveRedEye,
  MoreVert,
  FilterList,
  NextPlan,
} from "@mui/icons-material";

import { StyledTableCell, StyledTableRow } from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";
import Toast from "../../components/Layout/Toast";
import { useTranslation } from "react-i18next";
import { selectAuth } from "../auth/authSlice";
import { ClientTypes, Role } from "../auth/types/authType";
import {
  CompletionStatus,
  Employee,
  EmployeeStatus,
  Nationalities,
} from "./types/processTypes";
import { Company } from "../setups/types/setupTypes";
import _ from "lodash";
import { isPrivilegedTransaction } from "../../utils/authUtils";
import { ProcessStages } from "./components/ProcessStages";
import CustomDialog from "../../components/modals/CustomDialog";
import AuthSkeleton from "../auth/AuthSkeleton";
import Search from "../../components/search/Search";
import { selectPreference, toggleThis } from "../preferences/preferencesSlice";
import FilterSidebar from "../../components/filter/FilterSidebar";

export const Employees = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const theme = useTheme();
  const {
    employeesWithCount: { employees, totalCount },
    loading: employeesLoading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  const { user } = useAppSelector(selectAuth);
  const {
    searchText,
    status,
    nationality,
    maritalStatus,
    religion,
    profession,
    arabic,
    english,
    ageStart,
    ageEnd,
    experienceYears,
    agencyId,
    agentId,
    cocCompleted: coc,
    medicalCompleted: medical,
    biometricsCompleted: biometrics,
  } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectCandidates, setSelectCandidates] = useState<boolean[]>([false]);
  const [open, setOpen] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

  const handleClickOpen = (id: string) => {
    setSelectedEmployeeId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const DeleteEmployee = () => {
    setOpen(false);
    dispatch(removeEmployee(selectedEmployeeId));
  };

  const changeCandidateStatus = (employee: Employee) => {
    setSelectedEmployee(employee);
    setOpenChangeStatus(true);
  };
  const dialogCloseChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  useEffect(() => {
    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchEmployees({
        skip: skipRows,
        take: rowsPerPage,
        searchText:
          searchText && searchText.length > 0 ? searchText : undefined,
        status: status && status !== EmployeeStatus.all ? status : undefined,
        nationality,
        maritalStatus,
        religion,
        profession,
        arabic,
        english,
        ageStart,
        ageEnd,
        experienceYears,
        agencyId,
        agentId,
        cocCompleted:
          coc && coc === CompletionStatus.completed
            ? true
            : coc === CompletionStatus.not_completed
            ? false
            : undefined,
        medicalCompleted:
          medical && medical === CompletionStatus.completed
            ? true
            : medical === CompletionStatus.not_completed
            ? false
            : undefined,
        biometricsCompleted:
          biometrics && biometrics === CompletionStatus.completed
            ? true
            : biometrics === CompletionStatus.not_completed
            ? false
            : undefined,
      })
    );
    //}
  }, [
    dispatch,
    searchText,
    status,
    nationality,
    maritalStatus,
    religion,
    profession,
    arabic,
    english,
    ageStart,
    ageEnd,
    experienceYears,
    currentPage,
    rowsPerPage,
    agencyId,
    agentId,
    coc,
    medical,
    biometrics,
  ]); //

  //function filterList() {
  //setEmployeesForFilter(getFilteredList);
  //}
  const handleDrawerOpen = () => {
    dispatch(toggleThis({ type: "Filter", newValue: true }));
  };
  function getDisplayName(company?: Company) {
    if (company) {
      if (theme.direction === "ltr")
        return company.displayName; //.substring(0, 10) + "...";
      else return company.nameAmharic;
    } else return "";
  }

  useEffect(() => {
    setTotal(totalCount as number);
    const checkBoxes = [...Array(totalCount)].map(() => {
      return false;
    });
    setSelectCandidates(checkBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  useEffect(() => {
    const employeeIds: Employee[] = [];
    for (let i = 0; i < totalCount; i++) {
      if (selectCandidates[i] === true)
        employeeIds.push(employees[i] as Employee);
    }
    setSelectedEmployees(employeeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCandidates]);

  return (
    <>
      <Helmet>
        <title> {`${t("employees")} | ${t("platform")}`}</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              justifyItems="center"
            >
              {isPrivilegedTransaction(
                user?.roles as Role[],
                "AddEmployees"
              ) && (
                <Tooltip title="Add New Employee">
                  <Button
                    color="secondary"
                    variant="contained"
                    component={RouterLink}
                    to={`/app/employee/${Math.random()
                      .toString(36)
                      .slice(2, 12)}`}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              )}
              <Search initialValue={searchText} />
              <Tooltip title={t("filter")}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleDrawerOpen}
                  size="large"
                  sx={{
                    marginRight: theme.direction === "ltr" ? "0px" : "-12px",
                    marginLeft: theme.direction === "rtl" ? "0px" : "-12px",
                    // ...(isFilterOpen && { display: "none" }),
                  }}
                >
                  <FilterList />
                </Button>
              </Tooltip>
            </Stack>
          </>

          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {employeesLoading === "pending" ? (
              <AuthSkeleton />
            ) : employees.length === 0 ? (
              <Typography
                alignSelf="center"
                justifyContent="center"
                variant="h6"
                color="white"
                sx={{
                  backgroundColor: "primary.main",
                  p: 1,
                  m: 1,
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                {t("no_data_found") + " ..."}
              </Typography>
            ) : (
              employees.map((row, index) => (
                <TableContainer
                  component={Paper}
                  key={index}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    px: 1.5,
                    pt: 1,
                    mt: 1,
                  }}
                >
                  <Table size="medium" aria-label="simple table">
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "right" }}>
                          {" "}
                          <Typography
                            variant="h5"
                            color="primary"
                            alignSelf="flex-end"
                          >
                            {te(row.status as string)}
                          </Typography>{" "}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                          >
                            <Avatar
                              component={RouterLink}
                              alt="avatar"
                              src={row ? row.facePhoto : ""}
                              sx={{
                                cursor: "pointer",
                                height: 50,
                                width: 50,
                              }}
                              to={
                                user?.company?.type === ClientTypes.LocalAgency
                                  ? `/app/employee/${row._id}`
                                  : `/app/cvp/${row._id}`
                              }
                            />
                            <Stack direction="row" spacing={1}>
                              {row.additionalData?.nationality ===
                              Nationalities.ethiopian ? (
                                <img
                                  alt="ethioFlag"
                                  height="20px"
                                  width="30px"
                                  src="/ethiopiaFlag.png"
                                />
                              ) : row.additionalData?.nationality ===
                                Nationalities.kenyan ? (
                                <img
                                  alt="kenyaFlag"
                                  height="20px"
                                  width="30px"
                                  src="/kenyaFlag.png"
                                />
                              ) : row.additionalData?.nationality ===
                                Nationalities.ugandan ? (
                                <img
                                  alt="ugandaFlag"
                                  height="20px"
                                  width="30px"
                                  src="/ugandaFlag.png"
                                />
                              ) : (
                                <img
                                  alt="philippineFlag"
                                  height="20px"
                                  width="30px"
                                  src="/philippineFlag.png"
                                />
                              )}
                              <Typography
                                variant="h5"
                                color="secondary"
                                textTransform="capitalize"
                              >
                                {te(row.additionalData?.nationality as string)}
                              </Typography>
                            </Stack>{" "}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>
                          <Typography color="primary" variant="h5">
                            {t("name")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          <Typography color="secondary" variant="h5">
                            {row.givenName + " - " + row.passportNumber}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>
                          <>
                            <Typography color="primary" variant="h5">
                              {t("epro")}
                            </Typography>
                            <Typography color="primary" variant="h5">
                              {t("pro")}
                            </Typography>
                          </>
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          {user?.company?.type === ClientTypes.Moderator ? (
                            <>
                              <Typography
                                variant="h5"
                                color="secondary"
                                textTransform="capitalize"
                              >
                                {getDisplayName(row.owner)}
                              </Typography>

                              <Typography
                                variant="h5"
                                color="secondary"
                                textTransform="capitalize"
                              >
                                {getDisplayName(row.partner)}
                              </Typography>
                            </>
                          ) : (
                            <Typography
                              variant="h5"
                              color="secondary"
                              textTransform="capitalize"
                            >
                              {user?.company &&
                              user.company.type === ClientTypes.ForeignAgent
                                ? getDisplayName(row.owner)
                                : getDisplayName(row.partner)}
                            </Typography>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>
                          <Typography color="primary" variant="h5">
                            {t("visa_number")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          <Typography variant="h5" color="secondary">
                            {row.visa?.visaNumber}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>
                          <Typography color="primary" variant="h5">
                            {t("employer")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="h5" color="secondary">
                            {row.visa?.passportNumber && row.visa?.fullName
                              ? `${row.visa?.passportNumber} - ${row.visa?.fullName}`
                              : ""}
                          </Typography>{" "}
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          <Stack direction="row" justifyContent="flex-end">
                            <Tooltip title={t("status")}>
                              <IconButton
                                color="secondary"
                                onClick={() => changeCandidateStatus(row)}
                                size="large"
                              >
                                <NextPlan />
                              </IconButton>
                            </Tooltip>
                            {isPrivilegedTransaction(
                              user?.roles as Role[],
                              "ViewEmployees"
                            ) && (
                              <Tooltip title={t("view") + " " + t("cv")}>
                                <IconButton
                                  color="primary"
                                  component={RouterLink}
                                  to={`/app/cvp/${row._id}`}
                                  size="large"
                                >
                                  <RemoveRedEye />
                                </IconButton>
                              </Tooltip>
                            )}
                            {(row.status === EmployeeStatus.new ||
                              row.status === EmployeeStatus.selected ||
                              row.status === EmployeeStatus.contracted) &&
                              isPrivilegedTransaction(
                                user?.roles as Role[],
                                "EditEmployees"
                              ) && (
                                <Tooltip title={t("edit")}>
                                  <IconButton
                                    color="primary"
                                    component={RouterLink}
                                    to={`/app/employee/${row._id}`}
                                    size="large"
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {(row.status === EmployeeStatus.new ||
                              row.status === EmployeeStatus.selected ||
                              row.status === EmployeeStatus.contracted) &&
                              isPrivilegedTransaction(
                                user?.roles as Role[],
                                "DeleteEmployees"
                              ) && (
                                <Tooltip title={t("delete")}>
                                  <IconButton
                                    color="secondary"
                                    onClick={() =>
                                      handleClickOpen(row._id as string)
                                    }
                                    size="large"
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ))
            )}
          </Box>
          <TableContainer
            component={Paper}
            sx={{ mt: 1, display: { xs: "none", md: "block" } }}
          >
            <Table size="medium" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  {/* <StyledTableCell></StyledTableCell> */}
                  <StyledTableCell
                    width="2%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("sno")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="5%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("photo")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="15%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("name")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="10%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("referenceNumber")}
                  </StyledTableCell>
                  {/* <StyledTableCell>{te("medical")}</StyledTableCell>
                  <StyledTableCell>{te("coc")}</StyledTableCell> */}
                  {user?.company?.type === ClientTypes.Moderator ? (
                    <>
                      <StyledTableCell
                        width="10%"
                        sx={{ textTransform: "uppercase" }}
                      >
                        {t("epro")}
                      </StyledTableCell>
                      <StyledTableCell
                        width="10%"
                        sx={{ textTransform: "uppercase" }}
                      >
                        {t("pro")}
                      </StyledTableCell>
                    </>
                  ) : (
                    <StyledTableCell
                      width="20%"
                      sx={{ textTransform: "uppercase" }}
                    >
                      {t("partner")}
                    </StyledTableCell>
                  )}
                  <StyledTableCell
                    width="5%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("visa_number")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="15%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("employer")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="5%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("status")}
                  </StyledTableCell>
                  <StyledTableCell
                    width="23%"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {t("actions")}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeesLoading === "pending" ? (
                  <TableSkeleton numRows={5} numColumns={9} />
                ) : employees.length === 0 ? (
                  <StyledTableRow key={"noData"}>
                    <StyledTableCell colSpan={7} sx={{ textAlign: "center" }}>
                      <Typography variant="h5" color="secondary">
                        {t("no_data_found")}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  employees.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      {/* <StyledTableCell>
                        <Checkbox
                          checked={
                            selectCandidates[index]
                              ? selectCandidates[index]
                              : false
                          }
                          onChange={(e) => changeCheckBox(e, index)}
                          inputProps={{ "aria-labcontrolled" }}
                          name={index.toString()}
                          color="secondary"
                        />
                      </StyledTableCell> */}
                      <StyledTableCell>
                        <Typography color="primary" variant="h6">
                          {(
                            currentPage * rowsPerPage +
                            index +
                            1
                          ).toLocaleString(
                            theme.direction === "rtl" ? "ar-SA" : "en-US"
                          )}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Avatar
                          component={RouterLink}
                          alt="avatar"
                          src={row ? row.facePhoto : ""}
                          sx={{
                            cursor: "pointer",
                            height: 40,
                            width: 40,
                          }}
                          to={
                            user?.company?.type === ClientTypes.LocalAgency &&
                            (row.status === EmployeeStatus.new ||
                              row.status === EmployeeStatus.contracted)
                              ? `/app/employee/${row._id}`
                              : `/app/cvp/${row._id}`
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: "primary.main", fontWeight: 700 }}
                      >
                        {row.givenName + " - " + row.passportNumber}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: "secondary.main", fontWeight: 700 }}
                      >
                        {row.education?.yearCompleted}
                        {/* <Button
                          color="primary"
                          component={RouterLink}
                          to={
                            user?.company?.type === ClientTypes.LocalAgency &&
                            (row.status === EmployeeStatus.new ||
                              row.status === EmployeeStatus.contracted)
                              ? `/app/employee/${row._id}`
                              : `/app/cvp/${row._id}`
                          }
                          size="small"
                        >
                          <Stack direction="row" spacing={1}>
                            {row.additionalData?.nationality ===
                            Nationalities.ethiopian ? (
                              <img
                                alt="ethioFlag"
                                height="20px"
                                width="30px"
                                src="/ethiopiaFlag.png"
                              />
                            ) : row.additionalData?.nationality ===
                              Nationalities.kenyan ? (
                              <img
                                alt="kenyaFlag"
                                height="20px"
                                width="30px"
                                src="/kenyaFlag.png"
                              />
                            ) : row.additionalData?.nationality ===
                              Nationalities.ugandan ? (
                              <img
                                alt="ugandaFlag"
                                height="20px"
                                width="30px"
                                src="/ugandaFlag.png"
                              />
                            ) : (
                              <img
                                alt="philippineFlag"
                                height="20px"
                                width="30px"
                                src="/philippineFlag.png"
                              />
                            )}
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: 700 }}
                              color="secondary"
                              textTransform="capitalize"
                            >
                              {te(row.additionalData?.nationality as string)}
                            </Typography>
                          </Stack>
                        </Button> */}
                      </StyledTableCell>
                      {/* <StyledTableCell
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        {row?.medicalCompleted ? <Check /> : <Close />}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      >
                        {row?.cocCompleted ? <Check /> : <Close />}
                      </StyledTableCell> */}

                      {user?.company?.type === ClientTypes.Moderator ? (
                        <>
                          <StyledTableCell
                            sx={{ color: "primary.main", fontWeight: 700 }}
                          >
                            {getDisplayName(row.owner)}
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{ color: "primary.main", fontWeight: 700 }}
                          >
                            {getDisplayName(row.partner)}
                          </StyledTableCell>
                        </>
                      ) : (
                        <StyledTableCell
                          sx={{ color: "primary.main", fontWeight: 700 }}
                        >
                          {user?.company &&
                          user.company.type === ClientTypes.ForeignAgent
                            ? getDisplayName(row.owner)
                            : getDisplayName(row.partner)}
                        </StyledTableCell>
                      )}

                      <StyledTableCell
                        sx={{ color: "secondary.main", fontWeight: 700 }}
                      >
                        {row.visa?.visaNumber}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: "secondary.main", fontWeight: 700 }}
                      >
                        {row.visa?.passportNumber && row.visa?.fullName
                          ? `${row.visa?.passportNumber} - ${row.visa?.fullName}`
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ color: "secondary.main", fontWeight: 700 }}
                      >
                        {te(row.status as string)}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Stack direction="row">
                          <Tooltip title={t("status")}>
                            <IconButton
                              color="secondary"
                              onClick={() => changeCandidateStatus(row)}
                              size="large"
                            >
                              <NextPlan />
                            </IconButton>
                          </Tooltip>
                          {isPrivilegedTransaction(
                            user?.roles as Role[],
                            "ViewEmployees"
                          ) && (
                            <Tooltip title={t("view") + " " + t("cv")}>
                              <IconButton
                                color="primary"
                                component={RouterLink}
                                to={`/app/cvp/${row._id}`}
                                size="large"
                              >
                                <RemoveRedEye />
                              </IconButton>
                            </Tooltip>
                          )}
                          {(row.status === EmployeeStatus.new ||
                            row.status === EmployeeStatus.selected ||
                            row.status === EmployeeStatus.contracted) &&
                            isPrivilegedTransaction(
                              user?.roles as Role[],
                              "EditEmployees"
                            ) && (
                              <Tooltip title={t("edit")}>
                                <IconButton
                                  color="primary"
                                  component={RouterLink}
                                  to={`/app/employee/${row._id}`}
                                  size="large"
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )}
                          {(row.status === EmployeeStatus.new ||
                            row.status === EmployeeStatus.selected ||
                            row.status === EmployeeStatus.contracted) &&
                            isPrivilegedTransaction(
                              user?.roles as Role[],
                              "DeleteEmployees"
                            ) && (
                              <Tooltip title={t("delete")}>
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    handleClickOpen(row._id as string)
                                  }
                                  size="large"
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <FilterSidebar listType="employees" />
          <br />
          {success && <Toast severity="success">{success.message}</Toast>}
          {error && <Toast severity="error">{error.message}</Toast>}
          {employees.length > 0 && (
            <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Typography
                alignSelf="center"
                justifyContent="center"
                variant="h6"
                color="white"
                sx={{
                  backgroundColor: "primary.main",
                  p: 1,
                  borderRadius: "4px",
                }}
              >
                {t("total")}: {total}
              </Typography>

              <Paging
                total={total}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </Stack>
          )}
        </>
      </Box>

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This data will be deleted permanently.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Button onClick={DeleteEmployee}>Delete</Button>
          </DialogActions>
        </Dialog>
      )}

      {openChangeStatus && (
        <CustomDialog
          title={t("Change") + " " + t("status")}
          isOpen={openChangeStatus}
          handleDialogClose={dialogCloseChangeStatus}
        >
          <ProcessStages
            currentEmployee={selectedEmployee}
            handleDialogClose={dialogCloseChangeStatus}
          />
        </CustomDialog>
      )}
    </>
  );
};
