import { Delete, Add, ExpandMore } from "@mui/icons-material";
import "dayjs/locale/ar-sa";
import "dayjs/locale/am";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";

import { StyledTableRow, StyledTableCell } from "../../../styles/tableStyles";
import {
  CountriesList,
  ProfessionalTypes,
  ExperienceYears,
  Employee,
  LanguageExperience,
  WorkExperience,
  CurrencyTypes,
  MaritalStatus,
  ReligionTypes,
} from "../types/processTypes";
import { useAppSelector } from "../../../app/hooks";
import { selectPreference } from "../../preferences/preferencesSlice";

interface ExperienceTabProps {
  formikData: FormikProps<Employee>;
}
export const Experience = ({ formikData }: ExperienceTabProps) => {
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  // const theme = useTheme();
  const { locale } = useAppSelector(selectPreference);

  const [country, setCountry] = useState(CountriesList.saudi_arabia);
  const [showAddExperience, setShowAddExperience] = useState(false);
  const [profession, setProfession] = useState(ProfessionalTypes.housemaid);
  const [years, setYears] = useState(ExperienceYears.two);
  const onChangeC = (value: ChangeEvent<HTMLInputElement>) => {
    setCountry(value.target.value as CountriesList);
  };
  const onChangeP = (value: ChangeEvent<HTMLInputElement>) => {
    setProfession(value.target.value as ProfessionalTypes);
  };
  const onChangeY = (value: ChangeEvent<HTMLInputElement>) => {
    setYears(value.target.value as ExperienceYears);
  };

  const addExperience = () => {
    if (formikData.values.workExperience)
      formikData.setFieldValue("workExperience", [
        ...(formikData.values.workExperience as [WorkExperience]),
        { country, profession, years },
      ]);
    else
      formikData.setFieldValue("workExperience", [
        { country, profession, years },
      ]);
    setShowAddExperience(false);
  };
  const addForeignExperience = () => {
    setShowAddExperience(true);
  };
  const removeExperience = (id: string) => {
    formikData.setFieldValue(
      "workExperience",
      formikData.values.workExperience?.filter((w) => w._id !== id)
    );
  };
  return (
    <Box>
      <Accordion expanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Foreign Experience
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="flex-start">
            {formikData.values.workExperience &&
              formikData.values.workExperience.length > 0 && (
                <TableContainer component={Paper} sx={{ mb: 1 }}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>{t("country")}</StyledTableCell>
                        <StyledTableCell>{t("profession")}</StyledTableCell>
                        <StyledTableCell>{t("forYears")}</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {formikData.values.workExperience &&
                        formikData.values.workExperience.map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {te(row.country as string)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {te(row.profession as string)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {te(row.years as string)}
                            </StyledTableCell>

                            <StyledTableCell>
                              <IconButton
                                color="secondary"
                                onClick={() =>
                                  removeExperience(row._id as string)
                                }
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </Grid>
          <Grid container spacing={1}>
            {!showAddExperience && (
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={addForeignExperience}
                  size="small"
                >
                  <Add /> Add Foreign Experience
                </Button>
              </Grid>
            )}
            {showAddExperience && (
              <>
                <Grid item md={3} xs={12}>
                  <TextField
                    id="experienceCountry"
                    select
                    defaultValue="Ethiopia"
                    label={t("country")}
                    onChange={onChangeC}
                    value={country}
                    fullWidth
                  >
                    {Object.keys(CountriesList).map((option) => (
                      <MenuItem key={option} value={option as string}>
                        {te(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} xs={12}>
                  <TextField
                    id="experienceProfession"
                    select
                    defaultValue="Housemaid"
                    label="Profession"
                    onChange={onChangeP}
                    value={profession}
                    fullWidth
                  >
                    {Object.keys(ProfessionalTypes).map((option) => (
                      <MenuItem key={option} value={option as string}>
                        {te(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} xs={12}>
                  <TextField
                    id="experienceYears"
                    select
                    defaultValue="Two"
                    label={t("forYears")}
                    onChange={onChangeY}
                    value={years}
                    fullWidth
                  >
                    {Object.keys(ExperienceYears).map((option) => (
                      <MenuItem key={option} value={option as string}>
                        {te(option.toLowerCase())}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={addExperience}
                    size="large"
                    sx={{ mt: 1 }}
                  >
                    <Add /> Add
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            More CV Data
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <DatePicker
                  label={t("applicationDate")}
                  views={["year", "month", "day"]}
                  minDate={new Date("1920-01-01")}
                  disableMaskedInput
                  inputFormat="DD-MMM-YYYY"
                  value={formikData.values.additionalData?.documentReceivedDate}
                  onChange={(value) => {
                    formikData.setFieldValue(
                      "additionalData.documentReceivedDate",
                      value,
                      true
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="applicationDate"
                      sx={{
                        mt: 1,
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="additionalData.appliedCountry"
                select
                label={te("applied") + " " + te("country")}
              >
                {Object.keys(CountriesList).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="additionalData.appliedProfession"
                select
                label={te("applied") + " " + te("profession")}
              >
                {Object.keys(ProfessionalTypes).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="additionalData.maritalStatus"
                select
                label={te("maritalStatus")}
              >
                {Object.keys(MaritalStatus).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="additionalData.religion"
                select
                label={te("religion")}
              >
                {Object.keys(ReligionTypes).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="education.arabicLanguage"
                select
                label={t("arabic")}
              >
                {Object.keys(LanguageExperience).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te((option as string).toLowerCase())}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="education.englishLanguage"
                select
                label={t("english")}
              >
                {Object.keys(LanguageExperience).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te((option as string).toLowerCase())}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="education.remarks"
                label="Other Skills"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="additionalData.numberOfChildren"
                label={t("numberOfChildren")}
                type="number"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormikTextField
                formikKey="additionalData.height"
                label={t("height") + "(" + t("meter") + ")"}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormikTextField
                formikKey="additionalData.weight"
                label={t("weight") + "(" + t("kg") + ")"}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Stack direction="row">
                <FormikTextField
                  formikKey="additionalData.salary"
                  label={t("salary")}
                  type="number"
                  sx={{ mr: 0.3, mt: 1 }}
                />
                <FormikTextField
                  formikKey="additionalData.currency"
                  select
                  label={te("currency")}
                >
                  {Object.keys(CurrencyTypes).map((option) => (
                    <MenuItem key={option} value={option as string}>
                      {te(option)}
                    </MenuItem>
                  ))}
                </FormikTextField>
              </Stack>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="address.telephone"
                label={t("employeeTelephone")}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="contactPerson.displayName"
                label="Contact Name"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="contactPerson.address.telephone"
                label={t("contactTelephone")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formikKey="additionalData.notes"
                label={t("remark")}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
