import { Helmet } from "react-helmet";
import Header from "./Header";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const { t } = useTranslation();
  const { t: ta } = useTranslation("auth");
  return (
    <>
      <Helmet>
        <title> {`${ta("home")} | ${t("platform")}`}</title>
      </Helmet>
      <Container maxWidth="lg" sx={{ height: "100vh", mt: 2 }}>
        <Header />
      </Container>
    </>
  );
};
export default LandingPage;
