import Grid from "@mui/material/Grid";
import { Box, Stack, Typography } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPassportShort } from "./components/basic/BasicPassport";
import { InterStand } from "./components/inter/InterStand";
import { InterPostApplied } from "./components/inter/InterPostApplied";
import { InterDetail } from "./components/inter/InterDetail";
import { InterPassportScan } from "./components/inter/InterPassportScan";
import { InterFullName } from "./components/inter/InterFullName";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { BasicSkillsOneLineHorzStar } from "./components/basic/BasicSkills";
import { BasicExperience } from "./components/basic/BasicExperience";
import { BasicLanguageStar } from "./components/basic/BasicLanguage";
import { BasicRemarks, BasicRemarksVer } from "./components/basic/BasicRemarks";
import { BasicFlagHorz } from "./components/basic/BasicFlag";
import { InterLocation } from "./components/inter/InterLocation";
import { BasicLocation } from "./components/basic/BasicLocation";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function InterTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "100%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="start"
          >
            <Grid item sm={6} xs={12}>
              {cvTemplate && currentEmployee && (
                <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                  {/* <BasicReferenceOnly
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    /> */}
                  <BasicFlagHorz
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <InterFullName
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <InterStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterDetail
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {cvTemplate && currentEmployee && (
                <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                  <BasicExperience
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    type="basic"
                  />
                  <BasicLanguageStar
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <InterPostApplied
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <BasicPassportShort
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <InterPassportScan
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item sm={10} xs={12}>
                <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                  <BasicSkillsOneLineHorzStar
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <BasicRemarks
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                </Stack>
              </Grid>
              <Grid item sm={2} xs={12}>
                <InterLocation
                  type="moreCvs"
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default InterTemplate;
