import Grid from "@mui/material/Grid";
import { Box, Stack, Typography } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicDetailEnglish } from "./components/basic/BasicDetail";
import { BasicExperienceHalool } from "./components/basic/BasicExperience";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { differenceInMonths, format } from "date-fns";
import { useTranslation } from "react-i18next";

// 704a66 header background
// dbdbdb even row color
interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function HaloolTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");

  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="flex-end"
            sx={{
              mb: 2,
              backgroundImage:
                "radial-gradient(circle, white 0%, #bbb8a7 100%)}",
              pt: 1,
            }}
          >
            {/* Short Photo */}
            <Grid item xs={2}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>
            <Grid item xs={7}>
              {cvTemplate && currentEmployee && (
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "40px",
                      textAlign: "center",
                      fontFamily: "sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    {currentEmployee.givenName + " " + currentEmployee.surName}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={3}>
              {cvTemplate && currentEmployee && (
                <img
                  style={{ margin: "auto", marginTop: "5px" }}
                  width="100%"
                  height="250px"
                  src="/haloolLogo.png"
                  alt="office logo"
                />
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography variant="h3">About Me</Typography>
              <Typography variant="h6">Hello!</Typography>
              <Typography variant="h6">
                I am <b>Domestic Helper</b> and I can do different work such as
                General Cleaning, Care of Kids, and Assist in Cooking.
              </Typography>
              {cvTemplate && currentEmployee && (
                <Grid container item xs={12} md={8} spacing={0}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Abroad Experience:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase", fontWeight: 800 }}
                    >
                      {currentEmployee.workExperience &&
                      currentEmployee.workExperience.length <= 0 ? (
                        <>{tt("firstTimer")}</>
                      ) : (
                        <>
                          {te(
                            (currentEmployee.workExperience &&
                              currentEmployee.workExperience[0]
                                .country) as string
                          ) +
                            "-" +
                            te(
                              (currentEmployee.workExperience &&
                                currentEmployee.workExperience[0]
                                  .profession) as string
                            ) +
                            "-" +
                            te(
                              (currentEmployee.workExperience &&
                                currentEmployee.workExperience[0]
                                  .years) as string
                            )}
                        </>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">Religion:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase", fontWeight: 800 }}
                    >
                      {currentEmployee.additionalData?.religion}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">Date fof Birth:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      {format(
                        new Date(currentEmployee.dateOfBirth as Date),
                        "dd-MMM-yyyy"
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">Age:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase", fontWeight: 800 }}
                    >
                      {Math.floor(
                        differenceInMonths(
                          new Date(),
                          new Date(currentEmployee.dateOfBirth as Date)
                        ) / 12
                      ).toLocaleString("en-US") + " Years Old"}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">Marital Status:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase", fontWeight: 800 }}
                    >
                      {currentEmployee.additionalData?.maritalStatus as string}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">Number of Children:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase", fontWeight: 800 }}
                    >
                      {currentEmployee.additionalData?.numberOfChildren?.toLocaleString(
                        "en-US"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Grid>

          <Grid item md={7} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetailEnglish
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showSalary={true}
                showPassportNumber={true}
                showIssueDate={true}
                showPlaceOfIssue={false}
                showExpiryDate={true}
                showLanguage={true}
                showNationality={false}
                showReligion={false}
                showDateOfBirth={false}
                showAge={false}
                showPlaceOfBirth={true}
                showMaritalStatus={false}
                showNumberOfChildren={false}
                showWeightHeight={true}
                showComplexion={false}
                showEducation={true}
                showLivingTown={true}
                showEmployeeTelephone={false}
                showContactName={true}
                showContactTelephone={true}
              />
            )}
          </Grid>
          <Grid item md={5} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              sx={{
                alignItems: "center",
              }}
            >
              <Stack direction="row" spacing={1}>
                {cvTemplate && currentEmployee && (
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            {cvTemplate && currentEmployee && (
              <BasicExperienceHalool
                currentEmployee={currentEmployee}
                template={cvTemplate}
                type="entrance"
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default HaloolTemplate;
