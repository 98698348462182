import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  Stack,
  TableHead,
  Avatar,
  Badge,
  Fab,
  Tooltip,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import Image from "mui-image";
import { Employee, Nationalities } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const InterStand = ({ currentEmployee, template }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginTop: template.spacing,
        borderWidth: template.borderWidth,
        borderColor: template.borderColor,
        borderStyle: template.borderStyle,
        boxShadow: template.bodyShadow,
        p: 1,
      }}
      spacing={1}
      alignItems="center"
    >
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("personalPhoto", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("personalPhoto", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
        </Table>
      </StyledTableContainer>

      {/* <Badge
        overlap="rectangular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={
          <Tooltip title={currentEmployee.additionalData?.nationality}>
            <Fab component="span" color="secondary">
              <Avatar
                alt="avatar"
                src={
                  currentEmployee.additionalData?.nationality ===
                  Nationalities.ethiopian
                    ? "/ethiopiaFlag.png"
                    : currentEmployee.additionalData?.nationality ===
                      Nationalities.kenyan
                    ? "/kenyaFlag.png"
                    : currentEmployee.additionalData?.nationality ===
                      Nationalities.ugandan
                    ? "/ugandaFlag.png"
                    : "/philippineFlag.png"
                }
                sx={{
                  height: 50,
                  width: 50,
                  // marginTop: -3,
                }}
              />
            </Fab>
          </Tooltip>
        }
        >
        </Badge> */}
      <Image
        src={
          currentEmployee && currentEmployee.standPhoto
            ? (currentEmployee.standPhoto as string)
            : "/blankLong.jpg"
        }
        alt={currentEmployee.passportNumber}
        width="80%"
        height="550px"
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          borderColor: template.borderColor,
          borderStyle: template.borderStyle,
          borderWidth: (template.borderWidth as number) * 2,
        }}
      />
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableBody>
            {/* <StyledTableRowTemplate template={template} key="7">
              <StyledTableCellLabelTemplate template={template}>
                {tt("weight", "en") + "/" + tt("height", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {(currentEmployee.additionalData?.weight === ""
                  ? "-"
                  : currentEmployee.additionalData?.weight+ tt("kg", "ar")) +
                  "/" +
                  (currentEmployee.additionalData?.height === ""
                    ? "-"
                    : currentEmployee.additionalData?.height+ tt("meter", "ar"))}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("weight", "ar") + "/" + tt("height", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate> */}
            <StyledTableRowTemplate template={template} key="7">
              <StyledTableCellLabelTemplate template={template}>
                {tt("weight", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {currentEmployee.additionalData?.weight === ""
                  ? "-"
                  : currentEmployee.additionalData?.weight +
                    tt("kg", template.cvLang)}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("weight", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="77">
              <StyledTableCellLabelTemplate template={template}>
                {tt("height", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {currentEmployee.additionalData?.height === ""
                  ? "-"
                  : currentEmployee.additionalData?.height +
                    tt("meter", template.cvLang)}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("height", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Stack>
  );
};
