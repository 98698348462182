import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";

import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo/graphql";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./i18n";
// import ReactGA from "react-ga4";

/*
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-Y3JNELK16R"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y3JNELK16R');
</script>
*/
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//Initialize GA4
// if ((process.env.REACT_APP_ENV as string) === "production")
//   ReactGA.initialize("G-Y3JNELK16R");

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
    >
      <Provider store={store}>
        <CssBaseline />
        <BrowserRouter>
          <ApolloProvider client={apolloClient}>
            <React.Suspense fallback="...">
              <App />
            </React.Suspense>
          </ApolloProvider>
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// const SendAnalytics = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); //SendAnalytics
