import { TableContainer, Paper, Table, TableBody } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee, Nationalities } from "../../../types/processTypes";
import Image from "mui-image";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  flagName?: string | "/saudiFlag.png";
}
export const BasicFlag = ({ currentEmployee, template, flagName }: Props) => {
  return (
    <>
      <Image
        src={flagName as string}
        alt={currentEmployee.passportNumber}
        width={template.flagWidth}
        height="50px"
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          marginTop: template.spacing,
          borderColor: template.borderColor,
          borderWidth: template.borderWidth,
          borderStyle: template.borderStyle,
        }}
      />
    </>
  );
};

export const BasicFlagHorz = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginBottom: 0,
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
        marginTop: template.spacing,
        boxShadow: template.bodyShadow,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "30%" }}
            >
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName={
                  currentEmployee.additionalData?.nationality ===
                  Nationalities.ethiopian
                    ? "/ethiopiaFlag.png"
                    : currentEmployee.additionalData?.nationality ===
                      Nationalities.kenyan
                    ? "/kenyaFlag.png"
                    : currentEmployee.additionalData?.nationality ===
                      Nationalities.ugandan
                    ? "/ugandaFlag.png"
                    : "/philippineFlag.png"
                }
              />
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("nationality", template.cvLang)}
            </StyledTableCellLabelTemplate>

            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "33%" }}
            >
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/saudiFlag.png"
              />
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "22%", textAlign: "right" }}
            >
              {tt("destination", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const BasicCountryHorz = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginBottom: 0,
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
        marginTop: template.spacing,
        boxShadow: template.bodyShadow,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellValueTemplate
              template={{ ...template, bodyHeight: 52 }}
              sx={{ width: "30%", textAlign: "center" }}
            >
              {tt(currentEmployee.additionalData?.nationality as string, "en")}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("country", "en")}
            </StyledTableCellLabelTemplate>

            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("country", "ar")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "30%", textAlign: "center" }}
            >
              {tt(currentEmployee.additionalData?.nationality as string, "ar")}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const BasicFlagVert = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginBottom: 0,
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate template={template}>
              {tt("country", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellValueTemplate template={template}>
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/ethiopiaFlag.png"
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellLabelTemplate template={template}>
              {tt("destination", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellValueTemplate template={template}>
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/saudiFlag.png"
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
