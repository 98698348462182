import { useEffect } from "react";
import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AuthSkeleton from "./AuthSkeleton";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAuth } from "./authSlice";

import AccountHeader from "../../components/account/AccountHeader";
import ChangePassword from "../../components/account/ChangePassword";
import Container from "@mui/material/Container";
import { CompanyDetail } from "../../components/account/CompanyDetail";
import { useTranslation } from "react-i18next";

export const Profile = () => {
  const { loading } = useAppSelector(selectAuth);
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title> {`${t("myProfile")} | ${t("platform")}`}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ mt: 2 }} />
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          {loading === "pending" ? (
            <AuthSkeleton />
          ) : (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={5} xs={12}>
                  <AccountHeader />
                </Grid>
                <Grid item lg={8} md={7} xs={12}>
                  {/* <AccountDetail /> */}
                  <CompanyDetail />
                </Grid>
              </Grid>
              {/* <Box sx={{ pt: 3 }}></Box> */}
              <Box sx={{ pt: 3 }}>
                <ChangePassword />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};
