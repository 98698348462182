export enum RoleTypes {
  ViewCompanies = "ViewCompanies",
  AddCompanies = "AddCompanies",
  CvMaker = "CvMaker",

  ViewUsers = "ViewUsers",
  ManageUsers = "ManageUsers",

  ManageUserRoles = "ManageUserRoles",

  CompanyManagement = "CompanyManagement",
  PartnersManagement = "PartnersManagement",

  ViewDashboard = "ViewDashboard",

  ViewCandidates = "ViewCandidates",
  SelectCandidates = "SelectCandidates",
  DeselectCandidates = "DeselectCandidates",
  ViewCv = "ViewCv",

  ViewEmployees = "ViewEmployees",
  AddEmployees = "AddEmployees",
  EditEmployees = "EditEmployees",
  DeleteEmployees = "DeleteEmployees",
  ChangeEmployeeStatus = "ChangeEmployeeStatus",

  OpenComplains = "OpenComplains",
  EditComplains = "EditComplains",
  ClosComplains = "ClosComplains",
  ReOpenComplains = "ReOpenComplains",
  ConfirmComplains = "ConfirmComplains",

  CompanyProfile = "CompanyProfile",
  ChangePassword = "ChangePassword",

  ViewNotifications = "ViewNotifications",
  MarkAsReadNotifications = "MarkAsReadNotifications",
  DeleteNotifications = "DeleteNotifications",

  ViewReports = "ViewReports",
}
