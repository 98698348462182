import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { addDays, differenceInMonths } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createEmployee,
  fetchEmployees,
  resetSuccess,
  selectProcesses,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import { selectPreference, toggleThis } from "../preferences/preferencesSlice";
import {
  Stack,
  Button,
  Typography,
  Skeleton,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useTheme,
  FormControlLabel,
  Switch,
  Tooltip,
  Badge,
  Fab,
  Avatar,
} from "@mui/material";
import { Assignment, FilterList, Share, ThumbUp } from "@mui/icons-material";
import Image from "mui-image";

import Paging from "../../components/Layout/Paging";
import {
  CompletionStatus,
  Employee,
  EmployeeStatus,
  Nationalities,
  NotificationMessageTypes,
} from "./types/processTypes";
import {
  StyledTableRow,
  StyledTableCellLabel,
  StyledTableCellValue,
} from "../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../components/modals/CustomDialog";
import ShareCvs from "./components/ShareCvs";
import { t } from "i18next";
import { Company } from "../setups/types/setupTypes";
import { VisaEntry } from "./VisaEntry";
import { isPrivilegedTransaction } from "../../utils/authUtils";
import { ClientTypes, Role } from "../auth/types/authType";
import { selectAuth } from "../auth/authSlice";
import Toast from "../../components/Layout/Toast";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import Search from "../../components/search/Search";
import FilterSidebar from "../../components/filter/FilterSidebar";
interface Props {
  isPremium: boolean;
}

export const Candidates = ({ isPremium }: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { user } = useAppSelector(selectAuth);
  const [company, setCompany] = useState<Company>();

  const {
    employeesWithCount: { employees, totalCount },
    // notificationsWithCount: { notifications, totalCount: notifyCount },
    loading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  const {
    searchText,
    nationality,
    maritalStatus,
    religion,
    profession,
    arabic,
    english,
    ageStart,
    ageEnd,
    experienceYears,
    agencyId,
    agentId,
    cocCompleted: coc,
    medicalCompleted: medical,
    biometricsCompleted: biometrics,
  } = useAppSelector(selectPreference);

  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [sharedEmployeeIds, setSharedEmployeeIds] = useState<string[]>([]);
  const [openVisa, setOpenVisa] = useState(false);

  const [visaSelectedEmployee, setVisaSelectedEmployee] = useState<Employee>();
  const [selectCandidates, setSelectCandidates] = useState<boolean[]>([false]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  const viewCvHandler = (id: string) => {
    //dispatch(setSelectedEmployee(employees.find((e) => e._id === id)));
    if (isPremium) navigate(`/app/cvp/${id}`);
    else navigate(`/app/cvp/${id}`);
  };
  const dialogClose = () => {
    dispatch(resetSuccess());
    setOpen(false);
  };
  const dialogCloseVisa = () => {
    dispatch(resetSuccess());
    setOpenVisa(false);
  };

  useEffect(() => {
    if (user && user.company) {
      setCompany(user.company);
    }
  }, [user]);
  // useEffect(() => {
  //   if (success && selectedShortLink && selectedShortLink.link) {
  //     setOpen(true);
  //     setShortLink(selectedShortLink);
  //   }
  // }, [success, selectedShortLink]);

  useEffect(() => {
    //dispatch(changePageTitle("Candidates List"));

    const skipRows = currentPage * rowsPerPage;

    //if (!employees || employees.length === 0 || searchText) {

    dispatch(
      fetchEmployees({
        skip: skipRows,
        take: rowsPerPage,
        status: EmployeeStatus.new,
        searchText,
        nationality,
        maritalStatus,
        religion,
        profession,
        arabic,
        english,
        ageStart,
        ageEnd,
        experienceYears,
        agencyId,
        agentId,
        cocCompleted:
          coc && coc === CompletionStatus.completed
            ? true
            : coc === CompletionStatus.not_completed
            ? false
            : undefined,
        medicalCompleted:
          medical && medical === CompletionStatus.completed
            ? true
            : medical === CompletionStatus.not_completed
            ? false
            : undefined,
        biometricsCompleted:
          biometrics && biometrics === CompletionStatus.completed
            ? true
            : biometrics === CompletionStatus.not_completed
            ? false
            : undefined,
      })
    );

    //}
  }, [
    dispatch,
    searchText,
    nationality,
    maritalStatus,
    religion,
    profession,
    arabic,
    english,
    ageStart,
    ageEnd,
    experienceYears,
    currentPage,
    rowsPerPage,
    agencyId,
    agentId,
    coc,
    medical,
    biometrics,
  ]); //

  // useEffect(() => {
  //   setEmployees(allEmployees.filter((e) => e.visa && e.visa.visaNumber));
  // }, [allEmployees]);

  // useEffect(() => {

  //   dispatch(fetchShortLinks(""));
  // }, [dispatch]);

  useEffect(() => {
    setTotal(totalCount as number);
    const checkBoxes = [...Array(totalCount)].map((e, i) => {
      return false;
    });
    setSelectCandidates(checkBoxes);
    //console.log(checkBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const shareList = () => {
    const employeeIds: string[] = [];
    for (let i = 0; i < totalCount; i++) {
      if (selectCandidates[i] === true)
        employeeIds.push(employees[i]._id as string);
    }
    const emp = employees[0];
    if (emp) {
      setSharedEmployeeIds(employeeIds);
      //dispatch(generateLink({ employeeIds }));
      setOpen(true);
    }
    //console.log(employees[0]);
  };
  const shareSingle = (employeeId: string) => {
    setSharedEmployeeIds([employeeId]);
    // const emp = employees.find((e) => e._id === employeeId);
    // if (emp) {

    //   dispatch(generateLink({ employeeIds: [employeeId] }));
    // }
    setOpen(true);
  };
  const selectCandidate = (employee: Employee) => {
    setVisaSelectedEmployee(employee);
    setOpenVisa(true);
  };

  const justSelect = (employee: Employee) => {
    let values = employee;
    const currentDate = new Date();
    values = {
      ...values,
      status: EmployeeStatus.selected,
      notificationMessage: NotificationMessageTypes.selected as string,
      partner: company,
      selectionDueDate: addDays(currentDate, 3),
    };

    dispatch(createEmployee(values));
  };
  const handleDrawerOpen = () => {
    dispatch(toggleThis({ type: "Filter", newValue: true }));
  };
  // const openShareDialogue = (linkId: string) => {
  //   setOpen(true);
  //   setShortLink(sharedLinks.find((s) => s._id === linkId) as ShortLink);
  // };
  const changeSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAll = event.target.checked;
    setSelectAll(selectedAll);
    const checkBoxes = [...Array(totalCount)].map((e, i) => {
      return selectedAll;
    });
    setSelectCandidates(checkBoxes);
    //console.log(selectCandidates);
  };

  const changeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const tempSelected = [...selectCandidates];
    tempSelected[index] = event.target.checked;
    setSelectCandidates(tempSelected);
  };

  return (
    <>
      <Helmet>
        <title> {`${t("candidates")} | ${t("platform")}`}</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          {employees.length > 0 && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                justifyContent="space-between"
                // sx={{
                //   m: 0,
                //   p: 1,
                //   backgroundColor: theme.palette.primary.main,
                //   color: theme.palette.common.white,
                //   height: 60,
                //   borderRadius: "10px",
                // }}
              >
                {user &&
                  user.company &&
                  user.company.type === ClientTypes.Moderator && (
                    <CompanyFilter
                      companyType={ClientTypes.ForeignAgent}
                      setCompany={setCompany}
                      includeAll={false}
                    />
                  )}

                <Search initialValue={searchText} />
                <Tooltip title={t("filter")}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleDrawerOpen}
                    size="large"
                    sx={{
                      marginRight: theme.direction === "ltr" ? "0px" : "-12px",
                      marginLeft: theme.direction === "rtl" ? "0px" : "-12px",
                      // ...(isFilterOpen && { display: "none" }),
                    }}
                  >
                    <FilterList />
                  </Button>
                </Tooltip>
              </Stack>
              {/* <Accordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="div">
                    <FilterList />
                    Filter List
                  </Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <CompanyFilter
                    companyType={ClientTypes.ForeignAgent}
                    setCompany={setCompany}
                    includeAll={true}
                  />
                </AccordionDetails>
              </Accordion> */}
            </>
          )}
          <>
            <Stack justifyContent="center" sx={{ mt: 1 }} spacing={1}>
              {loading === "pending" ? (
                <Grid
                  container
                  justifyContent="center"
                  sx={{ mt: 1 }}
                  spacing={1}
                >
                  {[...Array(10)].map((e, i) => (
                    <Grid
                      container
                      item
                      lg={3}
                      md={4}
                      sm={6}
                      xs={12}
                      direction="column"
                      display="flex"
                      alignItems="center"
                      key={i}
                    >
                      <Skeleton
                        variant="rounded"
                        width={180}
                        height={180}
                        style={{ margin: "5px" }}
                      />
                      <Skeleton variant="text" height={25} width={180} />
                      <Skeleton variant="text" height={25} width={180} />
                    </Grid>
                  ))}
                </Grid>
              ) : employees.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src="/No_Data.svg"
                    alt="No Data"
                    width={250}
                    height={120}
                    shift="top"
                    distance="2rem"
                    shiftDuration={320}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="h5">
                    {t("no_candidates_found")}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Grid container justifyContent="center">
                    {employees.map((row, index) => (
                      <Grid
                        item
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                        container
                        direction="column"
                        display="flex"
                        alignItems="center"
                        key={index}
                        sx={{
                          border: `1px solid ${theme.palette.primary.main}`,
                          borderRadius: "5px",
                          boxShadow: "5px 5px rgba(0,0,0,0.14)",
                          mx: 1,
                          px: 1,
                        }}
                      >
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <Tooltip title={row.additionalData?.nationality}>
                              <Fab component="span" color="secondary">
                                <Avatar
                                  alt="avatar"
                                  src={
                                    row.additionalData?.nationality ===
                                    Nationalities.ethiopian
                                      ? "/ethiopiaFlag.png"
                                      : row.additionalData?.nationality ===
                                        Nationalities.kenyan
                                      ? "/kenyaFlag.png"
                                      : row.additionalData?.nationality ===
                                        Nationalities.ugandan
                                      ? "/ugandaFlag.png"
                                      : "/philippineFlag.png"
                                  }
                                  sx={{
                                    height: 50,
                                    width: 50,
                                    // marginTop: -3,
                                  }}
                                />
                              </Fab>
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={() => viewCvHandler(row._id as string)}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Image
                                src={
                                  row && row.facePhoto
                                    ? (row.facePhoto as string)
                                    : "/blankShort.jpg"
                                }
                                width={200}
                                height={200}
                                shift="top"
                                distance="2rem"
                                shiftDuration={320}
                                style={{ borderRadius: "50%" }}
                              />
                            </Stack>
                          </Button>
                        </Badge>

                        <TableContainer
                          component={Paper}
                          sx={{
                            marginTop: 1,
                            borderRadius: "10px",
                            width: "100%",
                          }}
                        >
                          <Table size="small" aria-label="simple table">
                            <TableHead>
                              <StyledTableRow key="3">
                                <StyledTableCellLabel
                                  colSpan={4}
                                  sx={{ textAlign: "center" }}
                                >
                                  {row.givenName +
                                    " (" +
                                    row.passportNumber +
                                    ")"}
                                  <br />
                                  {row.owner?.displayName}
                                </StyledTableCellLabel>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow key="1">
                                <StyledTableCellLabel>
                                  {tt("age")}
                                </StyledTableCellLabel>
                                <StyledTableCellValue>
                                  {Math.floor(
                                    differenceInMonths(
                                      new Date(),
                                      new Date(row.dateOfBirth as Date)
                                    ) / 12
                                  ).toLocaleString(
                                    theme.direction === "rtl"
                                      ? "ar-SA"
                                      : "en-US"
                                  )}
                                </StyledTableCellValue>

                                <StyledTableCellLabel>
                                  {tt("salary")}
                                </StyledTableCellLabel>
                                <StyledTableCellValue>
                                  {row.additionalData?.salary?.toLocaleString(
                                    theme.direction === "rtl"
                                      ? "ar-SA"
                                      : "en-US"
                                  ) +
                                    "" +
                                    te(row.additionalData?.currency as string)}
                                </StyledTableCellValue>
                              </StyledTableRow>
                              <StyledTableRow key="0">
                                <StyledTableCellLabel>
                                  {te("arabic")}
                                </StyledTableCellLabel>
                                <StyledTableCellValue>
                                  {te(row.education?.arabicLanguage as string)}
                                </StyledTableCellValue>

                                <StyledTableCellLabel>
                                  {te("english")}
                                </StyledTableCellLabel>
                                <StyledTableCellValue>
                                  {te(row.education?.englishLanguage as string)}
                                </StyledTableCellValue>
                              </StyledTableRow>
                              <StyledTableRow key="5">
                                <StyledTableCellLabel>
                                  {tt("workExperience")}
                                </StyledTableCellLabel>
                                <StyledTableCellValue colSpan={3}>
                                  {row.workExperience &&
                                  row.workExperience.length <= 0 ? (
                                    <>{tt("firstTimer")}</>
                                  ) : (
                                    <>
                                      {te(
                                        (row.workExperience &&
                                          row.workExperience[0]
                                            .country) as string
                                      ) +
                                        "-" +
                                        te(
                                          (row.workExperience &&
                                            row.workExperience[0]
                                              .profession) as string
                                        ) +
                                        "-" +
                                        te(
                                          (row.workExperience &&
                                            row.workExperience[0]
                                              .years) as string
                                        )}
                                    </>
                                  )}
                                </StyledTableCellValue>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                        >
                          <Stack direction="row" spacing={1} sx={{ m: 1 }}>
                            {row.status === EmployeeStatus.new &&
                              isPrivilegedTransaction(
                                user?.roles as Role[],
                                "SelectCandidates"
                              ) && (
                                <>
                                  <Tooltip title={t("only_select")}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => justSelect(row)}
                                      size="small"
                                    >
                                      <ThumbUp />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title={t("addAgreement")}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => selectCandidate(row)}
                                      size="small"
                                    >
                                      <Assignment />
                                    </Button>
                                  </Tooltip>
                                </>
                              )}
                            {/* {row.status !== EmployeeStatus.new &&
                            isPrivilegedTransaction(
                              user?.roles as Role[],
                              "DeselectCandidates"
                            ) && (
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() =>
                                  deSelectCandidate(row._id as string)
                                }
                                size="small"
                              >
                                <ThumbDown />
                              </Button>
                            )} */}
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => shareSingle(row._id as string)}
                              size="small"
                            >
                              <Share />
                            </Button>
                          </Stack>
                          <Switch
                            checked={
                              selectCandidates[index]
                                ? selectCandidates[index]
                                : false
                            }
                            onChange={(e) => changeCheckBox(e, index)}
                            inputProps={{ "aria-label": "controlled" }}
                            name={index.toString()}
                            color="secondary"
                          />
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                  <FilterSidebar listType="candidates" />
                </>
              )}
            </Stack>
            {employees.length > 0 && (
              <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  justifyItems="center"
                  sx={{
                    "& > :not(style)": {
                      m: 0,
                      ml: 1,
                    },
                  }}
                >
                  <FormControlLabel
                    style={{ marginBottom: "0" }}
                    control={
                      <Switch
                        checked={selectAll}
                        onChange={(e) => changeSelectAll(e)}
                        inputProps={{ "aria-label": "controlled" }}
                        name="selectAll"
                        color="secondary"
                      />
                    }
                    label="Select All"
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={shareList}
                    size="large"
                    sx={{ mt: 1 }}
                    disabled={!selectCandidates.some((c) => c === true)}
                  >
                    <Share />
                  </Button>
                </Stack>
                <Typography
                  alignSelf="center"
                  justifyContent="center"
                  variant="h6"
                  color="white"
                  sx={{
                    backgroundColor: "primary.main",
                    p: 1,
                    borderRadius: "4px",
                  }}
                >
                  {t("total")}: {total}
                </Typography>

                <Paging
                  total={total}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                />
              </Stack>
            )}
          </>

          {/*{employees.length > 0 && (
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  <Share />
                  Shared Links
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Stack>
                  {sharedLinks && sharedLinks.length > 1 && (
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>{t("sno")}</StyledTableCell>
                            <StyledTableCell>{t("link")}</StyledTableCell>
                            <StyledTableCell>{t("employees")}</StyledTableCell>

                            <StyledTableCell>{t("actions")}</StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {sharedLinks.map((row, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell>{row.link}</StyledTableCell>
                              <StyledTableCell>
                                {row.employees
                                  ?.map((e) => e.givenName)
                                  .join(", ")}
                              </StyledTableCell>

                              <StyledTableCell>
                                <IconButton
                                  color="secondary"
                                  onClick={() =>
                                    openShareDialogue(row._id as string)
                                  }
                                  size="large"
                                >
                                  <Share />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion> 
          )} */}

          {success && <Toast severity="success">{success.message}</Toast>}
          {error && <Toast severity="error">{error.message}</Toast>}
          {open && (
            <CustomDialog
              title="Share Link"
              isOpen={open}
              handleDialogClose={dialogClose}
            >
              <ShareCvs
                employeeIds={sharedEmployeeIds}
                handleDialogClose={dialogCloseVisa}
              />
            </CustomDialog>
          )}
          {openVisa && (
            <CustomDialog
              title="Select Candidate"
              isOpen={openVisa}
              handleDialogClose={dialogCloseVisa}
            >
              <VisaEntry
                currentEmployee={visaSelectedEmployee}
                handleDialogClose={dialogCloseVisa}
              />
            </CustomDialog>
          )}
        </>
      </Box>
    </>
  );
};
