import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Stack,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { selectAuth } from "../../auth/authSlice";

import Toast from "../../../components/Layout/Toast";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Share } from "@mui/icons-material";
import { generateLink, selectProcesses, shareByEmail } from "../processSlices";
import { ClientTypes } from "../../auth/types/authType";
import { CompanyFilter } from "../../../components/filter/CompanyFilter";
import { Company } from "../../setups/types/setupTypes";

interface LinkProp {
  employeeIds: string[];
  handleDialogClose: Function;
}
const ShareCvs = ({ employeeIds, handleDialogClose }: LinkProp) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const [cvHeader, setCompany] = useState<Company>();

  const [email, setEmail] = useState("");
  const [employeesList, setEmployeesList] = useState("");
  const { t } = useTranslation();
  const {
    error: shareFailed,
    success: shareSuccess,
    selectedShortLink,
    loading,
  } = useAppSelector(selectProcesses);

  useEffect(() => {
    if (
      user &&
      user.company &&
      user.company.type === ClientTypes.ForeignAgent
    ) {
      setCompany(user.company);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (employeeIds && employeeIds.length > 0 && cvHeader && cvHeader._id)
      dispatch(generateLink({ employeeIds, headerId: cvHeader?._id }));
  }, [dispatch, cvHeader]);

  useEffect(() => {
    if (shareSuccess) {
      handleDialogClose();
    }
  }, [shareSuccess]);

  const isEmailValid =
    email.length === 0 ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const shareLinkByEmail = () => {
    //shareSuccess &&
    if (selectedShortLink && selectedShortLink.link) {
      dispatch(shareByEmail({ link: selectedShortLink.link, email }));
    }
  };
  const shareLinkByWhatsApp = () => {
    if (selectedShortLink && selectedShortLink.link) {
      handleDialogClose();

      window.open(
        `https://wa.me/?text=https://cv.enjazplus.com/c/${selectedShortLink.link}?lng=ar`,
        "_blank"
      );
    }
  };
  useEffect(() => {
    if (
      selectedShortLink &&
      selectedShortLink.link &&
      selectedShortLink.employees
    )
      setEmployeesList(
        selectedShortLink.employees
          ?.map((e) => e.givenName)
          .join(", ") as string
      );
  }, [selectedShortLink]);

  return (
    <Card>
      {/* <CardHeader subheader={t("share")} title={t("shareLinks")} />
      <Divider /> */}
      {loading === "pending" && <LinearProgress color="secondary" />}

      <CardContent>
        {user &&
          user.company &&
          user.company.type !== ClientTypes.ForeignAgent && (
            <CompanyFilter
              companyType={ClientTypes.ForeignAgent}
              setCompany={setCompany}
              includeAll={false}
            />
          )}
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="link"
          value={selectedShortLink.link}
          label={t("link")}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="employees"
          value={employeesList}
          label={t("employees")}
          disabled
        />
        <TextField
          fullWidth
          sx={{ mt: 1 }}
          name="email"
          label={t("email")}
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          error={!isEmailValid as boolean}
          helperText={!isEmailValid ? "Email is not correct" : ""}
        />
        <Box m={1}>
          {shareFailed && <Toast severity="error">{shareFailed.message}</Toast>}
          {shareSuccess && (
            <Toast severity="success">{shareSuccess.message}</Toast>
          )}
        </Box>
      </CardContent>

      <Divider />
      {selectedShortLink &&
        selectedShortLink.link &&
        selectedShortLink.link.length > 0 && (
          <Stack
            direction={isSmallScreen ? "column" : "row"}
            spacing={1}
            sx={{
              justifyContent: "space-between",
              p: 2,
              margin: "auto",
              marginBottom: theme.spacing(2),
            }}
          >
            <Button
              onClick={shareLinkByEmail}
              color="secondary"
              variant="contained"
              disabled={!isEmailValid || email.length === 0}
            >
              <Share /> {t("shareByEmail")}
            </Button>
            <Button
              onClick={shareLinkByWhatsApp}
              color="secondary"
              variant="contained"
              sx={{
                padding: "8px",
                color: "white",
                backgroundColor: "#075E54",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                alt="Chat on WhatsApp"
                src="/Digital_Glyph_White.svg"
                width="50px"
                height="30px"
              />
              {t("shareByWhatsApp")}
            </Button>
            {/* <StyledLink
              aria-label="Chat on WhatsApp"
              href={`https://wa.me/?text=https://cv.enjazplus.com/c/${selectedShortLink.link}?lng=ar`}
              target={"_blank"}
              sx={{
                padding: "8px",
                color: "white",
                backgroundColor: "#075E54",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <img
                alt="Chat on WhatsApp"
                src="/Digital_Glyph_White.svg"
                width="50px"
                height="30px"
              />
              {t("shareByWhatsApp")}
            </StyledLink>{" "} */}
          </Stack>
        )}
    </Card>
  );
};

export default ShareCvs;
