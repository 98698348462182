import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { Assignment, ExpandMore } from "@mui/icons-material";
import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createEmployee,
  resetSelectedVisa,
  selectProcesses,
  setSelectedEmployee,
} from "./processSlices";

import {
  Employee,
  EmployeeStatus,
  NotificationMessageTypes,
} from "./types/processTypes";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { createVisaSchema } from "./validation";
import { useTranslation } from "react-i18next";
import { Company } from "../setups/types/setupTypes";
import { selectAuth } from "../auth/authSlice";
import { ClientTypes } from "../auth/types/authType";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
interface CandidateId {
  currentEmployee?: Employee;

  handleDialogClose: Function;
}
export const VisaEntry = ({
  currentEmployee,
  handleDialogClose,
}: CandidateId) => {
  const { t } = useTranslation();

  const [company, setCompany] = useState<Company>();

  const {
    selectedEmployee,
    error: visaFailed,
    success: visaAdded,
  } = useAppSelector(selectProcesses);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  useEffect(() => {
    if (
      currentEmployee &&
      currentEmployee._id &&
      currentEmployee._id.length > 10
    ) {
      //dispatch(getEmployee(id));
      dispatch(setSelectedEmployee(currentEmployee));
    } else {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentEmployee]);

  useEffect(() => {
    if (visaAdded) {
      handleDialogClose();
    }
  }, [visaAdded]);

  function resetFields() {
    dispatch(resetSelectedVisa());
  }
  // useEffect(() => {
  //   if (selectedEmployee && selectedEmployee.owner) {
  //     setCompany(selectedEmployee.owner);
  //   }
  // }, [selectedEmployee]);
  useEffect(() => {
    if (user && user.company) {
      setCompany(user.company);
    }
  }, [user]);

  return (
    <>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Stack spacing={1} sx={{ px: 2, pt: 2 }}>
          {user &&
            user.company &&
            user.company.type === ClientTypes.Moderator && (
              <>
                <CompanyFilter
                  companyType={ClientTypes.ForeignAgent}
                  setCompany={setCompany}
                  includeAll={false}
                />
              </>
            )}

          <Formik
            enableReinitialize={true}
            initialValues={selectedEmployee as Employee}
            validationSchema={createVisaSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);

              values = {
                ...values,
                status: EmployeeStatus.contracted,
                partner: company,
                notificationMessage:
                  NotificationMessageTypes.contracted as string,

                selectionDueDate: new Date(),
              };
              dispatch(createEmployee(values));
            }}
          >
            {(props: FormikProps<Employee>) => (
              <Form>
                <Accordion expanded>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6" component="div">
                      {t("agreement") + " " + t("detail")}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormikTextField
                          formikKey="givenName"
                          label={t("candidate") + " " + t("fullName")}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          formikKey="visa.visaNumber"
                          label={t("visa_number")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          formikKey="visa.passportNumber"
                          label={t("id_number")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          formikKey="visa.fullName"
                          label={t("full_name")}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormikTextField
                          formikKey="visa.sponsorBirthDate"
                          label={t("dateOfBirth")}
                        />
                      </Grid> */}
                    </Grid>
                    <Divider variant="middle" sx={{ m: 2 }} />

                    <Button
                      type="submit"
                      color="secondary"
                      fullWidth
                      variant="contained"
                      disabled={!props.isValid}
                    >
                      <Assignment />
                      {t("addAgreement")}
                    </Button>
                  </AccordionDetails>
                </Accordion>

                <br />
                {visaAdded && (
                  <Toast severity="success">{visaAdded.message}</Toast>
                )}
              </Form>
            )}
          </Formik>
          {visaFailed && <Toast severity="error">{visaFailed.message}</Toast>}
        </Stack>
      </Box>
    </>
  );
};
