import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import Image from "mui-image";
import { Stack } from "@mui/material";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicPassportScan = ({ currentEmployee, template }: Props) => {
  return (
    <Stack alignItems="center">
      <Image
        src={
          currentEmployee.passportScan
            ? (currentEmployee.passportScan as string)
            : "/EthioPassport.png"
        }
        alt={currentEmployee.passportNumber}
        width={template.passportWidth}
        height={template.passportHeight}
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          borderColor: template.borderColor,
          borderWidth: (template.borderWidth as number) * 2,
          borderStyle: template.borderStyle,
          boxShadow: template.passportShadow,
        }}
      />
    </Stack>
  );
};
