import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import SupportChat from "./SupportChat";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../../features/auth/authSlice";
import { getCompany, selectSetups } from "../../features/setups/setupSlices";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t: tt } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const { selectedCompany } = useAppSelector(selectSetups);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [timeZone, setTimeZone] = useState(-3);
  useEffect(() => {
    const dt = new Date();
    let diffTZ = dt.getTimezoneOffset() / 60;
    setTimeZone(diffTZ);
    //Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user && user.company) {
      dispatch(getCompany(user.company._id as string));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (selectedCompany) {
      if (
        selectedCompany.enjazPlusLicense &&
        selectedCompany.enjazPlusLicense.length > 0
      )
        setSubscriptionMessage(selectedCompany.enjazPlusLicense);
      else setSubscriptionMessage("");
    }
  }, [dispatch, selectedCompany]);

  return (
    <footer>
      {subscriptionMessage ? (
        <>
          <Alert
            severity="warning"
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              "& .MuiAlert-message": {
                width: "100%",
                padding: 0,
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                {/* <Typography
                  fontWeight="lg"
                  fontSize="md"
                  mt={0.25}
                  sx={{ textTransform: "uppercase" }}
                >
                  Activation Required
                </Typography> */}
                <Typography
                  fontSize="sm"
                  fontFamily="Noto Sans Ethiopic"
                  fontWeight="extrabold"
                  color="error"
                  sx={{ opacity: 0.9 }}
                >
                  {subscriptionMessage === "expired"
                    ? tt("expiredMessage")
                    : subscriptionMessage === "quota"
                    ? tt("fulledQuota")
                    : subscriptionMessage.includes("/")
                    ? tt("fulledQuotaPrefix") +
                      subscriptionMessage +
                      tt("fulledQuotaSuffix")
                    : tt("expiringMessagePrefix") +
                      subscriptionMessage +
                      tt("expiringMessageSuffix")}
                </Typography>
              </Stack>
              {/* <Button
                color="secondary"
                variant="contained"
                onClick={handleOpen}
                size="large"
                sx={{
                  mt: 1,
                  fontFamily: "Noto Sans Ethiopic",
                  fontWeight: "extrabold",
                }}
              >
                <Send /> ክፍያ ለመፈጸም
              </Button> */}
            </Stack>
          </Alert>
        </>
      ) : timeZone !== -4 && timeZone !== -3 ? (
        <Alert
          severity="info"
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            "& .MuiAlert-message": {
              width: "100%",
              padding: 0,
            },
          }}
        >
          <Typography
            fontSize="md"
            fontFamily="Noto Sans Ethiopic"
            fontWeight="extrabold"
            sx={{ opacity: 0.9 }}
            color="error"
          >
            {`Please correct computer CLOCK and TIMEZONE(GMT+3/GMT+4)`}
          </Typography>
        </Alert>
      ) : (
        <>
          {/* <Alert
            severity="info"
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              "& .MuiAlert-message": {
                width: "100%",
                padding: 0,
              },
            }}
          >
            <Typography
              fontSize="md"
              fontFamily="Noto Sans Ethiopic"
              fontWeight="extrabold"
              sx={{ opacity: 0.9 }}
            >
              {`Logout and Login and (Ctrl+F5) to get the NEW UPDATES`}
            </Typography>
          </Alert> */}
        </>
      )}
      {/* <SupportChat /> */}
      {/*<Box
        <Typography
          color="red"
          variant="h5"
          sx={{ textAlign: "center", textTransform: "uppercase" }}
        ></Typography>
        mt={1}
        px={2}
        width={"100%"}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <StyledLink
          aria-label="Chat on WhatsApp"
          href="https://wa.me/251911850908"
          target={"_blank"}
        >
          {" "}
          <img
            alt="Chat on WhatsApp"
            src="/Digital_Glyph_Dark_Green.svg"
            width="120px"
            height="36px"
          />
        </StyledLink>{" "}
         <StyledLink
          href={"https://www.facebook.com/pinnasofts"}
          target={"_blank"}
        >
          <i className="fa fa-whatsapp"></i>
        </StyledLink>
        <StyledLink href={"https://twitter.com/pinnasofts"} target={"_blank"}>
          <i className="fa fa-twitter"></i>
        </StyledLink>
        <StyledLink href={"https://github.com/pinnasofts"} target={"_blank"}>
          <i className="fa fa-github"></i>
        </StyledLink>{" "} 
      </Box>*/}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: 20, color: "green" }}
        >
          {"Activate Your Account"}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" color="secondary">
            To Activate Your Account Deposit To:
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            sx={{ mt: 2, fontWeight: "bold" }}
          >
            CBE:1000304951285 - Ibrahim Yassin Hamid
          </Typography>
          <Typography color="secondary">OR</Typography>
          <Typography
            variant="h6"
            color="primary"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            BOA:102814118 - Ibrahim Yassin Hamid
          </Typography>
          <Typography variant="body1" color="secondary" sx={{ mb: 1 }}>
            P.S. After Making The Payment, Send The Screen Shot By
            WhatsApp/Telegram (0911850908) & Then Your Account Will Be Activated
            Shortly
          </Typography>
          <Typography variant="body2" color="secondary">
            Thanks
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
};

export default Footer;
