import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import QRCodeStyling from "qr-code-styling";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../app/hooks";
import { selectSetups } from "../../../../setups/setupSlices";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  type: string;
}
export const BasicLocation = ({ currentEmployee, template, type }: Props) => {
  const { selectedCompany } = useAppSelector(selectSetups);

  useEffect(() => {
    if (currentEmployee._id && template.bgColor && template.headerGradient) {
      const qrCanvas = document.getElementById(
        currentEmployee._id as string
      ) as HTMLElement;
      qrCanvas.innerHTML = "";
      const hg = template.headerGradient;

      let qrWidth = 120;
      // if (template.qrLocationWidth && template.qrMoreCvsWidth)
      //   qrWidth =
      //     type === "location"
      //       ? template.qrLocationWidth
      //       : template.qrMoreCvsWidth;

      let qrHeight = 120;
      // if (template.qrLocationHeight && template.qrMoreCvsHeight)
      //   qrHeight =
      //     type === "location"
      //       ? template.qrLocationHeight
      //       : template.qrMoreCvsHeight;

      let qrMargin = 0;
      if (template.qrLocationMargin && template.qrMoreCvsMargin)
        qrMargin =
          type === "location"
            ? template.qrLocationMargin
            : template.qrMoreCvsMargin;
      let qrLink =
        selectedCompany &&
        selectedCompany.address &&
        selectedCompany.address.mobile &&
        currentEmployee &&
        currentEmployee._id
          ? `https://wa.me/${selectedCompany.address.mobile}?text=https://cv.enjazplus.com/app/cvp/${currentEmployee._id}_${currentEmployee.givenName}_${currentEmployee.passportNumber}`
          : "https://cv.enjazplus.com/";

      let qrImageUrl =
        currentEmployee && currentEmployee._id
          ? currentEmployee.facePhoto
          : "/cvPlusLogo2.png";

      const qrCode = new QRCodeStyling({
        width: qrWidth,
        height: qrHeight,
        type: "svg",
        shape: "square",
        margin: qrMargin,
        data: qrLink,
        image: qrImageUrl,
        dotsOptions: {
          type: "classy-rounded",
          color: "#fffff",
          gradient: {
            type: "radial",
            rotation: 0,
            colorStops: [
              { offset: 0, color: hg.firstColor },
              { offset: 1, color: hg.secondColor },
            ],
          },
        },
        cornersSquareOptions: {
          color: hg.firstColor,
          type: "extra-rounded",
        },
        cornersDotOptions: {
          color: hg.secondColor,
        },
        backgroundOptions: {
          color: template.bgColor,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 0,
        },
      });
      qrCode.append(qrCanvas);
    }
  }, [currentEmployee, template]);
  return <Box id={currentEmployee._id as string}></Box>;
};
