import Box from "@mui/material/Box";
import { Link, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectSetups } from "../../features/setups/setupSlices";
import { useAppSelector } from "../../app/hooks";
import { selectProcesses } from "../../features/process/processSlices";
import { ColorButton } from "../../features/home/styles/componentStyled";
const ChooseCandidate = () => {
  const theme = useTheme();
  const { t: tt } = useTranslation();
  const { selectedCompany } = useAppSelector(selectSetups);
  const { selectedEmployee } = useAppSelector(selectProcesses);

  return (
    <Box
      sx={{
        position: "fixed",
        // left: theme.direction === "rtl" ? "10px" : "auto",
        // right: theme.direction === "ltr" ? "10px" : "auto",
        top: "5px",
        bottom: "auto",
        zIndex: "999",
        mx: 2,
      }}
    >
      {selectedCompany &&
        selectedCompany.address &&
        selectedCompany.address.mobile &&
        selectedEmployee &&
        selectedEmployee._id && (
          <ColorButton
            target="_blank"
            href={`https://wa.me/${selectedCompany.address.mobile}?text=https://cv.enjazplus.com/app/cvp/${selectedEmployee._id}_${selectedEmployee.givenName}_${selectedEmployee.passportNumber}`}
            justifyItems="center"
            alignItems="center"
          >
            {" "}
            <img
              alt="Chat on WhatsApp"
              src="/Digital_Glyph_White.svg"
              width="50px"
              height="40px"
            />
            {tt("choose_candidate")}
          </ColorButton>
        )}
    </Box>
  );
};

export default ChooseCandidate;

// ?text=${window.location.href}
