import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  LinearProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientTypes, UserTypes } from "../../features/auth/types/authType";
import { Company } from "../../features/setups/types/setupTypes";
import { selectAuth } from "../../features/auth/authSlice";
import { selectSetups } from "../../features/setups/setupSlices";
import { fetchCompanies } from "../../features/setups/setupSlices";
import { useTranslation } from "react-i18next";

interface Props {
  setCompany: any;
  companyType?: ClientTypes;
  companyId?: string | undefined;
  includeAll?: boolean | undefined;
}
export const CompanyFilter = ({
  setCompany,
  companyType,
  companyId,
  includeAll,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [userSelectedCompany, setSelectedCompany] = useState<Company>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { user, loading: loadingUser } = useAppSelector(selectAuth);
  const {
    selectedCompany: parentCompany,
    companiesWithCount: { companies: allCompanies },
    loading: companiesLoading,
  } = useAppSelector(selectSetups);
  useEffect(() => {
    dispatch(fetchCompanies({ skip: 0, take: 500 }));
    // if (user && user.userType === UserTypes.Super) {
    //   dispatch(
    //     fetchCompanies({ type: ClientTypes.ForeignAgent, skip: 0, take: 500 })
    //   );
    // } else if (user && user.company && user.company.partners) {

    //}
  }, [dispatch, user]);

  useEffect(() => {
    if (allCompanies && allCompanies.length > 0) {
      if (user && user.userType === UserTypes.Super) {
        setCompanies([
          { _id: "-1", displayName: "All", email: "all@cv.enjazplus.com" },
          ...allCompanies,
        ]);
      } else if (user && user.company && user.company.partners) {
        const partnerIds = user.company.partners.map((c) => c._id);
        const offices = allCompanies.filter((c) => partnerIds.includes(c._id));
        if (includeAll)
          setCompanies([
            { _id: "-1", displayName: "All", email: "all@cv.enjazplus.com" },
            ...offices.filter((c) => c.type === companyType),
          ]);
        else if (companyType)
          setCompanies(offices.filter((c) => c.type === companyType));
        else setCompanies(offices);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompanies]);

  // useEffect(() => {
  //   if (user) {
  //     if (user.company && user.company.partners) {
  //       if (user.company.type === ClientTypes.Moderator) {
  //         if (includeAll)
  //           setCompanies([
  //             { _id: "-1", displayName: "All", email: "all@cv.enjazplus.com" },
  //             ...user.company.partners,
  //           ]);
  //         else setCompanies(user.company.partners);
  //       } else {
  //         dispatch(getCompany(user.company.partners[0]._id as string));
  //       }
  //     }
  //   }
  // }, [dispatch, user]);

  // useEffect(() => {
  //   if (
  //     parentCompany &&
  //     parentCompany.partners &&
  //     parentCompany.partners.length > 0 &&
  //     parentCompany.type === ClientTypes.Moderator &&
  //     user &&
  //     user.company
  //   ) {
  //     //console.log(parentCompany.partners);
  //     if (user.company.type === ClientTypes.LocalAgency) {
  //       if (includeAll)
  //         setCompanies([
  //           { _id: "-1", displayName: "All", email: "all@cvplus.com" },
  //           ...parentCompany.partners.filter(
  //             (c) => c.type === ClientTypes.ForeignAgent
  //           ),
  //         ]);
  //       else
  //         setCompanies([
  //           ...parentCompany.partners.filter(
  //             (c) => c.type === ClientTypes.ForeignAgent
  //           ),
  //         ]);
  //     } else if (user.company.type === ClientTypes.ForeignAgent) {
  //       if (includeAll)
  //         setCompanies([
  //           { _id: "-1", displayName: "All", email: "all@cvplus.com" },
  //           ...parentCompany.partners.filter(
  //             (c) => c.type === ClientTypes.LocalAgency
  //           ),
  //         ]);
  //       else
  //         setCompanies([
  //           ...parentCompany.partners.filter(
  //             (c) => c.type === ClientTypes.LocalAgency
  //           ),
  //         ]);
  //     }
  //   }
  // }, [dispatch, parentCompany]);

  // useEffect(() => {
  //   if (user) {
  // if (user.userType === UserTypes.Super) {
  //   dispatch(
  //     fetchCompanies({ type: ClientTypes.ForeignAgent, skip: 0, take: 500 })
  //   );
  // } else if (user.company && user.company.partners) {
  //   if (includeAgency)
  //     setCompanies([...user.company.partners, user.company]);
  //   else if (includeAll)
  //     setCompanies([
  //       { _id: "-1", displayName: "All", email: "all@cv.enjazplus.com" },
  //       ...user.company.partners,
  //     ]);
  //   else setCompanies(user.company.partners);
  // }

  //   }
  // }, [dispatch, parentCompany]);

  function getDisplayName(company?: Company) {
    if (company) {
      if (theme.direction === "ltr")
        return company.displayName; //.substring(0, 10) + "...";
      else return company.nameAmharic;
    } else return "";
  }
  useEffect(() => {
    if (companies?.length > 0) {
      const defaultCompany =
        companyId && companyId.length > 0
          ? (companies.find((c) => c._id === companyId) as Company)
          : (companies[0] as Company);
      //console.log(companies, companyId, defaultCompany);

      setCompany(defaultCompany);
      setSelectedCompany(defaultCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);
  return (
    <Box
      sx={
        {
          //backgroundColor: "white",
          //color: "secondary.main",
          //borderRadius: "5px",
          // p: 0.5,
          // pb: 1,
          // mt: 0.5,
        }
      }
    >
      {loadingUser === "pending" || companies.length < 1 ? (
        <LinearProgress color="secondary" />
      ) : (
        // Search and Link your partner + " " + option.email
        <Autocomplete
          id="companyId"
          options={companies}
          value={userSelectedCompany}
          getOptionLabel={(option) => option.displayName}
          sx={{
            mt: 0,
            minWidth: "200px",
            color: "secondary.main",
            // backgroundColor: "white",
            p: 0.5,
            borderRadius: "5px",
            ".MuiSvgIcon-root": {
              color: "white",
            },
          }}
          defaultValue={companies[0]}
          loading={loadingUser !== "idle" ? true : false}
          onChange={(e, value) => {
            setSelectedCompany(value as Company);
            setCompany(value as Company);
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              label={
                companyType === ClientTypes.ForeignAgent ? t("pro") : t("epro")
              }
              name="companyId"
              {...params}
              variant="outlined"
            />
          )}
        />
      )}
    </Box>
  );
};
