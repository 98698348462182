export const typo = {
  fontFamily: [
    "Open Sans",
    "Roboto",
    "Arial",
    "sans-serif",
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontWeight: 900,
    fontSize: "2rem",
    letterSpacing: "-0.24px",
    "@media (min-width:600px)": {
      fontSize: "2.5rem",
    },
    "@media (min-width:900px)": {
      fontSize: "3rem",
    },
    "@media (min-width:1200px)": {
      fontSize: "3.5rem",
    },
  },
  h2: {
    fontWeight: 900,
    fontSize: "1.5rem",
    letterSpacing: "-0.24px",
    "@media (min-width:600px)": {
      fontSize: "2rem",
    },
    "@media (min-width:900px)": {
      fontSize: "3rem",
    },
    "@media (min-width:1200px)": {
      fontSize: "3.5rem",
    },
  },
  h3: {
    fontSize: "1rem",
    "@media (min-width:600px)": {
      fontSize: "1.8rem",
    },
    "@media (min-width:900px)": {
      fontSize: "2rem",
    },
    "@media (min-width:1200px)": {
      fontSize: "2.5rem",
    },
  },
  h4: {
    fontSize: "1.5rem",
  },
  h5: {
    fontSize: "1rem",
    "@media (min-width:600px)": {
      fontSize: "1.2rem",
    },
    "@media (min-width:900px)": {
      fontSize: "1.4rem",
    },
    "@media (min-width:1200px)": {
      fontSize: "1.6rem",
    },
    fontWeight: "bold",
  },
  h6: {
    fontSize: "1.142rem",
  },
  subtitle1: {
    fontSize: "1.3rem",
  },
  body1: {
    fontSize: "1.1rem",
  },
  body2: {
    fontSize: "0.7rem",
  },
  // h1: {
  //   fontWeight: 500,
  //   fontSize: 35,
  //   letterSpacing: '-0.24px'
  // },
  // h2: {
  //   fontWeight: 500,
  //   fontSize: 29,
  //   letterSpacing: '-0.24px'
  // },
  // h3: {
  //   fontWeight: 500,
  //   fontSize: 24,
  //   letterSpacing: '-0.06px'
  // },
  // h4: {
  //   fontWeight: 500,
  //   fontSize: 20,
  //   letterSpacing: '-0.06px'
  // },
  // h5: {
  //   fontWeight: 500,
  //   fontSize: 16,
  //   letterSpacing: '-0.05px'
  // },
  // h6: {
  //   fontWeight: 500,
  //   fontSize: 14,
  //   letterSpacing: '-0.05px'
  // },
  // overline: {
  //   fontWeight: 500
  // }
};
