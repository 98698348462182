import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Forgot, Profile, Reset, SignIn, Verify } from "../features/auth";
import { Role } from "../features/auth/types/authType";
import { RoleTypes } from "../features/auth/types/roleTypes";
import Dashboard from "../features/dashboard/Dashboard";
import Error from "../features/error/Error";
import { Candidates } from "../features/process/Candidates";
import { EmployeeEntry } from "../features/process/EmployeeEntry";
import { Employees } from "../features/process/Employees";
import { Companies } from "../features/setups/Companies";
import { CompanyProfile } from "../features/setups/CompanyProfile";
import { Users } from "../features/setups/users";
import DashboardLayout from "./DashboardLayout";
import MainLayout from "./MainLayout";
import Complaints from "../features/process/Complaints";
import Tutors from "./tutors/Tutors";
import TutorDetail from "./tutors/TutorDetail";
import Support from "./tutors/Support";
import ImportantLinks from "../features/process/ImportantLinks";
import { Reports } from "./reports/Reports";
import PremiumTemplate from "../features/process/premiumCvs/PremiumTemplate";
import PremiumTemplateMaker from "../features/process/premiumCvs/PremiumTemplateMaker";
import PremiumTemplateCarousel from "../features/process/premiumCvs/PremiumTemplateCarousel";
import LandingPage from "../features/home/LandingPage";

let PrivilegedRoles: Role[] = [];
const routes = (isLoggedIn: Boolean, roles: Role[], agencyType: String) => {
  PrivilegedRoles = roles;
  return [
    {
      path: "/app",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/home" />,
      children: [
        {
          path: "dashboard",
          element: isPrivileged(<Dashboard />, RoleTypes.ViewDashboard),
        },

        {
          path: "profile",
          element: isPrivileged(<Profile />, RoleTypes.CompanyProfile),
        },
        { path: "tutors", element: <Tutors /> },
        { path: "tutor/:id", element: <TutorDetail /> },
        { path: "support", element: <Support /> },
        {
          path: "complaints",
          element: isPrivileged(<Complaints />, RoleTypes.EditComplains),
        },
        { path: "links", element: <ImportantLinks /> },
        {
          path: "reports",
          element: isPrivileged(<Reports />, RoleTypes.ViewReports),
        },
        {
          path: "cp",
          element: isPrivileged(
            <CompanyProfile />,
            RoleTypes.CompanyManagement
          ),
        },
        { path: "cp/:id/:type", element: <CompanyProfile /> },
        {
          path: "cvs",
          element: isPrivileged(
            <Candidates isPremium={false} />,
            RoleTypes.ViewCandidates
          ),
        },
        // { path: "cvd", element: <CvDetailFormat isPremium={false} /> },
        // { path: "pcv", element: <PremiumCandidates isPremium={true} /> },
        {
          path: "cvp/:empId",
          element: isPrivileged(<PremiumTemplate />, RoleTypes.ViewEmployees),
        },
        {
          path: "cvm",
          element: isPrivileged(<PremiumTemplateMaker />, RoleTypes.CvMaker),
        },
        {
          path: "employees",
          element: isPrivileged(<Employees />, RoleTypes.ViewEmployees),
        },
        {
          path: "employee/:id",
          element: isPrivileged(<EmployeeEntry />, RoleTypes.AddEmployees),
        },

        {
          path: "users",
          element: isPrivileged(<Users />, RoleTypes.ManageUsers),
        },
        // { path: "user/:id", element: <User /> },
        {
          path: "c",
          element: isPrivileged(<Companies />, RoleTypes.ViewCompanies),
        },
        {
          path: "/app",
          element: (
            <Navigate
              to={agencyType === "employment" ? "/app/employees" : "/app/coms"}
            />
          ),
        },
        {
          path: "member",
          element: <Outlet />,
          children: [
            { path: "/app/member", element: <Profile /> },
            { path: "/app/member/add", element: <Profile /> },
          ],
        },
      ],
    },

    {
      path: "c/:link",
      element: <PremiumTemplateCarousel />,
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "home", element: <LandingPage /> },
        // { path: "ts", element: <CheckStatus /> },
        { path: "login", element: <SignIn /> },
        // { path: "register", element: <SignUp /> },
        {
          path: "verify/:id/:token/:expires/:signature",
          element: <Verify />,
        },
        // {
        //   path: "ccv/:link",
        //   element: <CvDetailCarousel />,
        // },
        { path: "forgotPassword", element: <Forgot /> },
        { path: "reset/:id/:token", element: <Reset /> },
        { path: "404", element: <Error /> },
        { path: "/", element: <Navigate to="/home" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];
};
function isPrivileged(element: ReactNode, privileged: string): ReactNode {
  if (
    PrivilegedRoles.some((r) => r.displayName === privileged) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("Add", "View")
    ) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("View", "Add")
    )
  ) {
    return element;
  }

  return <Navigate to="/app/support" />;
}
export default routes;
