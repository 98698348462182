import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostAppliedSingleValueGolden } from "./components/basic/BasicPostApplied";
import { BasicDetailGolden } from "./components/basic/BasicDetail";
import { BasicExperienceGolden } from "./components/basic/BasicExperience";
import { BasicPassportGolden } from "./components/basic/BasicPassport";
import { BasicFullNameGolden } from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicReferenceOnlyEnglish } from "./components/basic/BasicReference";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { BasicFooter } from "./components/basic/BasicFooter";
import { useTranslation } from "react-i18next";
import { BasicSkillsOneLineHorz } from "./components/basic/BasicSkills";
import { BasicCountryHorz } from "./components/basic/BasicFlag";

// 704a66 header background
// dbdbdb even row color
interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function GoldenTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");

  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="flex-end"
            sx={{
              mb: 2,

              pt: 1,
            }}
          >
            {/* Short Photo */}
            <Grid item xs={2}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>

            <Grid container item xs={10} spacing={0}>
              <Grid item xs={4}>
                {cvTemplate && currentEmployee && (
                  <BasicReferenceOnlyEnglish
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                {cvTemplate && currentEmployee && (
                  <BasicCountryHorz
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {cvTemplate && currentEmployee && (
                  <BasicFullNameGolden
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {cvTemplate && currentEmployee && (
                  <BasicPostAppliedSingleValueGolden
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    showHeader={false}
                    showProfession={false}
                    showSalary={true}
                    showContractPeriod={false}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={7} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetailGolden
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showPassportNumber={false}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={true}
                showLanguage={true}
                showPlaceOfBirth={false}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={true}
                showComplexion={false}
                showEducation={true}
                showLivingTown={true}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
            {/* {cvTemplate && currentEmployee && (
              <BasicLanguageGolden
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={false}
              />
            )} */}
            {cvTemplate && currentEmployee && (
              <BasicPassportGolden
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showPassportNumber={true}
                showIssueDate={true}
                showPlaceOfIssue={true}
                showExpiryDate={true}
              />
            )}
          </Grid>
          <Grid item md={5} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              sx={{
                alignItems: "center",
              }}
            >
              <Stack direction="row" spacing={1}>
                {cvTemplate && currentEmployee && (
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            {cvTemplate && currentEmployee && (
              <BasicExperienceGolden
                currentEmployee={currentEmployee}
                template={cvTemplate}
                type="entrance"
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            {cvTemplate && currentEmployee && (
              <BasicSkillsOneLineHorz
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <BasicFooter
              selectedCompany={selectedCompany}
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default GoldenTemplate;
