import React, { useEffect, useState } from "react";
import { differenceInHours, format } from "date-fns";

import { NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { selectAuth } from "../../features/auth/authSlice";
import { logout } from "../../features/auth/authSlice";
import { selectPreference } from "../../features/preferences/preferencesSlice";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Avatar, Box, Grid, Stack, Tooltip, useTheme } from "@mui/material";
import {
  Refresh,
  Notifications,
  MarkEmailRead,
  RemoveRedEye,
} from "@mui/icons-material";
import { t } from "i18next";
import {
  createNotification,
  fetchNotifications,
  selectProcesses,
} from "../../features/process/processSlices";
import { NotificationStatus } from "../../features/process/types/processTypes";
import {
  StyledListItemIcon,
  StyledListItemText,
} from "../../styles/listStyled";
import { useTranslation } from "react-i18next";
import { ClientTypes } from "../../features/auth/types/authType";
import { Company } from "../../features/setups/types/setupTypes";

// (
//   <MenuItem key="11" onClick={handleMenuClose} component={NavLink} to="/app/cp">
//     <ListItemIcon>
//       <CorporateFare />
//     </ListItemIcon>
//     Company Profile
//   </MenuItem>
// ),
function NavbarContent() {
  const { user } = useAppSelector(selectAuth);
  const { pageTitle, searchText } = useAppSelector(selectPreference);
  const dispatch = useAppDispatch();
  const { t: te } = useTranslation("enums");
  const {
    notificationsWithCount: { notifications, totalCount },
    loading: markAsReadLoading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const logoutHandler = () => {
    dispatch(logout());
    handleMenuClose();
  };
  const handleMarkAsRead = (employeeId: string) => {
    let values = notifications.find((e) => e._id === employeeId);
    if (values) {
      values = {
        ...values,
        status: NotificationStatus.read,
      };
      dispatch(createNotification(values));
    }
  };
  function refreshPage() {
    window.location.reload();
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function getDisplayName(company?: Company) {
    if (company) {
      if (theme.direction === "ltr")
        return company.displayName.substring(0, 10) + "...";
      else return company.nameAmharic;
    } else return "";
  }
  const [header, setHeader] = useState("");
  useEffect(() => {
    if (user && user.company) {
      let headerTitle = user.company?.displayName as string;
      if (theme.direction === "rtl")
        headerTitle = user.company.nameAmharic as string;
      setHeader(headerTitle);
    } else {
      setHeader(pageTitle);
    }
  }, [user, pageTitle]);
  useEffect(() => {
    dispatch(
      fetchNotifications({
        skip: 0,
        take: 10,
        status: NotificationStatus.new,
      })
    );

    //}
  }, [dispatch]);

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.default",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {notifications.map((row, index) => (
        <MenuItem
          key={index}
          onClick={handleMenuClose}
          component={NavLink}
          to={`/app/cvp/${row.employee?._id}_${Math.random()
            .toString(36)
            .slice(2, 12)}`}
        >
          {/* <StyledListItemIcon active={1}>
            <Avatar
              alt="avatar"
              src={row.from ? row.from.header : ""}
              sx={{
                height: 20,
                width: 20,
              }}
            />
          </StyledListItemIcon> */}
          <Grid
            container
            spacing={1}
            // direction="row"
            // alignItems="center"
            // justifyContent="space-between"
            // sx={{
            //   "& > :not(style)": {
            //     m: 0,
            //     ml: 1,
            //   },
            // }}
          >
            <Grid item xs={9}>
              <Stack direction="row" spacing={1} alignItems="center">
                <StyledListItemIcon active={1} sx={{ mx: 1 }}>
                  <Avatar
                    alt="avatar"
                    src={row.employee ? row.employee.facePhoto : ""}
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                  />
                </StyledListItemIcon>
                <StyledListItemText active={0}>
                  <Stack spacing={0.2}>
                    <Typography
                      variant="body1"
                      fontWeight="bolder"
                      color="secondary.main"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {te(row.message)}
                    </Typography>
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "lowercase",
                        }}
                      >
                        {row.fromUserEmail ? row.fromUserEmail : " "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {getDisplayName(row.from)}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      color="secondary.main"
                    >{`${Math.floor(
                      differenceInHours(
                        new Date(),
                        new Date(row.createdAt as Date)
                      )
                    )} ${t("hoursAgo")}`}</Typography>
                  </Stack>
                </StyledListItemText>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title={t("view")}>
                <IconButton
                  color="primary"
                  component={NavLink}
                  to={`/app/cvp/${row.employee?._id}_${Math.random()
                    .toString(36)
                    .slice(2, 12)}`}
                >
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("markAsRead")}>
                <IconButton
                  color="primary"
                  onClick={() => handleMarkAsRead(row._id as string)}
                  // sx={{ mx: 1 }}
                >
                  <MarkEmailRead />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Typography
        data-aos="fade-left"
        sx={{
          fontWeight: 900,
          fontFamily: "Open Sans",
          p: 0.5,
          display: { xs: "none", md: "flex" },
          textTransform: "capitalize",
        }}
        variant="h3"
        noWrap
      >
        {header}
      </Typography>
      <Box sx={{ flex: "1 1 auto" }} />
      <Toolbar disableGutters>
        {/* <Search initialValue={searchText} /> */}
        <IconButton
          aria-label="notifies"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <Badge badgeContent={totalCount} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <Tooltip title={t("refresh")}>
          <IconButton color="inherit" onClick={refreshPage}>
            <Refresh />
          </IconButton>
        </Tooltip>
        <Typography sx={{ display: { xs: "flex", md: "none" } }}>
          {user?.name}
        </Typography>
        <Typography sx={{ display: { xs: "none", md: "flex" } }}>
          {t("welcome") + " " + user?.name}
        </Typography>
      </Toolbar>

      {/*<Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: { xs: "none", md: "flex" },
        }}
      >
         <IconButton color="inherit" style={{ marginLeft: 0 }}>
        <Badge badgeContent={4} color="secondary">
          <MailIcon />
        </Badge>
      </IconButton>
    

        <Mode />
        <Language />
      </Box>*/}
      {/* <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        size="large"
      >
        <Avatar
          alt="avatar"
          src={user ? user.avatar : ""}
          sx={{
            height: 20,
            width: 20,
          }}
        />
      </IconButton> */}
      {renderMenu}
    </>
  );
}

export default NavbarContent;
