import { experimentalStyled, Link, LinkProps } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import { orange } from "@mui/material/colors";

export const GetInTouchArrow = experimentalStyled("div")(() => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "orange",
}));
export const ImageMargin = experimentalStyled("div")(({ theme }) => ({
  marginLeft: "0",
  [theme.breakpoints.up("sm")]: {
    // zIndex: '-10',
    marginLeft: "-10rem",
  },
}));
export const StyledListItemButton = experimentalStyled(
  ListItemButton
)<ListItemButtonProps>(({ theme }) => ({
  //color: 'white',
  color: theme.palette.getContrastText(theme.palette.primary.light),
  fontSize: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  fontWeight: "bold",

  "&:hover": {
    color: "white",
  },
}));
export const StyledButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    //color: 'white',
    fontSize: "0.75rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.75rem",
      //color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
    // fontWeight: "bold",
    lineHeight: "2.5rem",

    "&:hover": {
      color: theme.palette.getContrastText(theme.palette.action.selected),
      // color: "white",
    },
  })
);
export const MenuButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    //color: 'white',
    color: theme.palette.getContrastText(theme.palette.secondary.light),
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
      color: theme.palette.getContrastText(theme.palette.primary.light),
    },

    "&:hover": {
      color: "orange",
    },
  })
);
export const ColorButton = experimentalStyled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  borderRadius: "32px",
  // width: "200px",
  // height: "54px",
  fontSize: "1rem",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
    padding: "2px 8px",
    // lineHeight: "0.5",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
    padding: "4px 12px",
    // lineHeight: "1",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
    padding: "6px 16px",
    // lineHeight: "1.5",
  },
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",

  border: `0.05rem solid ${theme.palette.secondary.main}`,
  animation: "pulse-border 4.5s linear infinite",
  animationDelay: "1.5s",
  "@keyframes pulse-border": {
    "0%": {
      transform: "scale(1)",
      opacity: 0.5,
    },
    "25%": {
      transform: "scale(1.025)",
      opacity: 0.65,
    },
    "50%": {
      transform: "scale(1.05)",
      opacity: 0.75,
    },
    "75%": {
      transform: "scale(1.075)",
      opacity: 0.85,
    },
    "100%": {
      transform: "scale(1.1)",
      opacity: 1,
    },
  },
}));
export const LanguageButton = experimentalStyled(Button)<ButtonProps>(
  ({ theme }) => ({
    color: theme.palette.getContrastText(orange[500]),
    borderColor: orange[600],
    "&:hover": {
      borderColor: orange[900],
    },
    borderRadius: "8px",
    width: "50px",
    height: "24px",
    fontSize: "1rem",
    fontWeight: "bold",

    border: "0.55rem solid white",
  })
);
