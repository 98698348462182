import { gql } from "@apollo/client";
export const GET_COMPANY = gql`
  fragment getCompany on Company {
    _id
    type
    displayName
    email
    header
    footer
    remarks
    managerNameAmharic
    nameAmharic
    licenseNumber
    enjazPlusLicense
    depositAmount
    address {
      city
      telephone
      alternateTelephone
      mobile
      alternateMobile
      streetAddress
      country
    }
    parentCompany {
      _id
      type
      displayName
      email
      address {
        city
        telephone
        alternateTelephone
        mobile
        alternateMobile
        streetAddress
        country
      }
    }
    partners {
      _id
      type
      displayName
      email
      address {
        city
        telephone
        alternateTelephone
        mobile
        alternateMobile
        streetAddress
        country
      }
    }
    cvTemplate {
      name
      numberOfPages
      cvLang
      bgColor
      headFontColor
      headBgColor
      labelFontColor
      labelBgColor
      valueFontColor
      valueBgColor
      borderColor
      borderRadius
      borderStyle
      borderWidth
      spacing
      labelFontSize
      valueFontSize
      labelFontWeight
      valueFontWeight
      headerHeight
      bodyHeight
      textTransform
      headerGradient
      evenGradient
      oddGradient
      addPassportHeader
      useHeaderGradient
      useBodyGradient
      headerShadow
      bodyShadow
      photoShadow
      passportShadow
      qrLocationLink
      qrLocationWidth
      qrLocationHeight
      qrLocationImageUrl
      qrLocationMargin
      qrMoreCvsLink
      qrMoreCvsWidth
      qrMoreCvsHeight
      qrMoreCvsImageUrl
      qrMoreCvsMargin
      faceHeight
      faceWidth
      standHeight
      standWidth
      flagHeight
      flagWidth
      passportHeight
      passportWidth
      headBgColorAlpha
      headGrFirstColorAlpha
      headGrSecondColorAlpha
      labelBgColorAlpha
      oddGrFirstColorAlpha
      oddGrSecondColorAlpha
      valueBgColorAlpha
      evenGrFirstColorAlpha
      evenGrSecondColorAlpha
    }
  }
`;
export const GET_FOREIGN_AGENT = gql`
  fragment getForeignAgent on ForeignAgent {
    _id
    displayName
    email
    address {
      city
      telephone
      country
    }
    partners {
      _id
      displayName
      email
    }
  }
`;

export const GET_LOCAL_AGENCY = gql`
  fragment getLocalAgency on LocalAgency {
    _id
    displayName
    email
    address {
      city
      telephone
      country
    }
    partners {
      _id
      displayName
      email
    }
  }
`;
