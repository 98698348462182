import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";

import { useAppSelector } from "../../app/hooks";
import { selectAuth } from "../auth/authSlice";

import { useParams } from "react-router-dom";
import { CompanyEntry } from "./Company";
import { useTranslation } from "react-i18next";

// interface CompanyProfileProps {
//   agencyData?: LocalAgency | undefined;
//   agentData?: ForeignAgent | undefined;
// }

export const CompanyProfile = () => {
  const { id: companyId } = useParams() as {
    id: string;
  };
  const { user } = useAppSelector(selectAuth);
  const { t } = useTranslation();
  const { t: ta } = useTranslation("auth");
  return (
    <div>
      <Helmet>
        <title> {`${ta("companyProfile")} | ${t("platform")}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        {companyId ? (
          <CompanyEntry companyId={companyId} />
        ) : (
          <CompanyEntry companyId={user && (user.company?._id as string)} />
        )}

        {/* {companyId && type ? (
          type === "a" ? (
            <LocalAgencyEntry agencyId={companyId} />
          ) : type === "f" ? (
            <ForeignAgentEntry agentId={companyId} />
          ) : (
            <></>
          )
        ) : user?.clientType === ClientTypes.LocalAgency ? (
          <LocalAgencyEntry agencyId={user.agency?._id as string} />
        ) : user?.clientType === ClientTypes.ForeignAgent ? (
          <ForeignAgentEntry agentId={user?.agent?._id as string} />
        ) : (
          <></>
        )} */}
      </Box>
    </div>
  );
};
