import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { TextField, Typography } from "@mui/material";

import Toast from "../../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchRoles } from "../setupSlices";
import { changePageTitle } from "../../preferences/preferencesSlice";
import { selectSetups } from "../setupSlices";
import { UserRoles } from "./components/UserRoles";

interface UserProps {
  userId: string;
}
export const User = ({ userId }: UserProps) => {
  // const { id: userId } = useParams() as {
  //   id: string;
  // };

  const { selectedUser, error } = useAppSelector(selectSetups);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //dispatch(changePageTitle(`Manage User`));
    dispatch(fetchRoles("all"));
    // if (userId.length > 0) {
    //   dispatch(getUser(userId));
    // }
  }, [dispatch, userId]); //user

  useEffect(() => {
    // if (userId && roles.length > 0) {
    //   console.log(`from useEffect:${userId}`);
    //   dispatch(getUser(userId));
    // }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> {`${selectedUser?.name} Account`}</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              id="nameId"
              label="Name"
              value={selectedUser?.name as string}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textTransform: "uppercase" }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="emailId"
              label="Email"
              value={selectedUser?.email as string}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textTransform: "uppercase" }}
            />
          </Grid>
        </Grid>

        <Accordion sx={{ my: 1 }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" component="div">
              Roles
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <UserRoles userId={userId} />
          </AccordionDetails>
        </Accordion>

        {error && <Toast severity="error">{error.message}</Toast>}
      </Box>
    </>
  );
};
