import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientTypes } from "../../features/auth/types/authType";
import { Company } from "../../features/setups/types/setupTypes";
import { selectAuth } from "../../features/auth/authSlice";
import { useTranslation } from "react-i18next";
import { ChevronRight, FilterList } from "@mui/icons-material";
import {
  EmployeeStatus,
  CompletionStatus,
  Nationalities,
  ExperiencePeriods,
  AgeGap,
  LanguageExperienceFilter,
  ProfessionalTypesFilter,
  ReligionTypesFilter,
  MaritalStatusFilter,
  LanguageExperience,
  MaritalStatus,
  ProfessionalTypes,
  ReligionTypes,
} from "../../features/process/types/processTypes";
import { CompanyFilter } from "./CompanyFilter";
import {
  selectPreference,
  setAgeEnd,
  setAgeStart,
  setArabic,
  setBiometricsCompleted,
  setCocCompleted,
  setEnglish,
  setExperienceYears,
  setForeignAgent,
  setLocalAgency,
  setMaritalStatus,
  setMedicalCompleted,
  setNationality,
  setProfession,
  setReligion,
  setStatus,
  toggleThis,
} from "../../features/preferences/preferencesSlice";

interface Props {
  listType: string;
}
export const ListFilter = ({ listType }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");

  const { user, loading } = useAppSelector(selectAuth);

  const {
    agencyId,
    agentId,
    status,
    nationality,
    maritalStatus,
    religion,
    profession,
    arabic,
    english,
    ageStart,
    ageEnd,
    experienceYears,
    cocCompleted,
    medicalCompleted,
    biometricsCompleted,
  } = useAppSelector(selectPreference);

  const [localCompany, setLocalCompany] = useState<Company>();
  const [foreignCompany, setForeignCompany] = useState<Company>();

  const onChangeStatus = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setStatus(value.target.value as EmployeeStatus));
  };

  const onChangeNationality = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setNationality(value.target.value as Nationalities));
  };
  const onChangeMaritalStatus = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMaritalStatus(value.target.value as MaritalStatus));
  };
  const onChangeReligion = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setReligion(value.target.value as ReligionTypes));
  };
  const onChangeProfession = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProfession(value.target.value as ProfessionalTypes));
  };
  const onChangeArabic = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setArabic(value.target.value as LanguageExperience));
  };
  const onChangeEnglish = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEnglish(value.target.value as LanguageExperience));
  };
  const onChangeAgeStart = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.value === "all") dispatch(setAgeStart(undefined));
    else dispatch(setAgeStart(parseInt(value.target.value)));
  };
  const onChangeAgeEnd = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.value === "all") dispatch(setAgeEnd(undefined));
    else dispatch(setAgeEnd(parseInt(value.target.value)));
  };
  const onChangeExperienceYears = (value: ChangeEvent<HTMLInputElement>) => {
    if (value.target.value === "all") dispatch(setExperienceYears(undefined));
    else dispatch(setExperienceYears(parseInt(value.target.value)));
  };

  const onChangeCoc = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCocCompleted(value.target.value as CompletionStatus));
  };

  const onChangeMedical = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMedicalCompleted(value.target.value as CompletionStatus));
  };

  const onChangeBiometrics = (value: ChangeEvent<HTMLInputElement>) => {
    dispatch(setBiometricsCompleted(value.target.value as CompletionStatus));
  };

  useEffect(() => {
    if (localCompany && localCompany._id)
      dispatch(setLocalAgency(localCompany?._id));
  }, [dispatch, localCompany]);

  useEffect(() => {
    if (foreignCompany && foreignCompany._id)
      dispatch(setForeignAgent(foreignCompany?._id));
  }, [dispatch, foreignCompany]);
  const handleDrawerClose = () => {
    dispatch(toggleThis({ type: "Filter", newValue: false }));
  };
  return (
    <Box sx={{ pt: 8, backgroundColor: "primary.main" }}>
      {loading === "pending" ? (
        <LinearProgress color="secondary" />
      ) : (
        <Stack sx={{ backgroundColor: "white" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: "primary.main", color: "white", py: 1 }}
          >
            <Stack direction="row" alignItems="center" justifyContent="start">
              <FilterList />
              <Typography variant="h6" component="div">
                {t("filter")}
              </Typography>
            </Stack>
            <IconButton
              style={{ color: "white" }}
              onClick={handleDrawerClose}
              size="large"
            >
              <ChevronRight />
            </IconButton>
          </Stack>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ px: 1, pb: 5, pt: 1 }}
          >
            <Grid item xs={12}>
              <CompanyFilter
                companyType={
                  user?.company?.type === ClientTypes.LocalAgency
                    ? ClientTypes.ForeignAgent
                    : ClientTypes.LocalAgency
                }
                setCompany={
                  user?.company?.type === ClientTypes.LocalAgency
                    ? setForeignCompany
                    : setLocalCompany
                }
                includeAll={true}
                companyId={
                  user?.company?.type === ClientTypes.LocalAgency
                    ? agentId
                    : agencyId
                }
              />
            </Grid>
            {user?.company?.type === ClientTypes.Moderator && (
              <Grid item xs={12}>
                <CompanyFilter
                  companyType={ClientTypes.ForeignAgent}
                  setCompany={setForeignCompany}
                  includeAll={true}
                  companyId={agentId}
                />
              </Grid>
            )}

            {listType === "employees" && (
              <Grid item xs={12}>
                <TextField
                  id="statuses"
                  select
                  label={t("status")}
                  onChange={onChangeStatus}
                  value={status}
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ mt: 0 }}
                  SelectProps={{
                    sx: {
                      ".MuiSvgIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                >
                  {Object.keys(EmployeeStatus).map((option) => (
                    <MenuItem key={option} value={option as string}>
                      {te(option.toLowerCase())}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                id="nationalities"
                select
                label={te("nationality")}
                onChange={onChangeNationality}
                value={nationality}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(Nationalities).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="maritalStatus"
                select
                label={te("maritalStatus")}
                onChange={onChangeMaritalStatus}
                value={maritalStatus}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(MaritalStatusFilter).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="religion"
                select
                label={te("religion")}
                onChange={onChangeReligion}
                value={religion}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(ReligionTypesFilter).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="profession"
                select
                label={te("profession")}
                onChange={onChangeProfession}
                value={profession}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(ProfessionalTypesFilter).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="arabic"
                select
                label={te("arabic")}
                onChange={onChangeArabic}
                value={arabic}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(LanguageExperienceFilter).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="english"
                select
                label={te("english")}
                onChange={onChangeEnglish}
                value={english}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(LanguageExperienceFilter).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="experienceYears"
                select
                label={t("workExperience")}
                onChange={onChangeExperienceYears}
                value={experienceYears}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.values(ExperiencePeriods).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="ageStart"
                select
                label={te("fromAge")}
                onChange={onChangeAgeStart}
                value={ageStart}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.values(AgeGap).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="ageEnd"
                select
                label={te("toAge")}
                onChange={onChangeAgeEnd}
                value={ageEnd}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.values(AgeGap).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="coc"
                select
                label={te("coc")}
                onChange={onChangeCoc}
                value={cocCompleted}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(CompletionStatus).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="medical"
                select
                label={te("medical")}
                onChange={onChangeMedical}
                value={medicalCompleted}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(CompletionStatus).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="biometrics"
                select
                label={te("biometrics")}
                onChange={onChangeBiometrics}
                value={biometricsCompleted}
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mt: 0 }}
                SelectProps={{
                  sx: {
                    ".MuiSvgIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {Object.keys(CompletionStatus).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te(option.toLowerCase())}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Box>
  );
};
