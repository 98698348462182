import { gql } from "@apollo/client";
export const GET_VISA = gql`
  fragment getVisa on Visa {
    _id
    contractNumber
    visaNumber
    fullName
    fullNameAmharic
    cityAmharic
    passportNumber
    sponsorBirthDate
    wekalaDate
    visaQty
    salary
    currency
    remarks
    firstTime
    goodLooking
    writeRead
    profession
    religion
    ageCategory
    complexion
    status
    visaType
    address {
      telephone
      city
      country
    }
    owner {
      _id
      type
      displayName
      email
    }
  }
`;
export const GET_VISA_NO_OWNER = gql`
  fragment getVisaNoOwner on Visa {
    _id
    contractNumber
    visaNumber
    fullName
    fullNameAmharic
    cityAmharic
    passportNumber
    sponsorBirthDate
    wekalaDate
    visaQty
    salary
    currency
    remarks
    firstTime
    goodLooking
    writeRead
    profession
    religion
    ageCategory
    complexion
    visaType
    status
    address {
      telephone
      city
      country
    }
    owner {
      _id
    }
  }
`;

export const GET_EMPLOYEE = gql`
  ${GET_VISA_NO_OWNER}
  fragment getEmployee on Employee {
    _id
    status
    currentStatusDate
    currentStatusRemark
    passportNumber
    placeOfBirth
    placeOfIssue
    notificationMessage
    medicalCompleted
    cocCompleted
    biometricsCompleted
    visaIssued
    labourIssued
    ticketBooked
    givenName
    surName
    facePhoto
    standPhoto
    passportScan
    sex
    dateOfBirth
    selectionDueDate
    passportIssueDate
    passportExpiryDate
    documents
    additionalData {
      nationality
      pastNationality
      maritalStatus
      religion
      appliedProfession
      appliedCountry
      contractPeriod
      complexion
      documentReceivedDate
      salary
      currency
      numberOfChildren
      notes
      height
      weight
      fullNameAmharic
      lmisId
      labourId
      discontinuedDate
      afterFlightStatusDate
      afterFlightStatusRemark
    }
    address {
      telephone
      alternateTelephone
      city
      streetAddress
      country
    }
    contactPerson {
      displayName
      kinship
      address {
        telephone
        alternateTelephone
        city
        streetAddress
        country
      }
    }
    education {
      arabicLanguage
      englishLanguage
      educationLevel
      skills
      fieldOfStudy
      yearCompleted
      educationQG
      remarks
    }
    embassyProcess {
      enjazNumber
      stampedDate
    }
    flight {
      flightDateTime
      arrivedDateTime
    }
    workExperience {
      _id
      country
      profession
      years
    }
    visa {
      ...getVisaNoOwner
    }
    owner {
      _id
      type
      displayName
      nameAmharic
      depositAmount
      email
      header
      remarks
      address {
        telephone
        city
        country
      }
    }
    partner {
      _id
      type
      displayName
      nameAmharic
      depositAmount
      email
      header
      remarks
      address {
        telephone
        city
        country
      }
    }
    parentCompany {
      _id
      type
      displayName
      depositAmount
      email
      header
      remarks
      address {
        telephone
        city
        country
      }
    }
    prospects {
      _id
      type
      displayName
      email
      remarks
      header
    }
  }
`;
export const GET_EMPLOYEE_ONLY = gql`
  fragment getEmployeeOnly on Employee {
    _id
    status
    currentStatusDate
    currentStatusRemark
    passportNumber
    placeOfBirth
    placeOfIssue
    notificationMessage
    medicalCompleted
    cocCompleted
    biometricsCompleted
    visaIssued
    labourIssued
    ticketBooked
    givenName
    surName
    facePhoto
    standPhoto
    passportScan
    sex
    dateOfBirth
    selectionDueDate
    passportIssueDate
    passportExpiryDate
    documents
    additionalData {
      nationality
      pastNationality
      maritalStatus
      religion
      appliedProfession
      appliedCountry
      contractPeriod
      complexion
      documentReceivedDate
      salary
      currency
      numberOfChildren
      notes
      height
      weight
      discontinuedDate
      afterFlightStatusDate
      afterFlightStatusRemark
    }
    address {
      telephone
      alternateTelephone
      city
      streetAddress
      country
    }
    contactPerson {
      displayName
      kinship
      address {
        telephone
        alternateTelephone
        city
        streetAddress
        country
      }
    }
    education {
      arabicLanguage
      englishLanguage
      educationLevel
      skills
      fieldOfStudy
      yearCompleted
      educationQG
      remarks
    }
    embassyProcess {
      enjazNumber
      stampedDate
    }
    flight {
      flightDateTime
      arrivedDateTime
    }
    workExperience {
      _id
      country
      profession
      years
    }

    owner {
      _id
      type
      displayName
      nameAmharic
      email
      remarks
      header
      address {
        telephone
        city
        country
      }
    }
    partner {
      _id
      type
      displayName
      nameAmharic
      email
      remarks
      header
      address {
        telephone
        city
        country
      }
    }
    parentCompany {
      _id
      type
      displayName
      email
      remarks
      header
      address {
        telephone
        city
        country
      }
    }
    prospects {
      _id
      type
      displayName
      email
      remarks
      header
    }
  }
`;
