import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Stack, colors } from "@mui/material";
import { Pie } from "react-chartjs-2";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changePageTitle } from "../preferences/preferencesSlice";
import { fetchEmployees, selectProcesses } from "../process/processSlices";
import { Employee, EmployeeStatus } from "../process/types/processTypes";
import { useTranslation } from "react-i18next";
import { ChartOptions } from "chart.js";
import { Summary, TotalValues } from "./TotalValues";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const {
    employeesWithCount: { employees, totalCount },
    loading,
  } = useAppSelector(selectProcesses);
  //refreshList: "refresh",
  useEffect(() => {
    dispatch(changePageTitle("Dashboard"));
    dispatch(
      fetchEmployees({
        skip: 0,
        take: 500,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      const groupByCategory = employees.reduce(
        (group: { [key: string]: string[] }, product: Employee) => {
          let { status } = product;
          status = status as EmployeeStatus;
          group[status] = group[status] ?? [];
          group[status].push(product._id as string);
          return group;
        },
        {}
      );
      //console.log(groupByCategory);
      //console.log(groupByCategory["new"].length);
      setLabels([
        te("new"),
        te("selected"),
        te("contracted"),
        te("visa_issued"),
        te("labour_issued"),
        te("flight_booked"),
        te("departed"),
        te("lost"),
        te("returned"),
        te("contract_completed"),
        te("other"),
      ]);
      setValues([
        groupByCategory["new"] ? groupByCategory["new"].length : 0,
        groupByCategory["selected"] ? groupByCategory["selected"].length : 0,
        groupByCategory["contracted"]
          ? groupByCategory["contracted"].length
          : 0,
        groupByCategory["visa_issued"]
          ? groupByCategory["visa_issued"].length
          : 0,
        groupByCategory["labour_issued"]
          ? groupByCategory["labour_issued"].length
          : 0,

        groupByCategory["flight_booked"]
          ? groupByCategory["flight_booked"].length
          : 0,

        groupByCategory["departed"] ? groupByCategory["departed"].length : 0,

        groupByCategory["lost"] ? groupByCategory["lost"].length : 0,
        groupByCategory["returned"] ? groupByCategory["returned"].length : 0,

        groupByCategory["contract_completed"]
          ? groupByCategory["contract_completed"].length
          : 0,
        groupByCategory["other"] ? groupByCategory["other"].length : 0,
      ]);
    }
    // console.log(labels);
    // console.log(values);
    // console.log(totalCount);
  }, [employees]);

  const bgColors = [
    colors.orange[500],
    colors.grey[500],
    colors.green[500],
    colors.blue[500],
    colors.deepPurple[500],
    colors.indigo[500],
    colors.grey[500],
    colors.green[500],
    colors.blue[500],
    colors.deepPurple[500],
    colors.indigo[500],
  ];
  const bgColorsBolder = [
    colors.orange[700],
    colors.grey[700],
    colors.green[700],
    colors.blue[700],
    colors.deepPurple[700],
    colors.indigo[700],
    colors.grey[700],
    colors.green[700],
    colors.blue[700],
    colors.deepPurple[700],
    colors.indigo[700],
  ];
  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: bgColors,
        borderWidth: 2,
        borderColor: "white",
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: labels,
  };

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
        reverse: true,
        title: {
          display: true,
          text: "Current Status",
          position: "center",
          font: {
            size: 24,
            family: "Open Sans",
            style: "italic",
            weight: "bold",
          },
          color: "#FFA500",
          padding: {
            top: 10,
            bottom: 5,
          },
        },

        labels: {
          font: {
            size: 24,
            family: "Open Sans",
            style: "normal",
            weight: "bold",
          },
          color: "#008000",
        },
      },
    },
  };
  return (
    <>
      <Helmet>
        <title> {`${t("dashboard")} | ${t("platform")}`}</title>
      </Helmet>
      <Box
        sx={{
          maxHeight: "100vh",
          m: 1,
        }}
      >
        {values && values.length === 11 && (
          <Grid container spacing={1}>
            <Grid container item md={3} xs={12} spacing={1}>
              <Grid item xs={12}>
                <TotalValues
                  key={1}
                  label={labels[1]}
                  value={values[1]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[1]}
                  avatarColor={bgColorsBolder[1]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={2}
                  label={labels[2]}
                  value={values[2]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[2]}
                  avatarColor={bgColorsBolder[2]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={3}
                  label={labels[3]}
                  value={values[3]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[3]}
                  avatarColor={bgColorsBolder[3]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={4}
                  label={labels[4]}
                  value={values[4]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[4]}
                  avatarColor={bgColorsBolder[4]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={5}
                  label={labels[5]}
                  value={values[5]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[5]}
                  avatarColor={bgColorsBolder[5]}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack spacing={1}>
                {/* <Summary
                  key={10}
                  label={t("total")}
                  value={totalCount}
                  totalCount={totalCount}
                  loading={loading}
                  color={"#0d637d"}
                  avatarColor={"#0d637d"}
                /> */}
                <TotalValues
                  key={0}
                  label={labels[0]}
                  value={values[0]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[0]}
                  avatarColor={bgColorsBolder[0]}
                />
                <Pie options={options} data={data} />
              </Stack>
            </Grid>
            <Grid container item md={3} xs={12} spacing={1}>
              <Grid item xs={12}>
                <TotalValues
                  key={6}
                  label={labels[6]}
                  value={values[6]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[6]}
                  avatarColor={bgColorsBolder[6]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={7}
                  label={labels[7]}
                  value={values[7]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[7]}
                  avatarColor={bgColorsBolder[7]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={8}
                  label={labels[8]}
                  value={values[8]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[8]}
                  avatarColor={bgColorsBolder[8]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={9}
                  label={labels[9]}
                  value={values[9]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[9]}
                  avatarColor={bgColorsBolder[9]}
                />
              </Grid>
              <Grid item xs={12}>
                <TotalValues
                  key={10}
                  label={labels[10]}
                  value={values[10]}
                  totalCount={totalCount}
                  loading={loading}
                  color={bgColors[10]}
                  avatarColor={bgColorsBolder[10]}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Dashboard;
