import { useEffect } from "react";
import { Form, FormikProps, Formik } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import Save from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { registerSchema } from "../../features/setups/validation";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Toast from "../../components/Layout/Toast";
import {
  addCompany,
  getCompany,
  selectSetups,
} from "../../features/setups/setupSlices";
import { Company } from "../../features/setups/types/setupTypes";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { selectAuth } from "../../features/auth/authSlice";
import { AddressDetail } from "../../features/process/components/AddressDetail";

export const CompanyDetail = () => {
  const { user } = useAppSelector(selectAuth);

  const {
    loading,
    error,
    success,
    selectedCompany: companyProfile,
  } = useAppSelector(selectSetups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && user.company && user.company._id) {
      const companyId = user.company?._id as string;
      if (companyId && companyId.length > 0) {
        dispatch(getCompany(companyId as string));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box>
      <Formik
        enableReinitialize={true}
        initialValues={companyProfile as Company}
        validationSchema={registerSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          dispatch(addCompany(values));
        }}
      >
        {(props: FormikProps<Company>) => (
          <Form>
            <Accordion sx={{ m: 1 }} expanded={true}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Company Profile</Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="displayName"
                      disabled
                      label="Name"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormikTextField formikKey="email" disabled label="Email" />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="nameAmharic"
                      label="اسم الشركة (عربي)"
                    />
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="licenseNumber"
                      label="License Number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}></Grid>
                </Grid>
                <AddressDetail />

                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={!props.isValid}
                  sx={{ mt: 1 }}
                >
                  <Save /> Save Company Profile
                </Button>
              </AccordionDetails>
            </Accordion>
            <br />
            {loading === "pending" && <LinearProgress color="secondary" />}
            {success && <Toast severity="success">{success.message}</Toast>}
            {error && <Toast severity="error">{error.message}</Toast>}
          </Form>
        )}
      </Formik>
      <Box m={1}>
        {error && <Toast severity="error">{error.message}</Toast>}
      </Box>
    </Box>
  );
};
