import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
  setSelectedEmployee,
  sharedEmployees,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  useTheme,
  Stack,
  Box,
  Typography,
  Container,
  MobileStepper,
  AppBar,
  Toolbar,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { GradientTemplate, LabelTemplate } from "./styles/table";
import Image from "mui-image";
import { Employee, EmployeeStatus } from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import SwipeableViews from "react-swipeable-views";

import { bindKeyboard } from "react-swipeable-views-utils";

import InterTemplate from "./InterTemplate";
import BasicTemplate from "./BasicTemplate";
import { CvTemplate } from "../../setups/types/setupTypes";
import { getCompany, selectSetups, setCompany } from "../../setups/setupSlices";
import OxagonTwoTemplate from "./OxagonTwoTemplate";
import MaledaTemplate from "./MaledaTemplate";
import BasicTwoTemplate from "./BasicTwoTemplate";
import BasicThreeTemplate from "./BasicThreeTemplate";
import EntranceTemplate from "./EntranceTemplate";
import AkeemTemplate from "./AkeemTemplate";
import CadresTemplate from "./CadresTemplate";
import SkillStarTemplate from "./SkillStarTemplate";
import SanadTemplate from "./SanadTemplate";
import Toast from "../../../components/Layout/Toast";
import ChooseCandidate from "../../../components/footer/ChooseCandidates";
import { ShadowStyled } from "../../home/styles/layoutStyled";
import Logo from "../components/Logo";
import SupportChat from "../../../components/footer/SupportChat";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Helmet } from "react-helmet";
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function PremiumTemplateCarousel() {
  // const { agencyId, agentId } = useParams() as {
  //   agencyId: string;
  //   agentId: string;
  // };
  const { link } = useParams() as {
    link: string;
  };
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t: tt } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);

  //const { searchText } = useAppSelector(selectPreference);
  // const [open, setOpen] = useState(false);
  // const [openVisa, setOpenVisa] = useState(false);
  // const [sharedEmployeeIds, setSharedEmployeeIds] = useState<string[]>([]);
  // const [visaSelectedEmployeeId, setVisaSelectedEmployeeId] =
  //   useState<string>("");
  // const [shortLink, setShortLink] = useState<ShortLink>({});
  //const { user } = useAppSelector(selectAuth);
  // const [cvHeader, setCompany] = useState<Company>();

  const [name, setName] = useState("inter");
  const [numberOfPages, setNumberOfPages] = useState(1);

  const [cvLang, setCvLang] = useState("ar");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const [headFontColor, setHeadFontColor] = useState("#ffffff");
  const [headBgColor, setHeadBgColor] = useState(theme.palette.primary.main);
  const [labelFontColor, setLabelFontColor] = useState(
    theme.palette.primary.main
  );
  const [labelBgColor, setLabelBgColor] = useState("#ffffff");
  const [valueFontColor, setValueFontColor] = useState(
    theme.palette.secondary.main
  );
  const [valueBgColor, setValueBgColor] = useState("#ffffff");

  const [borderRadius, setBorderRadius] = useState("5px");
  const [borderStyle, setBorderStyle] = useState("solid");
  const [borderColor, setBorderColor] = useState("#ffffff");
  const [borderWidth, setBorderWidth] = useState(1);

  const [spacing, setSpacing] = useState(1);
  const [labelFontSize, setLabelFontSize] = useState(16);
  const [valueFontSize, setValueFontSize] = useState(18);
  const [labelFontWeight, setLabelFontWeight] = useState(900);
  const [valueFontWeight, setValueFontWeight] = useState(900);

  const [textTransform, setTextTransform] = useState("uppercase");
  const [headerHeight, setHeaderHeight] = useState(48);
  const [bodyHeight, setBodyHeight] = useState(36);

  const [addPassportHeader, setAddPassportHeader] = useState(false);
  const [useHeaderGradient, setUseHeaderGradient] = useState(false);
  const [useBodyGradient, setUseBodyGradient] = useState(false);

  const [headerShadow, setHeaderShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [bodyShadow, setBodyShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [photoShadow, setPhotoShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [passportShadow, setPassportShadow] = useState(
    "5px 5px rgba(0,0,0,0.14)"
  );

  const [qrLocationLink, setQrLocationLink] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrLocationWidth, setQrLocationWidth] = useState(120);
  const [qrLocationHeight, setQrLocationHeight] = useState(120);
  const [qrLocationImageUrl, setQrLocationImageUrl] =
    useState("/googleMaps.png");
  const [qrLocationMargin, setQrLocationMargin] = useState(0);

  const [qrMoreCvsLink, setQrMoreCvsLink] = useState(
    "https://cv.enjazplus.com"
  );
  const [qrMoreCvsWidth, setQrMoreCvsWidth] = useState(120);
  const [qrMoreCvsHeight, setQrMoreCvsHeight] = useState(120);
  const [qrMoreCvsImageUrl, setQrMoreCvsImageUrl] =
    useState("/cvPlusLogo2.png");
  const [qrMoreCvsMargin, setQrMoreCvsMargin] = useState(0);

  const [faceHeight, setFaceHeight] = useState("240px");
  const [faceWidth, setFaceWidth] = useState("100%");
  const [standHeight, setStandHeight] = useState("600px");
  const [standWidth, setStandWidth] = useState("400px");
  const [flagHeight, setFlagHeight] = useState("200px");
  const [flagWidth, setFlagWidth] = useState("100%");
  const [passportHeight, setPassportHeight] = useState("650px");
  const [passportWidth, setPassportWidth] = useState("700px");
  const [headBgColorAlpha, setHeadBgColorAlpha] = useState(1);
  const [headGrFirstColorAlpha, setHeadGrFirstColorAlpha] = useState(1);
  const [headGrSecondColorAlpha, setHeadGrSecondColorAlpha] = useState(1);
  const [labelBgColorAlpha, setLabelBgColorAlpha] = useState(1);
  const [oddGrFirstColorAlpha, setOddGrFirstColorAlpha] = useState(1);
  const [oddGrSecondColorAlpha, setOddGrSecondColorAlpha] = useState(1);
  const [valueBgColorAlpha, setValueBgColorAlpha] = useState(1);
  const [evenGrFirstColorAlpha, setEvenGrFirstColorAlpha] = useState(1);
  const [evenGrSecondColorAlpha, setEvenGrSecondColorAlpha] = useState(1);

  const [headerGradient, setHeaderGradient] = useState<GradientTemplate>({
    typeOfGradient: "radial-gradient",
    degree: "circle",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [evenGradient, setEvenGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });
  const [oddGradient, setOddGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });

  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    name,
    numberOfPages,
    cvLang,
    bgColor: backgroundColor,
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    headerGradient,
    evenGradient,
    oddGradient,
    addPassportHeader,
    useHeaderGradient,
    useBodyGradient,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  });

  useEffect(() => {
    setCvTemplate({
      name,
      numberOfPages,
      cvLang,
      bgColor: backgroundColor,
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
      borderColor,
      borderRadius,
      borderWidth,
      borderStyle,
      spacing,
      labelFontSize,
      valueFontSize,
      labelFontWeight,
      valueFontWeight,
      textTransform,
      headerHeight,
      bodyHeight,
      headerGradient,
      evenGradient,
      oddGradient,
      addPassportHeader,
      useHeaderGradient,
      useBodyGradient,
      headerShadow,
      bodyShadow,
      photoShadow,
      passportShadow,
      qrLocationLink,
      qrLocationWidth,
      qrLocationHeight,
      qrLocationImageUrl,
      qrLocationMargin,
      qrMoreCvsLink,
      qrMoreCvsWidth,
      qrMoreCvsHeight,
      qrMoreCvsImageUrl,
      qrMoreCvsMargin,
      faceHeight,
      faceWidth,
      standHeight,
      standWidth,
      flagHeight,
      flagWidth,
      passportHeight,
      passportWidth,
      headBgColorAlpha,
      headGrFirstColorAlpha,
      headGrSecondColorAlpha,
      labelBgColorAlpha,
      oddGrFirstColorAlpha,
      oddGrSecondColorAlpha,
      valueBgColorAlpha,
      evenGrFirstColorAlpha,
      evenGrSecondColorAlpha,
    });
  }, [
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    cvLang,
    headerGradient,
    evenGradient,
    oddGradient,
    useHeaderGradient,
    useBodyGradient,
    name,
    numberOfPages,
    backgroundColor,
    addPassportHeader,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  ]);

  const {
    // selectedEmployee: currentEmployee,
    employeesWithCount: { employees, header },
    success,
    error,
    loading,
  } = useAppSelector(selectProcesses);

  const { selectedCompany } = useAppSelector(selectSetups);

  const renderCvFormat = (cv: CvTemplate) => {
    setName(cv.name);
    setNumberOfPages(cv.numberOfPages);
    setCvLang(cv.cvLang);
    setBackgroundColor(cv.bgColor);
    setHeadFontColor(cv.headFontColor);
    setHeadBgColor(cv.headBgColor);
    setLabelFontColor(cv.labelFontColor);
    setLabelBgColor(cv.labelBgColor);
    setValueFontColor(cv.valueFontColor);
    setValueBgColor(cv.valueBgColor);

    setBorderColor(cv.borderColor);
    setBorderRadius(cv.borderRadius);
    setBorderWidth(cv.borderWidth);
    setBorderStyle(cv.borderStyle);
    setSpacing(cv.spacing);
    setLabelFontSize(cv.labelFontSize);
    setValueFontSize(cv.valueFontSize);
    setLabelFontWeight(cv.labelFontWeight);
    setValueFontWeight(cv.valueFontWeight);
    setTextTransform(cv.textTransform);
    setHeaderHeight(cv.headerHeight);
    setBodyHeight(cv.bodyHeight);

    setAddPassportHeader(cv.addPassportHeader);
    setUseHeaderGradient(cv.useHeaderGradient);
    setUseBodyGradient(cv.useBodyGradient);

    setHeaderShadow(cv.headerShadow);
    setBodyShadow(cv.bodyShadow);
    setPhotoShadow(cv.photoShadow);
    setPassportShadow(cv.passportShadow);

    setQrLocationLink(cv.qrLocationLink);
    setQrLocationWidth(cv.qrLocationWidth);
    setQrLocationHeight(cv.qrLocationHeight);
    setQrLocationImageUrl(cv.qrLocationImageUrl);
    setQrLocationMargin(cv.qrLocationMargin);

    setQrMoreCvsLink(cv.qrMoreCvsLink);
    setQrMoreCvsWidth(cv.qrMoreCvsWidth);
    setQrMoreCvsHeight(cv.qrMoreCvsHeight);
    setQrMoreCvsImageUrl(cv.qrMoreCvsImageUrl);
    setQrMoreCvsMargin(cv.qrMoreCvsMargin);

    setFaceHeight(cv.faceHeight);
    setFaceWidth(cv.faceWidth);
    setStandHeight(cv.standHeight);
    setStandWidth(cv.standWidth);
    setFlagHeight(cv.flagHeight);
    setFlagWidth(cv.flagWidth);
    setPassportHeight(cv.passportHeight);
    setPassportWidth(cv.passportWidth);

    setHeadBgColorAlpha(cv.headBgColorAlpha);
    setHeadGrFirstColorAlpha(cv.headGrFirstColorAlpha);
    setHeadGrSecondColorAlpha(cv.headGrSecondColorAlpha);
    setLabelBgColorAlpha(cv.labelBgColorAlpha);
    setOddGrFirstColorAlpha(cv.oddGrFirstColorAlpha);
    setOddGrSecondColorAlpha(cv.oddGrSecondColorAlpha);
    setValueBgColorAlpha(cv.valueBgColorAlpha);
    setEvenGrFirstColorAlpha(cv.evenGrFirstColorAlpha);
    setEvenGrSecondColorAlpha(cv.evenGrSecondColorAlpha);

    const headerGr = cv.headerGradient.split("_");
    if (headerGr.length === 6) {
      setHeaderGradient({
        typeOfGradient: headerGr[0],
        degree: headerGr[1],
        firstColor: headerGr[2],
        firstPercentage: headerGr[3],
        secondColor: headerGr[4],
        secondPercentage: headerGr[5],
      });
    }

    const evenGr = cv.evenGradient.split("_");
    if (evenGr.length === 6) {
      setEvenGradient({
        typeOfGradient: evenGr[0],
        degree: evenGr[1],
        firstColor: evenGr[2],
        firstPercentage: evenGr[3],
        secondColor: evenGr[4],
        secondPercentage: evenGr[5],
      });
    }
    const oddGr = cv.oddGradient.split("_");
    if (oddGr.length === 6) {
      setOddGradient({
        typeOfGradient: oddGr[0],
        degree: oddGr[1],
        firstColor: oddGr[2],
        firstPercentage: oddGr[3],
        secondColor: oddGr[4],
        secondPercentage: oddGr[5],
      });
    }
  };

  useEffect(() => {
    if (header && header._id) {
      dispatch(getCompany(header._id));
      //dispatch(setCompany(header));
    }
  }, [dispatch, header]);

  useEffect(() => {
    if (
      activeStep &&
      selectedCompany &&
      selectedCompany._id &&
      employees &&
      employees.length > 0
    ) {
      dispatch(setSelectedEmployee(employees[activeStep]));
    }
  }, [activeStep, dispatch, employees, selectedCompany]);

  useEffect(() => {
    if (selectedCompany && selectedCompany._id) {
      if (selectedCompany.cvTemplate) {
        renderCvFormat(selectedCompany.cvTemplate);
      } else {
        //renderCvFormat(currentEmployee.owner.cvTemplate);
        //console.log(selectedCompany);
      }
    }
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    //dispatch(changePageTitle("Cvs Detail"));
    if (!employees || employees.length === 0) {
      if (link && link.length === 6) {
        dispatch(
          sharedEmployees({
            link: link,
            refreshList: "network-only",
          })
        );
      }
    }
  }, [dispatch, link]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      setMaxSteps(employees.length);
      setActiveStep(0);

      // if (currentEmployee.givenName === "") {
      //   setActiveStep(0);
      //   //dispatch(setSelectedEmployee(employees[0]));
      // } else {
      //   let indexOf = employees.findIndex(
      //     (e: Employee) => e._id === currentEmployee._id
      //   );
      //   //dispatch(setSelectedEmployee(employees[indexOf]));
      //   setActiveStep(indexOf);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const setChosenEmployee = (employee: Employee) => {
    dispatch(setSelectedEmployee(employee));
  };

  return (
    <>
      <Helmet>
        <title> {`${tt("candidates")} | ${tt("platform")}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundImage: "url('/bg5.jpg')",
          // backgroundRepeat: "no-repeat",
          backgroundSize: "fill",
        }}
      >
        <Container maxWidth="lg">
          {employees && employees.length > 0 ? (
            <Box>
              {selectedCompany && selectedCompany.displayName.length > 1 && (
                <Box
                  component={Paper}
                  elevation={3}
                  sx={{ display: "block", overflow: "hidden", width: "100%" }}
                >
                  <ChooseCandidate />
                  <BindKeyboardSwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    disableLazyLoading
                    ignoreNativeScroll
                  >
                    {employees.map((employee, index) => {
                      // setChosenEmployee(currentEmployee);
                      //console.log(activeStep);
                      return (
                        <div
                          key={index}
                          // dir={theme.direction === "rtl" ? "rtl" : "ltr"}
                          id={`emp_${activeStep}`}
                        >
                          {Math.abs(activeStep - index) <= 2 ? (
                            <>
                              {name === "basic" ? (
                                <BasicTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "basic2" ? (
                                <BasicTwoTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "basic3" ? (
                                <BasicThreeTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "entrance" ? (
                                <EntranceTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "akeem" ? (
                                <AkeemTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "cadres" ? (
                                <CadresTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "oxagon" ? (
                                <OxagonTwoTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "maleda" ? (
                                <MaledaTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "stars" ? (
                                <SkillStarTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : name === "sanad" ? (
                                <SanadTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              ) : (
                                <InterTemplate
                                  backgroundColor={backgroundColor}
                                  cvTemplate={cvTemplate}
                                  currentEmployee={employee}
                                  selectedCompany={selectedCompany}
                                />
                              )}
                            </>
                          ) : null}
                        </div>
                      );
                    })}
                  </BindKeyboardSwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {tt("next")}
                        <KeyboardArrowRight />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        <KeyboardArrowLeft />
                        {tt("back")}
                      </Button>
                    }
                  />
                </Box>
              )}
            </Box>
          ) : loading === "pending" ? (
            <Stack
              spacing={1}
              sx={{
                height: "92vh",
                pt: 1,
              }}
            >
              {/* {cvTemplate && (
                <BasicHeader
                  selectedCompany={selectedCompany}
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )} */}
              <Stack alignItems="center">
                <AuthSkeleton />
              </Stack>
            </Stack>
          ) : (
            <Stack
              spacing={1}
              sx={{
                height: "92vh",
                pt: 1,
              }}
            >
              {/* {cvTemplate && (
                <BasicHeader
                  selectedCompany={selectedCompany}
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )} */}
              <Stack alignItems="center">
                <Image
                  src="/No_Data.svg"
                  alt="No Data"
                  width={250}
                  height={120}
                  shift="top"
                  distance="2rem"
                  shiftDuration={320}
                  style={{
                    borderRadius: "50%",
                  }}
                />
                <Typography variant="h6">
                  {tt("no_candidates_found")}
                </Typography>
                <Typography variant="h6">{tt("candidate_selected")}</Typography>
              </Stack>
            </Stack>
          )}
          {success && <Toast severity="success">{success.message}</Toast>}
          {error && <Toast severity="error">{error.message}</Toast>}
          {/* {open && (
            <CustomDialog
              title="Share Link"
              isOpen={open}
              handleDialogClose={dialogClose}
            >
              <ShareCvs
                employeeIds={sharedEmployeeIds}
                handleDialogClose={dialogCloseVisa}
              />
            </CustomDialog>
          )}
          {openVisa && (
            <CustomDialog
              title="Select Candidate"
              isOpen={openVisa}
              handleDialogClose={dialogCloseVisa}
            >
              <VisaEntry
                id={visaSelectedEmployeeId}
                handleDialogClose={dialogCloseVisa}
              />
            </CustomDialog>
          )} */}
        </Container>
        {/* <AppBar
          color="primary"
          position="relative"
          sx={{ top: "auto", bottom: 0, mt: 2 }}
        >
          <ShadowStyled>
            <Toolbar sx={{ height: 64 }}>
              <Container maxWidth="lg">
                <Stack direction="row" alignItems="center">
                  <RouterLink to="/">
                    <Logo />
                  </RouterLink>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Stack
                    spacing={2}
                    sx={{
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 0,
                        ml: 2,
                      },
                    }}
                    direction="row"
                  >
                    <SupportChat />
                  </Stack>
                </Stack>
              </Container>
            </Toolbar>
          </ShadowStyled>
        </AppBar> */}
      </Box>
    </>
  );
}

export default PremiumTemplateCarousel;
