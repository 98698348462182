import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";
import { format } from "date-fns";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}

export const BasicReference = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginBottom: 0,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "13%" }}
            >
              {tt("referenceNumber", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "22%" }}
            >
              {currentEmployee.education?.yearCompleted as string}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("referenceNumber", "ar")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("applicationDate", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {template.cvLang === "en"
                ? format(
                    new Date(
                      currentEmployee.additionalData
                        ?.documentReceivedDate as Date
                    ),
                    "dd-MMM-yyyy"
                  )
                : new Date(
                    currentEmployee.additionalData?.documentReceivedDate as Date
                  ).toLocaleDateString("ar-SA", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%", textAlign: "right" }}
            >
              {tt("applicationDate", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const BasicReferenceEnglish = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginBottom: 0,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "13%" }}
            >
              {tt("referenceNumber", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "22%" }}
            >
              {currentEmployee.education?.yearCompleted as string}
            </StyledTableCellValueTemplate>

            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("applicationDate", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {format(
                new Date(
                  currentEmployee.additionalData?.documentReceivedDate as Date
                ),
                "dd-MMM-yyyy"
              )}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const BasicReferenceVer = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginBottom: 0,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "10%" }}
            >
              {tt("referenceNumber", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "28%" }}
            >
              {currentEmployee.education?.yearCompleted as string}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "12%" }}
            >
              {tt("referenceNumber", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "12%" }}
            >
              {tt("applicationDate", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "26%" }}
            >
              {template.cvLang === "en"
                ? format(
                    new Date(
                      currentEmployee.additionalData
                        ?.documentReceivedDate as Date
                    ),
                    "dd-MMM-yyyy"
                  )
                : new Date(
                    currentEmployee.additionalData?.documentReceivedDate as Date
                  ).toLocaleDateString("ar-SA", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "12%" }}
            >
              {tt("applicationDate", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const BasicReferenceDateOnly = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginBottom: 0,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {tt("applicationDate", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "60%" }}
            >
              {template.cvLang === "en"
                ? format(
                    new Date(
                      currentEmployee.additionalData
                        ?.documentReceivedDate as Date
                    ),
                    "dd-MMM-yyyy"
                  )
                : new Date(
                    currentEmployee.additionalData?.documentReceivedDate as Date
                  ).toLocaleDateString("ar-SA", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {tt("applicationDate", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const BasicReferenceDateOnlyHeader = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginBottom: 0,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {tt("applicationDate", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "60%" }}
            >
              {template.cvLang === "en"
                ? format(
                    new Date(
                      currentEmployee.additionalData
                        ?.documentReceivedDate as Date
                    ),
                    "dd-MMM-yyyy"
                  )
                : new Date(
                    currentEmployee.additionalData?.documentReceivedDate as Date
                  ).toLocaleDateString("ar-SA", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "20%" }}
            >
              {tt("applicationDate", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export const BasicReferenceOnly = ({ currentEmployee, template }: Props) => {
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginBottom: 0,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ textAlign: "left" }}
            >
              {tt("referenceNumber", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.education?.yearCompleted as string}
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ textAlign: "right" }}
            >
              {tt("referenceNumber", "ar")}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export const BasicReferenceOnlyEnglish = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginBottom: 0,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={{ ...template, bodyHeight: 52 }}
              sx={{ textAlign: "left" }}
            >
              {tt("referenceNumber", "en")}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.education?.yearCompleted as string}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
