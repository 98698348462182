import { MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import {
  selectPreference,
  setLanguage,
} from "../../features/preferences/preferencesSlice";
export type LocaleTypes = {
  lang: string;
  nativeName: string;
};
export default function Language() {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { lang } = useAppSelector(selectPreference);
  const langs = [
    { lang: "en", nativeName: "English", code: "US", icon: "usaFlag.png" },
    { lang: "ar", nativeName: "العربية", code: "SA", icon: "saudiFlag.png" },
  ];
  // { lang: "et", nativeName: "አማርኛ", code: "ET", icon: "ethiopiaFlag.png" },
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    setSelectedLanguage(lang);
  }, [lang]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    setLanguage(value);
    if (i18n.language === "ar") {
      document.body.setAttribute("dir", "rtl");
      theme.direction = "rtl";
    } else {
      document.body.setAttribute("dir", "ltr");
      theme.direction = "ltr";
    }
  };
  // const handleChange = () => {
  //   //dispatch(setMode(mode === "light" ? "dark" : "light"));
  //   if (i18n.language === "en") {
  //     i18n.changeLanguage("ar");
  //     document.body.setAttribute("dir", "rtl");
  //     theme.direction = "rtl";
  //   } else  {
  //     i18n.changeLanguage("en");
  //     document.body.setAttribute("dir", "ltr");
  //     theme.direction = "ltr";
  //   }
  // };
  return (
    // <Tooltip
    //   sx={{ mr: 0, ml: 1 }}
    //   title={`${i18n.language === "ar" ? "To English" : "To Arabic"}`}
    // >
    //   <IconButton
    //     onClick={handleChange}
    //     color="inherit"
    //     size="large"
    //     edge="end"
    //   >
    //     {i18n.language === "ar" ? (
    //       <Avatar
    //         alt="e"
    //         src={"/english.png"}
    //         sx={{
    //           height: 20,
    //           width: 20,
    //         }}
    //       />
    //     ) : (
    //       <Avatar
    //         alt="a"
    //         src={"/arabic.png"}
    //         sx={{
    //           height: 20,
    //           width: 20,
    //         }}
    //       />
    //     )}
    //   </IconButton>
    // </Tooltip>
    <Select
      value={selectedLanguage}
      onChange={handleChange}
      displayEmpty
      autoWidth
      variant="standard"
      sx={{
        color: "black",
        borderColor: "white",
        p: "6px 8px",
      }}
    >
      {langs.map((lang) => (
        <MenuItem key={lang.lang} value={lang.lang}>
          <img
            loading="lazy"
            width="30"
            src={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png 2x`}
            alt={lang.nativeName}
          />
          {lang.nativeName}
        </MenuItem>
      ))}
      {/* <img src={`/${lang.icon}`} alt="avatar" height={20} width={25} />
  {lang.nativeName} */}
    </Select>
  );
}
