import { PaletteMode } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  CompletionStatus,
  EmployeeStatus,
  LanguageExperience,
  MaritalStatus,
  Nationalities,
  ProfessionalTypes,
  ReligionTypes,
} from "../process/types/processTypes";

export type PreferencesState = {
  isDesktop: boolean;
  isDrawerOpen: boolean;
  isMobileDrawerOpen: boolean;
  isFilterOpen: boolean;
  isMiniMode: boolean;
  pageTitle: string;
  mode: PaletteMode;
  lang: string;
  locale: string;
  searchText: string | undefined;
  status: EmployeeStatus | undefined;
  nationality: Nationalities | undefined;
  maritalStatus: MaritalStatus | undefined;
  religion: ReligionTypes | undefined;
  profession: ProfessionalTypes | undefined;
  arabic: LanguageExperience | undefined;
  english: LanguageExperience | undefined;
  ageStart: number | undefined;
  ageEnd: number | undefined;
  experienceYears: number | undefined;
  agencyId: string | undefined;
  agentId: string | undefined;
  cocCompleted: CompletionStatus | undefined;
  medicalCompleted: CompletionStatus | undefined;
  biometricsCompleted: CompletionStatus | undefined;
  visaIssued: string | undefined;
  labourIssued: string | undefined;
  flightBooked: string | undefined;
};

const initialState: PreferencesState = {
  isDesktop: true,
  isDrawerOpen: false,
  isMiniMode: false,
  isMobileDrawerOpen: false,
  isFilterOpen: false,
  pageTitle: "Dashboard",
  mode: "light",
  lang: "en",
  locale: "en",
  searchText: "",
  status: undefined,
  nationality: undefined,
  maritalStatus: undefined,
  religion: undefined,
  profession: undefined,
  arabic: undefined,
  english: undefined,
  ageStart: undefined,
  ageEnd: undefined,
  experienceYears: undefined,
  agencyId: undefined,
  agentId: undefined,
  cocCompleted: undefined,
  medicalCompleted: undefined,
  biometricsCompleted: undefined,
  visaIssued: undefined,
  labourIssued: undefined,
  flightBooked: undefined,
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    toggleThis: (state, { payload }) => {
      switch (payload.type) {
        case "Drawer":
          state.isDrawerOpen = payload.newValue;
          break;
        case "Mini":
          state.isMiniMode = payload.newValue;
          localStorage.setItem("miniMode", payload.newValue);
          break;
        case "Mobile":
          state.isMobileDrawerOpen = payload.newValue;
          break;
        case "Filter":
          state.isFilterOpen = payload.newValue;
          break;
      }
    },

    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setNationality: (state, { payload }) => {
      state.nationality = payload;
    },
    setMaritalStatus: (state, { payload }) => {
      state.maritalStatus = payload;
    },
    setReligion: (state, { payload }) => {
      state.religion = payload;
    },
    setProfession: (state, { payload }) => {
      state.profession = payload;
    },
    setArabic: (state, { payload }) => {
      state.arabic = payload;
    },
    setEnglish: (state, { payload }) => {
      state.english = payload;
    },
    setAgeStart: (state, { payload }) => {
      state.ageStart = payload;
    },
    setAgeEnd: (state, { payload }) => {
      state.ageEnd = payload;
    },
    setExperienceYears: (state, { payload }) => {
      state.experienceYears = payload;
    },
    setLocalAgency: (state, { payload }) => {
      state.agencyId = payload;
    },
    setForeignAgent: (state, { payload }) => {
      state.agentId = payload;
    },
    setCocCompleted: (state, { payload }) => {
      state.cocCompleted = payload;
    },
    setMedicalCompleted: (state, { payload }) => {
      state.medicalCompleted = payload;
    },
    setBiometricsCompleted: (state, { payload }) => {
      state.biometricsCompleted = payload;
    },

    setMode: (state, { payload }) => {
      state.mode = payload;
      localStorage.setItem("mode", payload);
    },
    setLanguage: (state, { payload }) => {
      state.lang = payload;
      state.locale =
        payload === "ar" ? "ar-sa" : payload === "et" ? "am" : payload;
      localStorage.setItem("lang", payload);
    },

    changePageTitle: (state, { payload }) => {
      state.pageTitle = payload;
    },
  },
  extraReducers: {},
});

export const {
  toggleThis,
  changePageTitle,
  setSearchText,
  setStatus,
  setNationality,
  setMaritalStatus,
  setReligion,
  setProfession,
  setArabic,
  setEnglish,
  setAgeStart,
  setAgeEnd,
  setExperienceYears,
  setLocalAgency,
  setForeignAgent,
  setCocCompleted,
  setMedicalCompleted,
  setBiometricsCompleted,
  setMode,
  setLanguage,
} = preferencesSlice.actions;

export const selectPreference = (state: RootState) =>
  state.preferences as PreferencesState;

export default preferencesSlice.reducer;
