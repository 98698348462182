import { toCanvas } from "html-to-image";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createEmployee,
  fetchEmployees,
  getEmployee,
  resetSuccess,
  selectProcesses,
  setSelectedEmployee,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  Tooltip,
  useTheme,
  Stack,
  Box,
  Typography,
  IconButton,
  Fab,
  Container,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { GradientTemplate, LabelTemplate } from "./styles/table";
import Image from "mui-image";
import {
  ArrowBack,
  ArrowForward,
  Assignment,
  ChangeCircleOutlined,
  Download,
  List,
  MoreVert,
  Share,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import {
  Employee,
  EmployeeStatus,
  NotificationMessageTypes,
} from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import { changePageTitle } from "../../preferences/preferencesSlice";

import CustomDialog from "../../../components/modals/CustomDialog";
import InterTemplate from "./InterTemplate";
import BasicTemplate from "./BasicTemplate";
import jsPDF from "jspdf";
import { Company, CvTemplate } from "../../setups/types/setupTypes";
import { getCompany, selectSetups } from "../../setups/setupSlices";
import { CompanyFilter } from "../../../components/filter/CompanyFilter";

import OxagonTwoTemplate from "./OxagonTwoTemplate";
import MaledaTemplate from "./MaledaTemplate";
import BasicTwoTemplate from "./BasicTwoTemplate";
import BasicThreeTemplate from "./BasicThreeTemplate";
import EntranceTemplate from "./EntranceTemplate";
import AkeemTemplate from "./AkeemTemplate";
import CadresTemplate from "./CadresTemplate";
import SkillStarTemplate from "./SkillStarTemplate";
import SanadTemplate from "./SanadTemplate";
import ShareCvs from "../components/ShareCvs";
import { VisaEntry } from "../VisaEntry";
import { ClientTypes, Role } from "../../auth/types/authType";
import { selectAuth } from "../../auth/authSlice";
import { isPrivilegedTransaction } from "../../../utils/authUtils";
import Toast from "../../../components/Layout/Toast";
import { useTranslation } from "react-i18next";
import { ProcessStages } from "../components/ProcessStages";
import { addDays } from "date-fns";
import JewelTemplate from "./JewelTemplate";
import HaloolTemplate from "./HaloolTemplate";
import GoldenTemplate from "./GoldenTemplate";

function PremiumTemplate() {
  const { empId } = useParams() as {
    empId: string;
  };
  const dispatch = useAppDispatch();
  const { t: te } = useTranslation("enums");
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [openPremium, setOpen] = useState(false);
  const [openVisaPremium, setOpenVisa] = useState(false);
  const [sharedEmployeeIds, setSharedEmployeeIds] = useState<string[]>([]);
  const [visaSelectedEmployeeId, setVisaSelectedEmployeeId] =
    useState<string>("");
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [changeStatusSelectedEmployeeId, setChangeStatusSelectedEmployeeId] =
    useState<string>("");
  const { user } = useAppSelector(selectAuth);
  const [company, setCompany] = useState<Company>();

  const [name, setName] = useState("inter");
  const [numberOfPages, setNumberOfPages] = useState(1);

  const [cvLang, setCvLang] = useState("ar");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const [headFontColor, setHeadFontColor] = useState("#ffffff");
  const [headBgColor, setHeadBgColor] = useState(theme.palette.primary.main);
  const [labelFontColor, setLabelFontColor] = useState(
    theme.palette.primary.main
  );
  const [labelBgColor, setLabelBgColor] = useState("#ffffff");
  const [valueFontColor, setValueFontColor] = useState(
    theme.palette.secondary.main
  );
  const [valueBgColor, setValueBgColor] = useState("#ffffff");

  const [borderRadius, setBorderRadius] = useState("5px");
  const [borderStyle, setBorderStyle] = useState("solid");
  const [borderColor, setBorderColor] = useState("#ffffff");
  const [borderWidth, setBorderWidth] = useState(1);

  const [spacing, setSpacing] = useState(1);
  const [labelFontSize, setLabelFontSize] = useState(16);
  const [valueFontSize, setValueFontSize] = useState(18);
  const [labelFontWeight, setLabelFontWeight] = useState(900);
  const [valueFontWeight, setValueFontWeight] = useState(900);

  const [textTransform, setTextTransform] = useState("uppercase");
  const [headerHeight, setHeaderHeight] = useState(48);
  const [bodyHeight, setBodyHeight] = useState(36);

  const [addPassportHeader, setAddPassportHeader] = useState(false);
  const [useHeaderGradient, setUseHeaderGradient] = useState(false);
  const [useBodyGradient, setUseBodyGradient] = useState(false);

  const [headerShadow, setHeaderShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [bodyShadow, setBodyShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [photoShadow, setPhotoShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [passportShadow, setPassportShadow] = useState(
    "5px 5px rgba(0,0,0,0.14)"
  );

  const [qrLocationLink, setQrLocationLink] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrLocationWidth, setQrLocationWidth] = useState(120);
  const [qrLocationHeight, setQrLocationHeight] = useState(120);
  const [qrLocationImageUrl, setQrLocationImageUrl] =
    useState("/googleMaps.png");
  const [qrLocationMargin, setQrLocationMargin] = useState(0);

  const [qrMoreCvsLink, setQrMoreCvsLink] = useState(
    "https://cv.enjazplus.com"
  );
  const [qrMoreCvsWidth, setQrMoreCvsWidth] = useState(120);
  const [qrMoreCvsHeight, setQrMoreCvsHeight] = useState(120);
  const [qrMoreCvsImageUrl, setQrMoreCvsImageUrl] =
    useState("/cvPlusLogo2.png");
  const [qrMoreCvsMargin, setQrMoreCvsMargin] = useState(0);

  const [faceHeight, setFaceHeight] = useState("240px");
  const [faceWidth, setFaceWidth] = useState("100%");
  const [standHeight, setStandHeight] = useState("600px");
  const [standWidth, setStandWidth] = useState("400px");
  const [flagHeight, setFlagHeight] = useState("200px");
  const [flagWidth, setFlagWidth] = useState("100%");
  const [passportHeight, setPassportHeight] = useState("650px");
  const [passportWidth, setPassportWidth] = useState("700px");
  const [headBgColorAlpha, setHeadBgColorAlpha] = useState(1);
  const [headGrFirstColorAlpha, setHeadGrFirstColorAlpha] = useState(1);
  const [headGrSecondColorAlpha, setHeadGrSecondColorAlpha] = useState(1);
  const [labelBgColorAlpha, setLabelBgColorAlpha] = useState(1);
  const [oddGrFirstColorAlpha, setOddGrFirstColorAlpha] = useState(1);
  const [oddGrSecondColorAlpha, setOddGrSecondColorAlpha] = useState(1);
  const [valueBgColorAlpha, setValueBgColorAlpha] = useState(1);
  const [evenGrFirstColorAlpha, setEvenGrFirstColorAlpha] = useState(1);
  const [evenGrSecondColorAlpha, setEvenGrSecondColorAlpha] = useState(1);

  const [headerGradient, setHeaderGradient] = useState<GradientTemplate>({
    typeOfGradient: "radial-gradient",
    degree: "circle",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [evenGradient, setEvenGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });
  const [oddGradient, setOddGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });

  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    name,
    numberOfPages,
    cvLang,
    bgColor: backgroundColor,
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    headerGradient,
    evenGradient,
    oddGradient,
    addPassportHeader,
    useHeaderGradient,
    useBodyGradient,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  });

  useEffect(() => {
    setCvTemplate({
      name,
      numberOfPages,
      cvLang,
      bgColor: backgroundColor,
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
      borderColor,
      borderRadius,
      borderWidth,
      borderStyle,
      spacing,
      labelFontSize,
      valueFontSize,
      labelFontWeight,
      valueFontWeight,
      textTransform,
      headerHeight,
      bodyHeight,
      headerGradient,
      evenGradient,
      oddGradient,
      addPassportHeader,
      useHeaderGradient,
      useBodyGradient,
      headerShadow,
      bodyShadow,
      photoShadow,
      passportShadow,
      qrLocationLink,
      qrLocationWidth,
      qrLocationHeight,
      qrLocationImageUrl,
      qrLocationMargin,
      qrMoreCvsLink,
      qrMoreCvsWidth,
      qrMoreCvsHeight,
      qrMoreCvsImageUrl,
      qrMoreCvsMargin,
      faceHeight,
      faceWidth,
      standHeight,
      standWidth,
      flagHeight,
      flagWidth,
      passportHeight,
      passportWidth,
      headBgColorAlpha,
      headGrFirstColorAlpha,
      headGrSecondColorAlpha,
      labelBgColorAlpha,
      oddGrFirstColorAlpha,
      oddGrSecondColorAlpha,
      valueBgColorAlpha,
      evenGrFirstColorAlpha,
      evenGrSecondColorAlpha,
    });
  }, [
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    cvLang,
    headerGradient,
    evenGradient,
    oddGradient,
    useHeaderGradient,
    useBodyGradient,
    name,
    numberOfPages,
    backgroundColor,
    addPassportHeader,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  ]);

  const {
    selectedEmployee: currentEmployee,
    employeesWithCount: { employees },
    selectedVisa,
    // shortLinkHeader,
    success,
    error,
    loading,
  } = useAppSelector(selectProcesses);

  const { selectedCompany } = useAppSelector(selectSetups);

  const renderCvFormat = (cv: CvTemplate) => {
    //console.log(cv);
    setName(cv.name);
    setNumberOfPages(cv.numberOfPages);
    setCvLang(cv.cvLang);
    setBackgroundColor(cv.bgColor);
    setHeadFontColor(cv.headFontColor);
    setHeadBgColor(cv.headBgColor);
    setLabelFontColor(cv.labelFontColor);
    setLabelBgColor(cv.labelBgColor);
    setValueFontColor(cv.valueFontColor);
    setValueBgColor(cv.valueBgColor);

    setBorderColor(cv.borderColor);
    setBorderRadius(cv.borderRadius);
    setBorderWidth(cv.borderWidth);
    setBorderStyle(cv.borderStyle);
    setSpacing(cv.spacing);
    setLabelFontSize(cv.labelFontSize);
    setValueFontSize(cv.valueFontSize);
    setLabelFontWeight(cv.labelFontWeight);
    setValueFontWeight(cv.valueFontWeight);
    setTextTransform(cv.textTransform);
    setHeaderHeight(cv.headerHeight);
    setBodyHeight(cv.bodyHeight);

    setAddPassportHeader(cv.addPassportHeader);
    setUseHeaderGradient(cv.useHeaderGradient);
    setUseBodyGradient(cv.useBodyGradient);

    setHeaderShadow(cv.headerShadow);
    setBodyShadow(cv.bodyShadow);
    setPhotoShadow(cv.photoShadow);
    setPassportShadow(cv.passportShadow);

    setQrLocationLink(cv.qrLocationLink);
    setQrLocationWidth(cv.qrLocationWidth);
    setQrLocationHeight(cv.qrLocationHeight);
    setQrLocationImageUrl(cv.qrLocationImageUrl);
    setQrLocationMargin(cv.qrLocationMargin);

    setQrMoreCvsLink(cv.qrMoreCvsLink);
    setQrMoreCvsWidth(cv.qrMoreCvsWidth);
    setQrMoreCvsHeight(cv.qrMoreCvsHeight);
    setQrMoreCvsImageUrl(cv.qrMoreCvsImageUrl);
    setQrMoreCvsMargin(cv.qrMoreCvsMargin);

    setFaceHeight(cv.faceHeight);
    setFaceWidth(cv.faceWidth);
    setStandHeight(cv.standHeight);
    setStandWidth(cv.standWidth);
    setFlagHeight(cv.flagHeight);
    setFlagWidth(cv.flagWidth);
    setPassportHeight(cv.passportHeight);
    setPassportWidth(cv.passportWidth);

    setHeadBgColorAlpha(cv.headBgColorAlpha);
    setHeadGrFirstColorAlpha(cv.headGrFirstColorAlpha);
    setHeadGrSecondColorAlpha(cv.headGrSecondColorAlpha);
    setLabelBgColorAlpha(cv.labelBgColorAlpha);
    setOddGrFirstColorAlpha(cv.oddGrFirstColorAlpha);
    setOddGrSecondColorAlpha(cv.oddGrSecondColorAlpha);
    setValueBgColorAlpha(cv.valueBgColorAlpha);
    setEvenGrFirstColorAlpha(cv.evenGrFirstColorAlpha);
    setEvenGrSecondColorAlpha(cv.evenGrSecondColorAlpha);

    const headerGr = cv.headerGradient.split("_");
    if (headerGr.length === 6) {
      setHeaderGradient({
        typeOfGradient: headerGr[0],
        degree: headerGr[1],
        firstColor: headerGr[2],
        firstPercentage: headerGr[3],
        secondColor: headerGr[4],
        secondPercentage: headerGr[5],
      });
    }

    const evenGr = cv.evenGradient.split("_");
    if (evenGr.length === 6) {
      setEvenGradient({
        typeOfGradient: evenGr[0],
        degree: evenGr[1],
        firstColor: evenGr[2],
        firstPercentage: evenGr[3],
        secondColor: evenGr[4],
        secondPercentage: evenGr[5],
      });
    }
    const oddGr = cv.oddGradient.split("_");
    if (oddGr.length === 6) {
      setOddGradient({
        typeOfGradient: oddGr[0],
        degree: oddGr[1],
        firstColor: oddGr[2],
        firstPercentage: oddGr[3],
        secondColor: oddGr[4],
        secondPercentage: oddGr[5],
      });
    }
  };

  // useEffect(() => {
  //   if (currentEmployee) {
  //     if (
  //       currentEmployee.prospects &&
  //       currentEmployee.prospects[0] &&
  //       currentEmployee.prospects[0]._id
  //     ) {
  //       dispatch(getCompany(currentEmployee.prospects[0]._id));
  //     } else if (currentEmployee.owner && currentEmployee.owner._id) {
  //       dispatch(getCompany(currentEmployee.owner._id));
  //     }
  //   }
  // }, [dispatch, currentEmployee]);
  //&&
  //user.company.type === ClientTypes.ForeignAgent;

  useEffect(() => {
    if (user && user.company && user.company._id) {
      setCompany(user.company);
    }
  }, [dispatch, user]);

  // useEffect(() => {
  //   if (cvHeader && cvHeader._id) {
  //     //console.log(cvHeader);
  //     dispatch(getCompany(cvHeader._id));
  //   }
  // }, [dispatch, cvHeader]);

  // useEffect(() => {
  //   if (shortLinkHeader && shortLinkHeader._id) {
  //     dispatch(getCompany(shortLinkHeader._id));
  //   }
  // }, [dispatch, shortLinkHeader]);

  useEffect(() => {
    if (selectedCompany && selectedCompany.cvTemplate) {
      renderCvFormat(selectedCompany.cvTemplate);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (company && company._id) {
      //console.log(company);
      if (empId === "sulpvc") {
        //dispatch(changePageTitle("CV list"));

        dispatch(
          fetchEmployees({
            searchText: "",
            skip: 0,
            take: 500,
            status: EmployeeStatus.new,
          })
        );
      } else if (empId && empId.length === 24) {
        dispatch(getEmployee(empId));
      } else if (empId && empId.length > 24) {
        dispatch(getEmployee(empId.substring(0, 24)));
      }
      if (company._id) {
        //renderCvFormat(company.cvTemplate);
        dispatch(getCompany(company._id));
      } else {
        //renderCvFormat(currentEmployee.owner.cvTemplate);
        //console.log(selectedCompany);
      }
    }
  }, [dispatch, company]);

  useEffect(() => {
    // if (empId === "sulpvc") {
    //   dispatch(changePageTitle("CV list"));
    //   dispatch(
    //     fetchEmployees({
    //       searchText: "",
    //       skip: 0,
    //       take: 500,
    //       status: EmployeeStatus.new,
    //     })
    //   );
    // } else if (empId && empId.length > 10) {
    //   dispatch(getEmployee(empId));
    // }
    // if (user && user.company && user.company._id) {
    //   dispatch(getCompany(user.company._id));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, empId]);

  useEffect(() => {
    if (employees && employees.length > 0 && empId === "sulpvc") {
      // if (empId && empId.length > 10) {
      //   dispatch(getEmployee(empId));
      // } else {
      if (currentEmployee.givenName === "") {
        dispatch(setSelectedEmployee(employees[0]));
      } else {
        let indexOf = employees.findIndex(
          (e: Employee) => e._id === currentEmployee._id
        );
        dispatch(setSelectedEmployee(employees[indexOf]));
      }
      //}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const previousEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === 0) indexOf = employees.length - 1;
    else indexOf--;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };
  const nextEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === employees.length - 1) indexOf = 0;
    else indexOf++;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };

  const shareSingle = (employeeId: string) => {
    setSharedEmployeeIds([employeeId]);

    //dispatch(generateLink({ employeeIds: [employeeId] }));
    setOpen(true);
  };

  const selectCandidate = (employeeId: string) => {
    setVisaSelectedEmployeeId(employeeId);

    setOpenVisa(true);
  };

  const justSelect = () => {
    let values = currentEmployee;
    const currentDate = new Date();
    values = {
      ...values,
      status: EmployeeStatus.selected,
      notificationMessage: NotificationMessageTypes.selected as string,
      partner: company,
      selectionDueDate: addDays(currentDate, 3),
    };

    dispatch(createEmployee(values));
  };

  const deSelectCandidate = (employeeId: string) => {
    //console.log(employeeId);
    let values =
      currentEmployee && currentEmployee.givenName !== ""
        ? currentEmployee
        : employees.find((e) => e._id === employeeId);
    if (values) {
      values = {
        ...values,
        status: EmployeeStatus.new,
        notificationMessage: NotificationMessageTypes.deSelected as string,

        visa: { ...selectedVisa, visaNumber: "0000000000" },
      };
      //console.log(values);
      dispatch(createEmployee(values));
    }
  };
  const changeCandidateStatus = (employeeId: string) => {
    setChangeStatusSelectedEmployeeId(employeeId);
    setOpenChangeStatus(true);
  };
  const dialogCloseChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  const dialogClose = () => {
    dispatch(resetSuccess());
    setOpen(false);
  };
  const dialogCloseVisa = () => {
    dispatch(resetSuccess());
    setOpenVisa(false);
  };
  // useEffect(() => {
  //   if (success && selectedShortLink && selectedShortLink.link) {
  //     setOpen(true);
  //     setShortLink(selectedShortLink);
  //   }
  // }, [success, selectedShortLink]);

  const handleGeneratePdf = () => {
    const fileName = ("CV_" + currentEmployee.givenName) as string;

    if (numberOfPages === 1) {
      const doc = new jsPDF("p", "mm", "a4", false);
      toCanvas(document.getElementById("personalData") as HTMLElement)
        .then((canvas) => {
          //const imgWidth = canvas.width;
          //const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("image/png");
          doc.addImage({
            imageData: imgData,
            format: "PNG",
            x: 4,
            y: 4,
            width: 200,
            height: 290,
            alias: "alias1",
            compression: "FAST",
          });
          doc.save(`${fileName}.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const doc = new jsPDF("p", "mm", "a4", false);
      toCanvas(document.getElementById("personalData") as HTMLElement)
        .then((canvas) => {
          //const imgWidth = 200;
          //const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("image/png");
          doc.addImage({
            imageData: imgData,
            format: "PNG",
            x: 4,
            y: 4,
            width: 200,
            height: 290,
            alias: "alias1",
            compression: "FAST",
          });

          doc.addPage();
          toCanvas(document.getElementById("passportData") as HTMLElement)
            .then((canvas2) => {
              //const imgWidth2 = 100;
              //const imgHeight2 = (canvas.height * imgWidth2) / canvas2.width;
              const imgData2 = canvas2.toDataURL("image/png");
              //console.log(imgData);
              doc.addImage({
                imageData: imgData2,
                format: "PNG",
                x: 4,
                y: 4,
                width: 200,
                height: 290,
                alias: "alias2",
                compression: "FAST",
              });
              doc.save(`${fileName}.pdf`);
              // doc.save("download.pdf", { returnPromise: true })
              //   .then((pdfFile) => {

              // });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          //backgroundColor: "success.main",
          minHeight: "100%",
          py: 3,
        }}
      >
        {(employees && employees.length > 0) ||
        (currentEmployee && currentEmployee.givenName !== "") ? (
          <Box>
            <>
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{
                    "& > :not(style)": {
                      m: 0,
                      ml: 1,
                    },
                  }}
                >
                  {employees && employees.length > 0 && (
                    <Tooltip title={t("previous")} sx={{ mt: 30 }}>
                      <Fab component="span" color="primary">
                        <IconButton
                          color="inherit"
                          onClick={previousEmployee}
                          size="large"
                        >
                          <ArrowBack />
                        </IconButton>
                      </Fab>
                    </Tooltip>
                  )}

                  {!isSmallScreen && (
                    <>
                      <Tooltip title={t("showList")}>
                        <Button
                          color="secondary"
                          component={RouterLink}
                          variant="contained"
                          to="/app/employees"
                          size="small"
                        >
                          <List />
                        </Button>
                      </Tooltip>

                      <Tooltip title={t("downloadCv")}>
                        <Button
                          color="secondary"
                          onClick={handleGeneratePdf}
                          variant="contained"
                          size="small"
                        >
                          <Download />
                        </Button>
                      </Tooltip>

                      <Tooltip title={t("share")}>
                        <Button
                          color="secondary"
                          onClick={() =>
                            shareSingle(currentEmployee._id as string)
                          }
                          variant="contained"
                          size="small"
                        >
                          <Share />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Stack>

                <Typography
                  alignSelf="center"
                  justifyContent="center"
                  variant="h4"
                  color="white"
                  sx={{
                    backgroundColor: "primary.main",
                    p: 1,
                    borderRadius: "4px",
                  }}
                >
                  {currentEmployee && te(currentEmployee.status as string)}
                </Typography>

                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{
                    "& > :not(style)": {
                      m: 0,
                      ml: 1,
                    },
                  }}
                >
                  {currentEmployee &&
                    currentEmployee.status === EmployeeStatus.new &&
                    isPrivilegedTransaction(
                      user?.roles as Role[],
                      "SelectCandidates"
                    ) && (
                      <Tooltip title={t("only_select")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={justSelect}
                          size="small"
                        >
                          <ThumbUp />
                        </Button>
                      </Tooltip>
                    )}
                  {currentEmployee &&
                    (currentEmployee.status === EmployeeStatus.new ||
                      currentEmployee.status === EmployeeStatus.selected) &&
                    isPrivilegedTransaction(
                      user?.roles as Role[],
                      "SelectCandidates"
                    ) && (
                      <Tooltip title={t("addAgreement")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            selectCandidate(currentEmployee._id as string)
                          }
                          size="small"
                        >
                          <Assignment />
                        </Button>
                      </Tooltip>
                    )}
                  {currentEmployee &&
                    (currentEmployee.status === EmployeeStatus.contracted ||
                      currentEmployee.status === EmployeeStatus.selected) &&
                    isPrivilegedTransaction(
                      user?.roles as Role[],
                      "DeselectCandidates"
                    ) && (
                      <Tooltip title={t("deselect")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            deSelectCandidate(currentEmployee._id as string)
                          }
                          size="small"
                        >
                          <ThumbDown />
                        </Button>
                      </Tooltip>
                    )}
                  <Tooltip title={t("more")}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        changeCandidateStatus(currentEmployee._id as string)
                      }
                      size="small"
                    >
                      <MoreVert />
                    </Button>
                  </Tooltip>

                  {employees && employees.length > 0 && (
                    <Tooltip title={t("next")} sx={{ mt: 30 }}>
                      <Fab component="span" color="primary">
                        <IconButton
                          color="inherit"
                          onClick={nextEmployee}
                          size="large"
                        >
                          <ArrowForward />
                        </IconButton>
                      </Fab>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
              {user &&
                user.company &&
                user.company.type !== ClientTypes.ForeignAgent && (
                  <CompanyFilter setCompany={setCompany} includeAll={false} />
                )}
            </>
            {company && company.displayName.length > 1 && (
              <Box
                component={Paper}
                elevation={3}
                sx={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  mt: 1,
                }}
                id="printCV"
              >
                <div dir={theme.direction === "rtl" ? "ltr" : "ltr"}>
                  {name === "basic" ? (
                    <BasicTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "basic2" ? (
                    <BasicTwoTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "basic3" ? (
                    <BasicThreeTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "entrance" ? (
                    <EntranceTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "akeem" ? (
                    <AkeemTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "cadres" ? (
                    <CadresTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "oxagon" ? (
                    <OxagonTwoTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "maleda" ? (
                    <MaledaTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "stars" ? (
                    <SkillStarTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "sanad" ? (
                    <SanadTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "jewel" ? (
                    <JewelTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "halool" ? (
                    <HaloolTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : name === "golden" ? (
                    <GoldenTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  ) : (
                    <InterTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={company}
                    />
                  )}
                </div>
              </Box>
            )}
          </Box>
        ) : loading === "pending" ? (
          <AuthSkeleton />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Image
              src="/No_Data.svg"
              alt="No Data"
              width={250}
              height={120}
              shift="top"
              distance="2rem"
              shiftDuration={320}
              style={{
                borderRadius: "50%",
              }}
            />
            <Typography variant="h5">{t("no_candidates_found")}</Typography>
          </Box>
        )}
        {success && <Toast severity="success">{success.message}</Toast>}
        {error && <Toast severity="error">{error.message}</Toast>}
        {openPremium && (
          <CustomDialog
            title="Share Link"
            isOpen={openPremium}
            handleDialogClose={dialogClose}
          >
            <ShareCvs
              employeeIds={sharedEmployeeIds}
              handleDialogClose={dialogClose}
            />
          </CustomDialog>
        )}
        {openVisaPremium && (
          <CustomDialog
            title="Select Candidate"
            isOpen={openVisaPremium}
            handleDialogClose={dialogCloseVisa}
          >
            <VisaEntry
              currentEmployee={currentEmployee}
              handleDialogClose={dialogCloseVisa}
            />
          </CustomDialog>
        )}
        {openChangeStatus && (
          <CustomDialog
            title={t("more") + " " + t("data")}
            isOpen={openChangeStatus}
            handleDialogClose={dialogCloseChangeStatus}
          >
            <ProcessStages
              currentEmployee={currentEmployee}
              handleDialogClose={dialogCloseChangeStatus}
            />
          </CustomDialog>
        )}
      </Box>
    </Container>
  );
}

export default PremiumTemplate;
