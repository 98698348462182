import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Employee,
  EmployeeStatus,
  NotificationMessageTypes,
} from "../types/processTypes";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

import { ExpandMore, Flight, Save } from "@mui/icons-material";
import {
  getEmployee,
  createEmployee,
  selectProcesses,
  setSelectedEmployee,
} from "../processSlices";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectPreference } from "../../preferences/preferencesSlice";
import { DateTimePicker } from "@mui/x-date-pickers";
import { selectAuth } from "../../auth/authSlice";
import Toast from "../../../components/Layout/Toast";
import { isPrivilegedTransaction } from "../../../utils/authUtils";
import { Role } from "../../auth/types/authType";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";

interface CurrentStatusProps {
  currentEmployee?: Employee;
  handleDialogClose: Function;
}
export const ProcessStages = ({
  currentEmployee,
  handleDialogClose,
}: CurrentStatusProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { locale } = useAppSelector(selectPreference);

  const [status, setStatus] = useState(EmployeeStatus.departed);

  // const [cocCompleted, setCocCompleted] = useState(false);
  // const [medicalCompleted, setMedicalCompleted] = useState(false);
  // const [embassyIssued, setEmbassyIssued] = useState(false);
  // const [labourIssued, setLabourIssued] = useState(false);
  // const [flightBooked, setFlightBooked] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<EmployeeStatus>(
    EmployeeStatus.contracted
  );

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);

  const {
    selectedEmployee,
    error: changeFailed,
    success: statusChanged,
  } = useAppSelector(selectProcesses);

  useEffect(() => {
    if (
      currentEmployee &&
      currentEmployee._id &&
      currentEmployee._id.length > 10
    ) {
      dispatch(setSelectedEmployee(currentEmployee));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentEmployee]);
  useEffect(() => {
    if (
      user &&
      isPrivilegedTransaction(user?.roles as Role[], "ChangeEmployeeStatus")
    ) {
      setChangeStatus(true);
    } else setChangeStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.owner) {
      setCurrentStatus(selectedEmployee.status as EmployeeStatus);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (statusChanged) {
      handleDialogClose();
    }
  }, [statusChanged]);

  const onChangeStatus = (value: ChangeEvent<HTMLInputElement>) => {
    setStatus(value.target.value as EmployeeStatus);
  };

  // useEffect(() => {
  //   if (currentStatus) {
  //     //console.log(currentStatus);
  //     switch (currentStatus) {
  //       case EmployeeStatus.medical_process:
  //         checkBoxes(1);
  //         break;
  //       case EmployeeStatus.visa_issued:
  //         checkBoxes(2);
  //         break;

  //       case EmployeeStatus.labour_issued:
  //         checkBoxes(3);
  //         break;

  //       case EmployeeStatus.flight_booked:
  //         checkBoxes(4);
  //         break;
  //     }
  //   }
  // }, [currentStatus]);
  // const checkBoxes = (index2: number) => {
  //   const index = index2 - 1;
  //   // if (index >= 0) setCocCompleted(true);
  //   // if (index >= 1) setMedicalCompleted(true);
  //   // if (index >= 2) setEmbassyIssued(true);
  //   // if (index >= 3) setLabourIssued(true);
  //   // if (index >= 4) setFlightBooked(true);
  // };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      //console.log(index);
      setCurrentIndex(index);
      if (event.target.checked) {
        setIsChecked(true);
        // if (index >= 0) setCocCompleted(true);
        // if (index >= 1) setMedicalCompleted(true);
        // if (index >= 2) setEmbassyIssued(true);
        // if (index >= 3) setLabourIssued(true);
        // if (index >= 4) setFlightBooked(true);
      } else {
        setIsChecked(false);
        // if (index <= 0) setCocCompleted(false);
        // if (index <= 1) setMedicalCompleted(false);
        // if (index <= 2) setEmbassyIssued(false);
        // if (index <= 3) setLabourIssued(false);
        // if (index <= 4) setFlightBooked(false);
      }
    };
  useEffect(() => {
    // if (isChecked) {
    //   switch (currentIndex) {
    //     case 0:
    //       setCurrentStatus(EmployeeStatus.coc_process);
    //       break;
    //     case 1:
    //       setCurrentStatus(EmployeeStatus.medical_process);
    //       break;
    //     case 2:
    //       setCurrentStatus(EmployeeStatus.visa_issued);
    //       break;
    //     case 3:
    //       setCurrentStatus(EmployeeStatus.labour_issued);
    //       break;
    //     case 4:
    //       setCurrentStatus(EmployeeStatus.flight_booked);
    //       break;
    //   }
    // } else {
    //   switch (currentIndex) {
    //     case 0:
    //       setCurrentStatus(EmployeeStatus.contracted);
    //       break;
    //     case 1:
    //       setCurrentStatus(EmployeeStatus.coc_process);
    //       break;
    //     case 2:
    //       setCurrentStatus(EmployeeStatus.medical_process);
    //       break;
    //     case 3:
    //       setCurrentStatus(EmployeeStatus.visa_issued);
    //       break;
    //     case 4:
    //       setCurrentStatus(EmployeeStatus.labour_issued);
    //       break;
    //   }
    // }
  }, [isChecked, currentIndex]);

  return (
    <Box
      alignItems="center"
      sx={{
        m: { xs: 0, md: 2 },
        px: { xs: 0, md: 2 },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={selectedEmployee as Employee}
        // validationSchema={createEmployeeSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);

          if (values.visa) {
            values = {
              ...values,
              status: status,
            };
          }
          //console.log(values);
          dispatch(createEmployee(values));
        }}
      >
        {(props: FormikProps<Employee>) => (
          <Form>
            <Grid container spacing={2} sx={{ px: 1, pt: 2 }}>
              <Grid container item xs={12} spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                  <FormikTextField
                    formikKey="givenName"
                    label={t("candidate") + " " + t("fullName")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    formikKey="visa.visaNumber"
                    label={t("visa_number")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    formikKey="visa.passportNumber"
                    label={t("id_number")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    formikKey="visa.fullName"
                    label={t("full_name")}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{ marginBottom: "0" }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.cocCompleted}
                        name="cocCompleted"
                        onChange={(value) => {
                          props.setFieldValue(
                            "cocCompleted",
                            value.target.checked,
                            true
                          );
                        }}
                      />
                    }
                    label={te("coc")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{
                      marginBottom: "0",
                    }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.medicalCompleted}
                        name="medicalCompleted"
                        onChange={(value) => {
                          props.setFieldValue(
                            "medicalCompleted",
                            value.target.checked,
                            true
                          );
                        }}
                      />
                    }
                    label={te("medical")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{
                      marginBottom: "0",
                    }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.biometricsCompleted}
                        name="biometricsCompleted"
                        onChange={(value) => {
                          props.setFieldValue(
                            "biometricsCompleted",
                            value.target.checked,
                            true
                          );
                        }}
                      />
                    }
                    label={te("biometrics")}
                  />
                </Grid>
                {selectedEmployee && selectedEmployee.visa && (
                  <Grid item xs={12}>
                    <Accordion>
                      <StyledAccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h6" component="div">
                          Change Current Status
                        </Typography>
                      </StyledAccordionSummary>

                      <AccordionDetails>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid item xs={12}>
                            <TextField
                              id="statuses"
                              select
                              defaultValue="all"
                              label={"Current Status"}
                              onChange={onChangeStatus}
                              value={status}
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              {Object.keys(EmployeeStatus)
                                .filter(
                                  (st) =>
                                    st === EmployeeStatus.contracted ||
                                    st === EmployeeStatus.visa_issued ||
                                    st === EmployeeStatus.labour_issued ||
                                    st === EmployeeStatus.flight_booked ||
                                    st === EmployeeStatus.departed ||
                                    st === EmployeeStatus.lost ||
                                    st === EmployeeStatus.returned ||
                                    st === EmployeeStatus.has_complain ||
                                    st === EmployeeStatus.contract_completed ||
                                    st === EmployeeStatus.other
                                )
                                .map((option) => (
                                  <MenuItem
                                    key={option}
                                    value={option as string}
                                  >
                                    {te(option.toLowerCase())}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                          {status &&
                            status !== EmployeeStatus.contracted &&
                            status !== EmployeeStatus.visa_issued &&
                            status !== EmployeeStatus.labour_issued && (
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={locale}
                                >
                                  <DateTimePicker
                                    label={"Flight Date and Time"}
                                    views={[
                                      "year",
                                      "month",
                                      "day",
                                      "hours",
                                      "minutes",
                                    ]}
                                    minDate={new Date("2023-01-01")}
                                    disableMaskedInput
                                    inputFormat="DD-MMM-YYYY hh:mm A"
                                    value={props.values.flight?.flightDateTime}
                                    onChange={(value) => {
                                      props.setFieldValue(
                                        "flight.flightDateTime",
                                        value,
                                        true
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="flightDateTime"
                                        sx={{
                                          mt: 0,
                                        }}
                                        fullWidth
                                        size="small"
                                        disabled
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            )}
                          <Grid item xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={"Status Date"}
                                views={["year", "month", "day"]}
                                minDate={new Date("2023-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.currentStatusDate}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "currentStatusDate",
                                    value,
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="currentStatusDate"
                                    sx={{
                                      mt: 1,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="currentStatusRemark"
                              label={t("status") + " " + t("remark")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {/* <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{ marginBottom: "0" }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.visaIssued}
                        name="embassyIssued"
                        onChange={(value) => {
                          props.setFieldValue(
                            "visaIssued",
                            value.target.checked,
                            true
                          );
                          if (value.target.checked) {
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.visa_issued
                            );
                            props.setFieldValue(
                              "notificationMessage",
                              NotificationMessageTypes.visa_issued as string
                            );
                          } else
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.contracted
                            );
                        }}
                      />
                    }
                    label={te("visa_issued")}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{ marginBottom: "0" }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.labourIssued}
                        name="labourIssued"
                        onChange={(value) => {
                          props.setFieldValue(
                            "labourIssued",
                            value.target.checked,
                            true
                          );
                          if (value.target.checked) {
                            props.setFieldValue(
                              "notificationMessage",
                              NotificationMessageTypes.labour_issued as string
                            );
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.labour_issued
                            );
                          } else
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.visa_issued
                            );
                        }}
                      />
                    }
                    label={te("labour_issued")}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    textAlign: theme.direction === "ltr" ? "left" : "right",
                  }}
                >
                  <FormControlLabel
                    style={{ marginBottom: "0" }}
                    disabled={!changeStatus}
                    control={
                      <Switch
                        color="secondary"
                        checked={props.values.ticketBooked}
                        name="ticketBooked"
                        onChange={(value) => {
                          props.setFieldValue(
                            "ticketBooked",
                            value.target.checked,
                            true
                          );
                          if (value.target.checked) {
                            props.setFieldValue(
                              "notificationMessage",
                              NotificationMessageTypes.flight_booked as string
                            );
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.flight_booked
                            );
                          } else
                            props.setFieldValue(
                              "status",
                              EmployeeStatus.labour_issued
                            );
                        }}
                      />
                    }
                    label={te("flight_booked")}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  visibility={props.values.ticketBooked ? "visible" : "hidden"}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <DateTimePicker
                      label={"Flight Date and Time"}
                      views={["year", "month", "day", "hours", "minutes"]}
                      minDate={new Date("2023-01-01")}
                      disableMaskedInput
                      inputFormat="DD-MMM-YYYY hh:mm A"
                      value={props.values.flight?.flightDateTime}
                      onChange={(value) => {
                        props.setFieldValue(
                          "flight.flightDateTime",
                          value,
                          true
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="flightDateTime"
                          sx={{
                            mt: 0,
                          }}
                          fullWidth
                          size="small"
                          disabled
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                    disabled={!changeStatus}
                    sx={{ mt: 1, width: "100%" }}
                  >
                    <Save />
                    {t("save") + " " + t("status")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <br />
            {statusChanged && (
              <Toast severity="success">{statusChanged.message}</Toast>
            )}
          </Form>
        )}
      </Formik>
      {changeFailed && <Toast severity="error">{changeFailed.message}</Toast>}
    </Box>
  );
};
