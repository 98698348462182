export const en = [
  { key: "sex", value: "Sex" },
  { key: "male", value: "Male" },
  { key: "female", value: "Female" },
  { key: "religion", value: "Religion" },
  { key: "muslim", value: "Muslim" },
  { key: "christian", value: "Christian" },
  { key: "non_muslim", value: "Non-Muslim" },
  { key: "other_religion", value: "Other Religion" },
  { key: "maritalStatus", value: "Marital Status" },
  { key: "single", value: "Single" },
  { key: "married", value: "Married" },
  { key: "divorced", value: "Divorced" },
  { key: "widow", value: "Widow" },
  { key: "separated", value: "Separated" },
  { key: "language", value: "Language" },
  { key: "language_knowledge", value: "Language Knowledge" },
  { key: "arabic", value: "Arabic" },
  { key: "english", value: "English" },
  { key: "poor", value: "None" },
  { key: "poor2", value: "Poor" },
  { key: "fair", value: "Fair" },
  { key: "average", value: "Average" },
  { key: "good", value: "Good" },
  { key: "fluent", value: "Fluent" },
  { key: "applied", value: "Applied" },
  { key: "profession", value: "Profession" },
  { key: "housemaid", value: "Housemaid" },
  { key: "house_worker", value: "House Worker" },
  { key: "driver", value: "Driver" },
  { key: "baby_sitter", value: "Baby Sitter" },
  { key: "nanny", value: "Nanny" },
  { key: "cooker", value: "Cooker" },
  { key: "nurse", value: "Nurse" },
  { key: "farm_worker", value: "Farm Worker" },
  { key: "other_profession", value: "Other Profession" },
  { key: "contractPeriod", value: "Contract Period" },
  { key: "forYears", value: "For Years" },
  { key: "period", value: "Period" },
  { key: "one", value: "1 Year" },
  { key: "two", value: "2 Years" },
  { key: "three", value: "3 Years" },
  { key: "four", value: "4 Years" },
  { key: "five", value: "5 Years" },
  { key: "six", value: "6 Years" },
  { key: "seven", value: "7 Years" },
  { key: "eight", value: "8 Years" },
  { key: "nine", value: "9 Years" },
  { key: "ten", value: "10 Years" },
  { key: "complexion", value: "Complexion" },
  { key: "acceptable", value: "Acceptable" },
  { key: "brown", value: "Brown" },
  { key: "black", value: "Black" },
  { key: "blue", value: "Blue" },
  { key: "ageCategory", value: "Age Category" },
  { key: "bet2125", value: "Between 21 & 25" },
  { key: "bet2630", value: "Between 26 & 30" },
  { key: "bet3135", value: "Between 31 & 35" },
  { key: "bet3640", value: "Between 36 & 40" },
  { key: "bet4145", value: "Between 41 & 45" },
  { key: "country", value: "Country" },
  { key: "destination", value: "Destination" },
  { key: "ethiopia", value: "Ethiopia" },
  { key: "kenya", value: "Kenya" },
  { key: "sudan", value: "Sudan" },
  { key: "eritrea", value: "Eritrea" },
  { key: "somalia", value: "Somalia" },
  { key: "addis_ababa", value: "Addis Ababa" },
  { key: "saudi_arabia", value: "Saudi Arabia" },
  { key: "bahrain", value: "Bahrain" },
  { key: "lebanon", value: "Lebanon" },
  { key: "oman", value: "Oman" },
  { key: "qatar", value: "Qatar" },
  { key: "kuwait", value: "Kuwait" },
  { key: "jordan", value: "Jordan" },
  { key: "syria", value: "Syria" },
  { key: "oman", value: "Oman" },
  { key: "uae", value: "UAE" },
  { key: "yemen", value: "Yemen" },
  { key: "previousNationality", value: "Previous Nationality" },
  { key: "nationality", value: "Nationality" },
  { key: "ethiopian", value: "Ethiopian" },
  { key: "kenyan", value: "Kenyan" },
  { key: "yemeni", value: "Yemeni" },
  { key: "sudanese", value: "Sudanese" },
  { key: "somali", value: "Somali" },
  { key: "eritrean", value: "Eritrean" },
  { key: "skills", value: "Skills" },
  { key: "washing_dishes", value: "Washing" },
  { key: "washing_ironing_clothes", value: "Ironing" },
  { key: "dusting_cleaning", value: "Cleaning" },
  { key: "baby_sitting", value: "Baby Sitting" },
  { key: "children_care", value: "Children Care" },
  { key: "elderly_care", value: "Elderly Care" },
  { key: "cooking", value: "Cooking" },
  { key: "arabic_cooking", value: "Arabic Cooking" },
  { key: "tutoring_children", value: "Tutoring Children" },
  { key: "tutor", value: "Tutor" },
  { key: "sewing", value: "Sewing" },
  { key: "computer", value: "Computer" },
  { key: "decorating", value: "Decorating" },
  { key: "driving", value: "Driving" },
  { key: "other_skills", value: "Other Skills" },
  { key: "willing_to_learn", value: "Willing to Learn" },
  { key: "currency", value: "Currency" },
  { key: "sarLONG", value: "Saudi Riyal" },
  { key: "saudi_riyal", value: "SAR" },
  { key: "qatar_riyal", value: "Qatar Riyal" },
  { key: "qarShort", value: "QAR" },
  { key: "kwdLong", value: "Kuwait Dinar" },
  { key: "kuwait_dinar", value: "KD" },
  { key: "aedLong", value: "UAE Dirham" },
  { key: "emirate_dirham", value: "ED" },
  { key: "jodLong", value: "Jordan Dinar" },
  { key: "jordan_dinar", value: "JoD" },
  { key: "lbnLong", value: "Lebanon Lire" },
  { key: "lebanon_lire", value: "LL" },
  { key: "education_level", value: "Education" },
  { key: "living_town", value: "Living Town" },
  { key: "grade_eight_complete", value: "8th" },
  { key: "secondary_complete", value: "Secondary Complete" },
  { key: "vocational_complete", value: "Vocational Complete" },
  { key: "college_complete", value: "College Complete" },
  { key: "post_graduate", value: "Post Graduate" },
  { key: "other_education", value: "Other Education" },
  { key: "passport", value: "Passport Details" },
  { key: "passportNo", value: "Passport No" },
  { key: "photo", value: "Photo" },
  { key: "id_card", value: "Id Card" },
  { key: "emergency_person_photo", value: "Contact Photo" },
  { key: "emergency_person_id_card", value: "Contact Id Card" },
  { key: "finger_print", value: "Finger Print" },
  { key: "grade_eight_certificate", value: "Grade 8 Certificate" },
  { key: "coc_certificate", value: "Coc Certificate" },
  { key: "insurance", value: "Insurance" },
  { key: "abroad_job_agreement", value: "Job Agreement" },
  { key: "trip_orientation", value: "Trip Orientation" },
  { key: "currentStatus", value: "Current Status" },
  { key: "all", value: "All" },
  { key: "new", value: "Available" },
  { key: "selected", value: "Selected" },
  { key: "contracted", value: "Contracted" },
  { key: "under_process", value: "Under Process" },
  { key: "medical", value: "Medical" },
  { key: "contract_process", value: "Contract Approval" },
  { key: "medical_process", value: "On Medical" },
  { key: "visa_issued", value: "Visa Issued" },
  { key: "ossc_process", value: "On Biometrics" },
  { key: "labour_issued", value: "LMIS Issued" },
  { key: "flight_process", value: "Ticketing" },
  { key: "flight_booked", value: "Flight Booked" },
  { key: "departed", value: "Departed" },
  { key: "on_good_condition", value: "Working Well" },
  { key: "discontinued", value: "Process Discontinued" },
  { key: "visa_canceled", value: "Visa Canceled" },
  { key: "lost", value: "Lost/Run Away" },
  { key: "returned", value: "Returned Back" },
  { key: "has_complain", value: "Has Complaint" },
  { key: "contract_completed", value: "Contract Completed" },
  { key: "work", value: "Work" },
  { key: "visit", value: "Visit" },
  { key: "transit", value: "Transit" },
  { key: "residence", value: "Residence" },
  { key: "umrah", value: "Umrah" },
  { key: "hajj", value: "Hajj" },
  { key: "other", value: "Other" },
  { key: "diplomacy", value: "Diplomacy" },
  { key: "dashboard", value: "Dashboard" },
  { key: "platform", value: "CvPlus" },
  { key: "enjaz+", value: "CvPlus" },
  { key: "candidates", value: "Candidates" },
  { key: "epro", value: "Employee Office" },
  { key: "pro", value: "Saudi Office" },
  { key: "cv", value: "CVs" },
  { key: "chatOnWhatsApp", value: "Chat On WhatsApp" },
  { key: "support", value: "Support" },
  { key: "statusTracking", value: "Status" },
  { key: "fullName", value: "Full Name" },
  { key: "name", value: "Name" },
  { key: "givenName", value: "Given Name" },
  { key: "surName", value: "Sur Name" },
  { key: "passportNumber", value: "Passport Number" },
  { key: "placeOfIssue", value: "Place Of Issue" },
  { key: "dateOfIssue", value: "Issue Date" },
  { key: "dateOfExpiry", value: "Expiry Date" },
  { key: "dateOfBirth", value: "Date Of Birth" },
  { key: "placeOfBirth", value: "Place Of Birth" },
  { key: "detailsOfApplication", value: "Personal Detail" },
  { key: "workInformation", value: "Work Information" },
  { key: "employmentApplication", value: "Employment Application" },
  { key: "referenceNumber", value: "Ref No." },
  { key: "applicationDate", value: "App. Date" },
  { key: "personalPhoto", value: "Personal Photo" },
  { key: "passportPhoto", value: "Passport Photo" },
  { key: "numberOfChildren", value: "No of Children" },
  { key: "workExperience", value: "Work Experience" },
  { key: "firstTimer", value: "First Timer" },
  { key: "addNew", value: "Add New" },
  { key: "save", value: "Save" },
  { key: "employee", value: "Employee" },
  { key: "data", value: "Data" },
  { key: "back", value: "Back" },
  { key: "next", value: "Next" },
  { key: "home", value: "Home" },
  { key: "shareByWhatsApp", value: "Share By WhatsApp" },
  { key: "shareByEmail", value: "Share By Email" },
  { key: "share", value: "Share" },
  { key: "link", value: "Link" },
  { key: "shareLinks", value: "Share Links" },
  { key: "employees", value: "Employees" },
  { key: "email", value: "Email" },
  { key: "submit", value: "Submit" },
  { key: "complaints", value: "Complaints" },
  { key: "visa", value: "Visa" },
  { key: "visas", value: "Visas" },
  { key: "processes", value: "Processes" },
  { key: "process", value: "Process" },
  { key: "embassy", value: "Embassy" },
  { key: "flight", value: "Flight" },
  { key: "molsa", value: "Molsa" },
  { key: "actions", value: "Actions" },
  { key: "numberOf", value: "Number Of" },
  { key: "no.", value: "No." },
  { key: "yes", value: "Yes" },
  { key: "no", value: "No" },
  { key: "doNot", value: "Do not" },
  { key: "number", value: "Number" },
  { key: "perPage", value: "Per Page" },
  { key: "list", value: "List" },
  { key: "brief", value: "List" },
  { key: "detail", value: "Detail" },
  { key: "aboutUs", value: "About Us" },
  { key: "refresh", value: "Refresh" },
  { key: "search", value: "Search" },
  { key: "search...", value: "Search..." },
  { key: "between", value: "Between" },
  { key: "and", value: "and" },
  { key: "from", value: "from" },
  { key: "to", value: "to" },
  { key: "age", value: "Age" },
  { key: "weight", value: "Weight" },
  { key: "kg", value: "Kg" },
  { key: "height", value: "Height" },
  { key: "meter", value: "Meter" },
  { key: "salary", value: "Salary" },
  { key: "remarks", value: "Remarks" },
  { key: "goodLooking", value: "Good Looking" },
  { key: "readingAndWriting", value: "Reading and Writing" },
  { key: "telephone", value: "Telephone" },
  { key: "employee_telephone", value: "Mobile No." },
  { key: "contact_name", value: "Contact Name" },
  { key: "contact_telephone", value: "Contact No." },
  { key: "year_graduated", value: "Year Graduated" },
  { key: "graduated_school", value: "Name of School" },
  { key: "alternateTelephone", value: "Alternate Telephone" },
  { key: "city", value: "City" },
  { key: "address", value: "Address" },
  { key: "condition", value: "Condition" },
  { key: "sponsor", value: "Sponsor" },
  { key: "employer", value: "Employer" },
  { key: "trainings", value: "Trainings" },
  { key: "videos", value: "Videos" },
  { key: "links", value: "Links" },

  { key: "afghan", value: "Afghan" },
  { key: "albanian", value: "Albanian" },
  { key: "algerian", value: "Algerian" },
  { key: "american", value: "American" },
  { key: "andorran", value: "Andorran" },
  { key: "angolan", value: "Angolan" },
  { key: "antiguans", value: "Antiguans" },
  { key: "argentinean", value: "Argentinean" },
  { key: "armenian", value: "Armenian" },
  { key: "australian", value: "Australian" },
  { key: "austrian", value: "Austrian" },
  { key: "azerbaijani", value: "Azerbaijani" },
  { key: "bahamian", value: "Bahamian" },
  { key: "bahraini", value: "Bahraini" },
  { key: "bangladeshi", value: "Bangladeshi" },
  { key: "barbadian", value: "Barbadian" },
  { key: "barbudans", value: "Barbudans" },
  { key: "batswana", value: "Batswana" },
  { key: "belarusian", value: "Belarusian" },
  { key: "belgian", value: "Belgian" },
  { key: "belizean", value: "Belizean" },
  { key: "beninese", value: "Beninese" },
  { key: "bhutanese", value: "Bhutanese" },
  { key: "bolivian", value: "Bolivian" },
  { key: "bosnian", value: "Bosnian" },
  { key: "brazilian", value: "Brazilian" },
  { key: "british", value: "British" },
  { key: "bruneian", value: "Bruneian" },
  { key: "bulgarian", value: "Bulgarian" },
  { key: "burkinabe", value: "Burkinabe" },
  { key: "burmese", value: "Burmese" },
  { key: "burundian", value: "Burundian" },
  { key: "cambodian", value: "Cambodian" },
  { key: "cameroonian", value: "Cameroonian" },
  { key: "canadian", value: "Canadian" },
  { key: "capeVerdean", value: "CapeVerdean" },
  { key: "centralAfrican", value: "CentralAfrican" },
  { key: "chadian", value: "Chadian" },
  { key: "chilean", value: "Chilean" },
  { key: "chinese", value: "Chinese" },
  { key: "colombian", value: "Colombian" },
  { key: "comoran", value: "Comoran" },
  { key: "congolese", value: "Congolese" },
  { key: "costaRican", value: "CostaRican" },
  { key: "croatian", value: "Croatian" },
  { key: "cuban", value: "Cuban" },
  { key: "cypriot", value: "Cypriot" },
  { key: "czech", value: "Czech" },
  { key: "danish", value: "Danish" },
  { key: "djibouti", value: "Djibouti" },
  { key: "dominican", value: "Dominican" },
  { key: "dutch", value: "Dutch" },
  { key: "eastTimorese", value: "East Timorese" },
  { key: "ecuadorean", value: "Ecuadorean" },
  { key: "egyptian", value: "Egyptian" },
  { key: "egypt", value: "Egypt" },
  { key: "emirian", value: "Emirian" },
  { key: "equatorialGuinean", value: "Equatorial Guinean" },
  { key: "estonian", value: "Estonian" },
  { key: "fijian", value: "Fijian" },
  { key: "filipino", value: "Filipino" },
  { key: "finnish", value: "Finnish" },
  { key: "french", value: "French" },
  { key: "gabonese", value: "Gabonese" },
  { key: "gambian", value: "Gambian" },
  { key: "georgian", value: "Georgian" },
  { key: "german", value: "German" },
  { key: "ghanaian", value: "Ghanaian" },
  { key: "greek", value: "Greek" },
  { key: "grenadian", value: "Grenadian" },
  { key: "guatemalan", value: "Guatemalan" },
  { key: "guineaBissauan", value: "Guinea Bissauan" },
  { key: "guinean", value: "Guinean" },
  { key: "guyanese", value: "Guyanese" },
  { key: "haitian", value: "Haitian" },
  { key: "herzegovinian", value: "Herzegovinian" },
  { key: "honduran", value: "Honduran" },
  { key: "hungarian", value: "Hungarian" },
  { key: "icelander", value: "Icelander" },
  { key: "indian", value: "Indian" },
  { key: "indonesian", value: "Indonesian" },
  { key: "iranian", value: "Iranian" },
  { key: "iraqi", value: "Iraqi" },
  { key: "irish", value: "Irish" },
  { key: "italian", value: "Italian" },
  { key: "ivorian", value: "Ivorian" },
  { key: "jamaican", value: "Jamaican" },
  { key: "japanese", value: "Japanese" },
  { key: "jordanian", value: "Jordanian" },
  { key: "kazakhstani", value: "Kazakhstani" },
  { key: "kittianAndNevisian", value: "Kittian and Nevisian" },
  { key: "kuwaiti", value: "Kuwaiti" },
  { key: "kyrgyz", value: "Kyrgyz" },
  { key: "laotian", value: "Laotian" },
  { key: "latvian", value: "Latvian" },
  { key: "lebanese", value: "Lebanese" },
  { key: "liberian", value: "Liberian" },
  { key: "libyan", value: "Libyan" },
  { key: "liechtensteiner", value: "Liechtensteiner" },
  { key: "lithuanian", value: "Lithuanian" },
  { key: "luxembourger", value: "Luxembourger" },
  { key: "macedonian", value: "Macedonian" },
  { key: "malagasy", value: "Malagasy" },
  { key: "malawian", value: "Malawian" },
  { key: "malaysian", value: "Malaysian" },
  { key: "maldivan", value: "Maldivan" },
  { key: "malian", value: "Malian" },
  { key: "maltese", value: "Maltese" },
  { key: "marshallese", value: "Marshallese" },
  { key: "mauritanian", value: "Mauritanian" },
  { key: "mauritian", value: "Mauritian" },
  { key: "mexican", value: "Mexican" },
  { key: "micronesian", value: "Micronesian" },
  { key: "moldovan", value: "Moldovan" },
  { key: "monacan", value: "Monacan" },
  { key: "mongolian", value: "Mongolian" },
  { key: "moroccan", value: "Moroccan" },
  { key: "mosotho", value: "Mosotho" },
  { key: "motswana", value: "Motswana" },
  { key: "mozambican", value: "Mozambican" },
  { key: "namibian", value: "Namibian" },
  { key: "nauruan", value: "Nauruan" },
  { key: "nepalese", value: "Nepalese" },
  { key: "newZealander", value: "NewZealander" },
  { key: "niVanuatu", value: "NiVanuatu" },
  { key: "nicaraguan", value: "Nicaraguan" },
  { key: "nigerien", value: "Nigerien" },
  { key: "northKorean", value: "NorthKorean" },
  { key: "northernIrish", value: "NorthernIrish" },
  { key: "norwegian", value: "Norwegian" },
  { key: "omani", value: "Omani" },
  { key: "pakistani", value: "Pakistani" },
  { key: "palauan", value: "Palauan" },
  { key: "palestinian", value: "Palestinian" },
  { key: "panamanian", value: "Panamanian" },
  { key: "papuaNewGuinean", value: "PapuaNewGuinean" },
  { key: "paraguayan", value: "Paraguayan" },
  { key: "peruvian", value: "Peruvian" },
  { key: "polish", value: "Polish" },
  { key: "portuguese", value: "Portuguese" },
  { key: "qatari", value: "Qatari" },
  { key: "romanian", value: "Romanian" },
  { key: "russian", value: "Russian" },
  { key: "rwandan", value: "Rwandan" },
  { key: "saintLucian", value: "SaintLucian" },
  { key: "salvadoran", value: "Salvadoran" },
  { key: "samoan", value: "Samoan" },
  { key: "sanMarinese", value: "SanMarinese" },
  { key: "saoTomean", value: "SaoTomean" },
  { key: "saudi", value: "Saudi" },
  { key: "scottish", value: "Scottish" },
  { key: "senegalese", value: "Senegalese" },
  { key: "serbian", value: "Serbian" },
  { key: "seychellois", value: "Seychellois" },
  { key: "sierraLeonean", value: "SierraLeonean" },
  { key: "singaporean", value: "Singaporean" },
  { key: "slovakian", value: "Slovakian" },
  { key: "slovenian", value: "Slovenian" },
  { key: "solomonIslander", value: "SolomonIslander" },
  { key: "southAfrican", value: "SouthAfrican" },
  { key: "southKorean", value: "SouthKorean" },
  { key: "spanish", value: "SouthKorean" },
  { key: "sriLankan", value: "SriLankan" },
  { key: "surinamer", value: "Surinamer" },
  { key: "swazi", value: "Swazi" },
  { key: "swedish", value: "Swedish" },
  { key: "swiss", value: "Swiss" },
  { key: "syrian", value: "Syrian" },
  { key: "taiwanese", value: "Taiwanese" },
  { key: "tajik", value: "Tajik" },
  { key: "tanzanian", value: "Tanzanian" },
  { key: "thai", value: "Thai" },
  { key: "togolese", value: "Togolese" },
  { key: "tongan", value: "Tongan" },
  { key: "trinidadianorTobagonian", value: "Trinidadian or Tobagonian" },
  { key: "tunisian", value: "Tunisian" },
  { key: "turkish", value: "Turkish" },
  { key: "tuvaluan", value: "Tuvaluan" },
  { key: "ugandan", value: "Ugandan" },
  { key: "ukrainian", value: "Ukrainian" },
  { key: "uruguayan", value: "Uruguayan" },
  { key: "uzbekistani", value: "Uzbekistani" },
  { key: "venezuelan", value: "Venezuelan" },
  { key: "vietnamese", value: "Vietnamese" },
  { key: "welsh", value: "Welsh" },
  { key: "zambian", value: "Zambian" },
  { key: "zimbabwean", value: "Zimbabwean" },
];
