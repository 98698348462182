import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import {
  selectPreference,
  toggleThis,
} from "../../features/preferences/preferencesSlice";
import { SwipeableDrawer } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { ListFilter } from "./ListFilter";
const drawerWidth = 280;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0px",
  //width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(9)} + 1px)`,
  // },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
interface Props {
  listType: string;
}
const FilterSidebar = ({ listType }: Props) => {
  const dispatch = useAppDispatch();

  const { isFilterOpen } = useAppSelector(selectPreference);

  const handleDrawerToggle = () => {
    dispatch(toggleThis({ type: "Filter", newValue: !isFilterOpen }));
  };
  const { i18n } = useTranslation();

  return (
    <Box sx={{ backgroundColor: "inherit" }}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        variant="temporary"
        anchor={i18n.dir() === "rtl" ? "left" : "right"}
        onClose={handleDrawerToggle}
        open={!!isFilterOpen}
        onOpen={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <ListFilter listType={listType} />
      </SwipeableDrawer>
      <Drawer
        PaperProps={{ sx: { backgroundColor: "transparent", opacity: "1" } }}
        variant="permanent"
        anchor={i18n.dir() === "rtl" ? "left" : "right"}
        sx={{
          display: { xs: "none", md: "block" },
        }}
        open={!!isFilterOpen}
      >
        <>
          <ListFilter listType={listType} />
        </>
      </Drawer>
    </Box>
  );
};

export default FilterSidebar;
