import { Stack } from "@mui/material";
import { LabelTemplate } from "../../styles/table";
import {
  Employee,
  LanguageExperience,
  Skills,
} from "../../../types/processTypes";

import { useEffect, useState } from "react";
import { Star, StarBorder } from "@mui/icons-material";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  rateType?: string | "arabic";
}
export const BasicRate = ({ currentEmployee, template, rateType }: Props) => {
  const [rateValue, setRateValue] = useState(1);
  useEffect(() => {
    if (currentEmployee && currentEmployee.education) {
      if (rateType === "arabic") {
        if (
          currentEmployee.education?.arabicLanguage === LanguageExperience.poor
        ) {
          setRateValue(1);
        } else if (
          currentEmployee.education?.arabicLanguage ===
            LanguageExperience.fair ||
          currentEmployee.education?.arabicLanguage === LanguageExperience.good
        ) {
          setRateValue(3);
        } else {
          setRateValue(5);
        }
      } else if (rateType === "english") {
        if (
          currentEmployee.education?.englishLanguage === LanguageExperience.poor
        ) {
          setRateValue(1);
        } else if (
          currentEmployee.education?.englishLanguage ===
            LanguageExperience.fair ||
          currentEmployee.education?.englishLanguage === LanguageExperience.good
        ) {
          setRateValue(3);
        } else {
          setRateValue(5);
        }
      } else if (rateType === "babySitting") {
        if (
          currentEmployee.education.skills &&
          currentEmployee.education?.skills?.indexOf(Skills.baby_sitting) > -1
        ) {
          setRateValue(3);
        } else {
          setRateValue(1);
        }
      } else if (rateType === "cleaning") {
        if (
          currentEmployee.education.skills &&
          currentEmployee.education?.skills?.indexOf(Skills.dusting_cleaning) >
            -1
        ) {
          setRateValue(3);
        } else {
          setRateValue(1);
        }
      } else if (rateType === "washing") {
        if (
          currentEmployee.education.skills &&
          currentEmployee.education?.skills?.indexOf(
            Skills.washing_ironing_clothes
          ) > -1
        ) {
          setRateValue(3);
        } else {
          setRateValue(1);
        }
      } else if (rateType === "cooking") {
        if (
          currentEmployee.education.skills &&
          currentEmployee.education?.skills?.indexOf(Skills.cooking) > -1
        ) {
          setRateValue(3);
        } else {
          setRateValue(1);
        }
      }
    }
  }, [rateType, currentEmployee]);

  return (
    <Stack
      direction="row"
      spacing={0.25}
      justifyContent="center"
      alignItems="center"
      sx={{ color: template.valueFontColor }}
    >
      <Star fontSize="small" />
      {rateValue >= 3 ? (
        <>
          <Star fontSize="small" />
          <Star fontSize="small" />
        </>
      ) : (
        <>
          <StarBorder fontSize="small" />
          <StarBorder fontSize="small" />
        </>
      )}

      {rateValue >= 5 ? (
        <>
          <Star fontSize="small" />
          <Star fontSize="small" />
        </>
      ) : (
        <>
          <StarBorder fontSize="small" />
          <StarBorder fontSize="small" />
        </>
      )}
    </Stack>
  );
};
