import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Link,
} from "@mui/material";
import SupportChat from "../footer/SupportChat";

export default function Support() {
  return (
    <Box sx={{ m: 4, py: 2 }}>
      <SupportChat />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/mofaLogo.jpg"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
                target="_blank"
              >
                Visa Platform
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa/Enjaz/GetVisaInformation/Person"
                target="_blank"
              >
                Wekala
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa"
                target="_blank"
              >
                Issued Print
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://tawtheeq.musaned.com.sa/"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/musaned2.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://tawtheeq.musaned.com.sa/"
                target="_blank"
              >
                Musaned
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://www.wafid.com/book-appointment/"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/wafid2.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/book-appointment/"
                target="_blank"
              >
                New
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/medical-status-search/"
                target="_blank"
              >
                Medical Results
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/search-slip/"
                target="_blank"
              >
                Get Slips
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
