import { Box, Typography } from "@mui/material";
import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { Company } from "../../../../setups/types/setupTypes";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicHeader = ({
  selectedCompany,
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {selectedCompany && selectedCompany.header ? (
        <Box
          sx={{
            borderRadius: template.borderRadius,
            boxShadow: template.headerShadow,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
          }}
        >
          <img
            width="100%"
            height="150px"
            src={selectedCompany.header as string}
            alt="office header"
          />
        </Box>
      ) : (
        <Box
          sx={{
            background: `radial-gradient(circle, ${template.labelBgColor} 40%, ${template.headBgColor} 100%)`,
            color: template.labelFontColor,
            borderRadius: template.borderRadius,
            boxShadow: template.headerShadow,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
          }}
        >
          <Typography variant="h2" textAlign="center" textTransform="uppercase">
            {`${selectedCompany.displayName}`} <br /> مكتب للإستقدام
          </Typography>
        </Box>
      )}
    </>
  );
};
export const BasicFooter = ({
  selectedCompany,
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {selectedCompany && selectedCompany.footer ? (
        <Box
          sx={{
            borderRadius: template.borderRadius,
            boxShadow: template.headerShadow,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
          }}
        >
          <img
            width="100%"
            height="150px"
            src={selectedCompany.footer as string}
            alt="office footer"
          />
        </Box>
      ) : (
        <Box
          sx={{
            background: `radial-gradient(circle, ${template.labelBgColor} 40%, ${template.headBgColor} 100%)`,
            color: template.labelFontColor,
            borderRadius: template.borderRadius,
            boxShadow: template.headerShadow,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
          }}
        >
          <Typography variant="h2" textAlign="center" textTransform="uppercase">
            {`${selectedCompany.displayName}`} <br /> مكتب للإستقدام
          </Typography>
        </Box>
      )}
    </>
  );
};
export const TextHeader = ({ currentEmployee, template }: Props) => {
  return (
    <Box
      sx={{
        background: `radial-gradient(circle, ${template.labelBgColor} 40%, ${template.headBgColor} 100%)`,
        color: template.labelFontColor,
        borderRadius: template.borderRadius,
        boxShadow: template.headerShadow,
        borderColor: template.borderColor,
        borderWidth: (template.borderWidth as number) * 3,
        borderStyle: template.borderStyle,
      }}
    >
      <Typography variant="h2" textAlign="center" textTransform="uppercase">
        Recruitment Office <br /> مكتب للإستقدام
      </Typography>
    </Box>
  );
};
