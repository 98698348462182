import {
  LabelTemplate,
  StyledTableCellLabelTemplate,
  StyledTableContainer,
  StyledTableRowTemplate,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { Stack, Table, TableBody, TableHead, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasicLocation } from "../basic/BasicLocation";
import { StyledTableCellValueTemplate } from "../../../../../styles/tableStyles";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  type: String;
}
export const InterLocation = ({ currentEmployee, template, type }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: template.spacing,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRowTemplate template={template} key="0">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("choose_candidate")}
              </Typography>
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableHead>
        <TableBody>
          <StyledTableRowTemplate template={template} key="1320">
            <StyledTableCellValueTemplate template={template}>
              <BasicLocation
                type="moreCvs"
                currentEmployee={currentEmployee}
                template={template}
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
